import React from 'react';
import MathDisplay from '../../common/MathDisplay';
import { Check, AlertCircle } from 'lucide-react';

const QuestionOptionsForm = ({ data, onChange }) => {
  // Ensure data has all required properties with default values
  const formData = {
    options: {
      A: '',
      B: '',
      C: '',
      D: '',
      ...data?.options
    },
    correct_answer: data?.correct_answer || 'A',
    concepts: {
      option_a: [],
      option_b: [],
      option_c: [],
      option_d: [],
      ...data?.concepts
    },
    misconceptions: {
      option_a: '',
      option_b: '',
      option_c: '',
      option_d: '',
      ...data?.misconceptions
    }
  };

  const handleOptionChange = (option, value) => {
    onChange({
      ...data,
      options: {
        ...data.options,
        [option]: value
      }
    });
  };

  const handleMisconceptionChange = (option, value) => {
    onChange({
      ...data,
      misconceptions: {
        ...data.misconceptions,
        [`option_${option.toLowerCase()}`]: value
      }
    });
  };

  const OptionHeader = ({ option, isCorrect }) => (
    <div className="flex items-center justify-between mb-3">
      <div className="flex items-center gap-2">
        <h3 className="text-lg font-medium text-gray-100">Option {option}</h3>
        {isCorrect ? (
          <span className="inline-flex items-center gap-1 px-2 py-0.5 rounded-full text-sm bg-green-900/20 text-green-400 border border-green-700/50">
            <Check size={14} />
            Correct Answer
          </span>
        ) : (
          <span className="inline-flex items-center gap-1 px-2 py-0.5 rounded-full text-sm bg-gray-900/20 text-gray-400 border border-gray-700/50">
            Option {option}
          </span>
        )}
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      {['A', 'B', 'C', 'D'].map(option => {
        const isCorrect = formData.correct_answer === option;
        const borderColor = isCorrect ? 'border-green-700/50' : 'border-gray-700/50';
        const bgColor = isCorrect ? 'bg-green-900/20' : 'bg-gray-900/20';

        return (
          <div 
            key={option} 
            className={`p-6 rounded-xl border ${borderColor} ${bgColor}`}
          >
            <OptionHeader option={option} isCorrect={isCorrect} />

            {/* Option Text */}
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Answer Text
              </label>
              <textarea
                value={formData.options[option] || ''}
                onChange={(e) => handleOptionChange(option, e.target.value)}
                className="w-full p-3 bg-gray-900/50 border border-gray-700 rounded-lg resize-none focus:ring-2 focus:ring-purple-500 text-gray-100 placeholder-gray-500"
                rows={3}
                placeholder="Enter the answer text..."
              />
              
              {/* Preview Section */}
              <div className="mt-4">
                <div className="flex items-center gap-2 mb-2">
                  <span className="text-sm font-medium text-gray-300">Preview</span>
                  <div className="flex-grow h-px bg-gray-700"></div>
                </div>
                <div className="p-4 rounded-lg bg-gray-900/30 border border-gray-700/50">
                  <div className="text-gray-100">
                    <MathDisplay content={formData.options[option] || 'No content'} />
                  </div>
                </div>
              </div>
            </div>

            {/* Misconception Section (only for incorrect answers) */}
            {!isCorrect && (
              <div>
                <div className="flex items-center gap-2 mb-2">
                  <AlertCircle size={16} className="text-gray-400" />
                  <label className="text-sm font-medium text-gray-300">
                    Common Misconception
                  </label>
                </div>
                <textarea
                  value={formData.misconceptions[`option_${option.toLowerCase()}`] || ''}
                  onChange={(e) => handleMisconceptionChange(option, e.target.value)}
                  className="w-full p-3 bg-gray-900/50 border border-gray-700 rounded-lg resize-none focus:ring-2 focus:ring-purple-500 text-gray-100 placeholder-gray-500"
                  rows={2}
                  placeholder="Explain why students might choose this incorrect answer..."
                />
                <p className="mt-2 text-sm text-gray-400">
                  Describe the misconception or error in thinking that might lead students to select this option.
                </p>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default QuestionOptionsForm;