// src/contexts/SchoolContext.jsx
import React, { createContext } from 'react';
import { useSchoolAuth } from '../hooks/useSchoolAuth';

export const SchoolContext = createContext(null);

export const SchoolProvider = ({ children }) => {
  const {
    isAuthenticated,
    loading,
    schoolData,
    login,
    logout,
    verifyAuth
  } = useSchoolAuth();

  // Map schoolData to the expected school object structure
  const school = schoolData ? {
    code: schoolData.code,
    name: schoolData.name,
    // Add any other school properties that your application expects
  } : null;

  const handleLogin = async (code, password) => {
    try {
      const response = await login(code, password);
      return response;
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      return { success: true };
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  return (
    <SchoolContext.Provider 
      value={{ 
        school,
        loading,
        isAuthenticated,
        login: handleLogin,
        logout: handleLogout,
        verifyAuth
      }}
    >
      {children}
    </SchoolContext.Provider>
  );
};