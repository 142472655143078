import React, { useState } from 'react';
import { X, Book, ChevronDown, GraduationCap, Settings, Save, AlertCircle } from 'lucide-react';
import MathDisplay from '../common/MathDisplay';
import QuestionBasicForm from './forms/QuestionBasicForm';
import QuestionOptionsForm from './forms/QuestionOptionsForm';
import QuestionConceptsForm from './forms/QuestionConceptsForm';
import QuestionDifficultyForm from './forms/QuestionDifficultyForm';

const TabButton = ({ id, label, icon: Icon, currentTab, onClick }) => (
  <button
    type="button"
    onClick={() => onClick(id)}
    className={`flex items-center gap-2 px-4 py-3 text-sm font-medium border-b-2 transition-colors ${
      currentTab === id
        ? 'border-purple-500 text-purple-400'
        : 'border-transparent text-gray-400 hover:text-gray-300 hover:border-gray-700'
    }`}
  >
    <Icon size={18} />
    {label}
  </button>
);

const QuestionEditModal = ({ question, onSave, onClose }) => {
  const [formData, setFormData] = useState(question);
  const [currentTab, setCurrentTab] = useState('basic');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const tabs = [
    { id: 'basic', label: 'Basic Info', icon: Book },
    { id: 'options', label: 'Options', icon: ChevronDown },
    { id: 'concepts', label: 'Concepts', icon: GraduationCap },
    { id: 'difficulty', label: 'Difficulty', icon: Settings }
  ];

  // Handle form data changes without saving
  const handleFormChange = (newData, shouldSave = false) => {
    setFormData(newData);
    setError(null);
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    try {
      setIsSubmitting(true);
      setError(null);
      await onSave(formData);
      onClose();
    } catch (error) {
      console.error('Error saving question:', error);
      setError('Failed to save question. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderForm = () => {
    const props = {
      data: formData,
      onChange: handleFormChange
    };

    switch (currentTab) {
      case 'basic':
        return <QuestionBasicForm {...props} />;
      case 'options':
        return <QuestionOptionsForm {...props} />;
      case 'concepts':
        return <QuestionConceptsForm {...props} />;
      case 'difficulty':
        return <QuestionDifficultyForm {...props} />;
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-gray-800 rounded-xl w-full max-w-4xl max-h-[90vh] overflow-hidden flex flex-col border border-gray-700">
        {/* Header */}
        <div className="flex items-center justify-between p-6 border-b border-gray-700">
          <h2 className="text-xl font-semibold text-gray-100">Edit Question</h2>
          <button
            type="button"
            onClick={onClose}
            className="p-2 hover:bg-gray-700 rounded-lg transition-colors text-gray-400"
          >
            <X size={20} />
          </button>
        </div>

        {/* Tabs */}
        <div className="border-b border-gray-700">
          <div className="flex overflow-x-auto">
            {tabs.map(tab => (
              <TabButton
                key={tab.id}
                {...tab}
                currentTab={currentTab}
                onClick={setCurrentTab}
              />
            ))}
          </div>
        </div>

        {/* Form Content */}
        <div className="flex-1 overflow-y-auto">
          <form onSubmit={handleSubmit} className="h-full">
            <div className="p-6">
              {renderForm()}
            </div>
            
            {/* Preview Section */}
            <div className="p-6 border-t border-gray-700 bg-gray-900/30">
              <h3 className="text-lg font-medium text-gray-100 mb-4">Preview</h3>
              <div className="space-y-4">
                <div className="p-4 rounded-lg bg-gray-900/50 border border-gray-700">
                  <div className="text-gray-100">
                    <MathDisplay content={formData.question_text} />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  {Object.entries(formData.options || {}).map(([key, value]) => (
                    <div
                      key={key}
                      className={`p-4 rounded-lg ${
                        formData.correct_answer === key
                          ? 'bg-green-900/20 border border-green-700/50 text-green-400'
                          : 'bg-gray-900/50 border border-gray-700/50 text-gray-300'
                      }`}
                    >
                      <span className="font-medium">Option {key}:</span>
                      <div className="mt-2">
                        <MathDisplay content={value} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Error Message */}
            {error && (
              <div className="px-6 pb-4">
                <div className="flex items-center gap-2 text-red-400 bg-red-900/20 p-3 rounded-lg border border-red-900/50">
                  <AlertCircle size={18} />
                  <p>{error}</p>
                </div>
              </div>
            )}

            {/* Action Buttons */}
            <div className="sticky bottom-0 bg-gray-800 border-t border-gray-700 px-6 py-4 flex justify-end gap-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-gray-300 hover:bg-gray-700 rounded-lg transition-colors"
                disabled={isSubmitting}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="flex items-center gap-2 px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={isSubmitting}
              >
                <Save size={18} />
                {isSubmitting ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default QuestionEditModal;