
import React from 'react';
import { 
  ArrowUpRight,
} from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
const PerformanceChart = ({ data = [] }) => {
    const formatValue = (value) => {
      if (typeof value === 'number') {
        return `${value.toFixed(1)}%`;
      }
      return 'N/A';
    };
  
    const formatDate = (date) => {
      if (!date) return 'N/A';
      try {
        return new Date(date).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric'
        });
      } catch (error) {
        return 'Invalid Date';
      }
    };
  
    return (
      <div className="bg-gray-800 rounded-xl shadow-md p-6 border border-gray-700/50">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h3 className="text-lg font-semibold text-gray-100">Performance Trends</h3>
            <p className="text-sm text-gray-400 mt-1">Student performance over time</p>
          </div>
          <button className="flex items-center gap-2 px-3 py-1.5 rounded-lg text-purple-400 hover:text-purple-300 hover:bg-gray-700/50 transition-all duration-300">
            <span className="text-sm font-medium">View Details</span>
            <ArrowUpRight className="w-4 h-4" />
          </button>
        </div>
  
        <div className="h-[340px]">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data}>
              <defs>
                <linearGradient id="colorScore" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8B5CF6" stopOpacity={0.2}/>
                  <stop offset="95%" stopColor="#8B5CF6" stopOpacity={0}/>
                </linearGradient>
              </defs>
              <CartesianGrid 
                strokeDasharray="3 3" 
                stroke="#374151" 
                vertical={false}
              />
              <XAxis 
                dataKey="date" 
                tickFormatter={formatDate}
                stroke="#6B7280"
                tick={{ fill: '#9CA3AF' }}
                axisLine={{ stroke: '#4B5563' }}
              />
              <YAxis 
                domain={[0, 100]} 
                stroke="#6B7280"
                tick={{ fill: '#9CA3AF' }}
                axisLine={{ stroke: '#4B5563' }}
                tickFormatter={value => `${value}%`}
              />
              <Tooltip 
                formatter={formatValue}
                labelFormatter={formatDate}
                contentStyle={{
                  backgroundColor: '#1F2937',
                  border: '1px solid #374151',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
                }}
                labelStyle={{ color: '#E5E7EB' }}
                itemStyle={{ color: '#A78BFA' }}
              />
              <Line 
                type="monotone" 
                dataKey="score" 
                stroke="#8B5CF6"
                strokeWidth={2}
                dot={{ fill: '#8B5CF6', strokeWidth: 2, r: 4 }}
                activeDot={{ r: 6, fill: '#8B5CF6' }}
                fillOpacity={1}
                fill="url(#colorScore)"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  };

  export default PerformanceChart;