import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { Brain, Rocket, Trophy, Star, Loader } from 'lucide-react';
import StudentForm from '../components/StudentForm/StudentForm';
import { storageService } from '../services/storageService';
import api from '../services/api';
import { GoogleLogin } from '@react-oauth/google';

const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID;

const HomePage = () => {
  const navigate = useNavigate();
  const [googleUserData, setGoogleUserData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    storageService.clearSession();
  }, []);

  const handleFormSuccess = useCallback(() => {
    try {
      const userData = storageService.getUserData();
      const requiredFields = ['testId', 'studentName', 'studentGrade', 'studentWhatsapp', 'studentEmail'];
      const missingFields = requiredFields.filter(field => !userData[field]);

      if (missingFields.length > 0) {
        throw new Error(`Missing required information: ${missingFields.join(', ')}`);
      }
      navigate('/test');
    } catch (error) {
      console.error('Navigation error:', error);
    }
  }, [navigate]);

  const checkExistingUser = useCallback(async (userData) => {
    try {
      const response = await api.get(`/user-questions?email=${encodeURIComponent(userData.email)}`);
      if (response.data.success && response.data.userExists) {
        storageService.setUserData({
          email: userData.email,
          name: userData.name,
          picture: userData.picture,
          whatsapp: response.data.whatsapp
        });
        navigate('/dashboard');
      } else {
        setGoogleUserData(userData);
      }
    } catch (error) {
      console.error('Error checking user:', error);
      setGoogleUserData(userData); // Show form if check fails
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);

  const handleGoogleSuccess = async (response) => {
    try {
      setIsLoading(true);
      const decoded = jwtDecode(response.credential);
      const userData = {
        name: decoded.name,
        email: decoded.email,
        picture: decoded.picture,
        sub: decoded.sub
      };
      await checkExistingUser(userData);
    } catch (error) {
      console.error('Error processing Google login:', error);
      setIsLoading(false);
    }
  };

  const buttonVariants = {
    hover: {
      scale: 1.05,
      boxShadow: "0 0 25px rgba(255, 255, 255, 0.3)",
    }
  };

  // Loading State
  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-[#112F3B] via-[#1a4459] to-[#2a5469] flex flex-col">
        <header className="py-8 px-4 bg-black/10">
          <motion.div 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-7xl mx-auto flex justify-center"
          >
            <img 
              src="/MQ_Logo_Tagline.png" 
              alt="Math Quotient" 
              className="w-72 md:w-92 h-auto"
            />
          </motion.div>
        </header>

        <div className="flex-1 flex items-center justify-center">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center text-white"
          >
            <Loader className="w-12 h-12 mx-auto mb-4 animate-spin text-purple-400" />
            <p className="text-lg">Checking your information...</p>
          </motion.div>
        </div>

        <footer className="py-6 text-center bg-black/10">
          <p className="text-white/60 text-sm">
            © 2025 Math Quotient. All rights reserved.
          </p>
        </footer>
      </div>
    );
  }

  // Student Form State
  if (googleUserData) {
    return (
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <div className="min-h-screen bg-gradient-to-br from-[#112F3B] via-[#1a4459] to-[#2a5469]">
          <div className="relative min-h-screen flex flex-col">
            <header className="py-8 px-4 bg-black/10">
              <motion.div 
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                className="max-w-7xl mx-auto flex justify-center"
              >
                <img 
                  src="/MQ_Logo_Tagline.png" 
                  alt="Math Quotient" 
                  className="w-72 md:w-92 h-auto"
                />
              </motion.div>
            </header>

            <main className="flex-1 flex items-center justify-center px-4">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="w-full max-w-md bg-white/10 backdrop-blur-xl rounded-2xl p-8 border border-white/20 shadow-xl"
              >
                <div className="text-center mb-8">
                  <h2 className="text-2xl md:text-3xl font-bold text-white mb-3">
                    Complete Your Profile
                  </h2>
                  <p className="text-white/80 text-sm md:text-base">
                    Let's personalize your learning experience
                  </p>
                </div>
                <StudentForm 
                  onSuccess={handleFormSuccess} 
                  initialData={googleUserData}
                  googleAuthenticated
                />
              </motion.div>
            </main>

            <footer className="py-6 text-center bg-black/10">
              <p className="text-white/60 text-sm">
                © 2025 Math Quotient. All rights reserved.
              </p>
            </footer>
          </div>
        </div>
      </GoogleOAuthProvider>
    );
  }

  // Initial Login State
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <div className="min-h-screen bg-gradient-to-br from-[#112F3B] via-[#1a4459] to-[#2a5469]">
        <div className="relative min-h-screen flex flex-col">
          <header className="py-8 px-4 bg-black/10">
            <motion.div 
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="max-w-7xl mx-auto flex justify-center"
            >
              <img 
                src="/MQ_Logo_Tagline.png" 
                alt="Math Quotient" 
                className="w-72 md:w-92 h-auto transform hover:scale-105 transition-transform duration-300"
              />
            </motion.div>
          </header>

          <main className="flex-1 flex flex-col lg:flex-row items-center justify-center gap-8 lg:gap-16 px-4 sm:px-8 py-8 sm:py-12">
            <motion.div 
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="hidden lg:block lg:flex-1 max-w-2xl"
            >
              <h1 className="text-5xl lg:text-5xl font-bold text-white mb-12 leading-tight">
                Bureau of Math<br />Investigation
              </h1>
              <div className="grid grid-cols-2 gap-8">
                <motion.div
                  whileHover={{ scale: 1.05, backgroundColor: 'rgba(255, 255, 255, 0.15)' }}
                  transition={{ duration: 0.3 }}
                  className="bg-white/10 backdrop-blur-lg rounded-xl p-8 border border-white/20 shadow-lg hover:shadow-xl"
                >
                  <Brain className="w-12 h-12 text-purple-400 mb-6" />
                  <h3 className="text-xl font-semibold text-white mb-3">Adaptive Learning</h3>
                  <p className="text-white/80 text-base">Questions adapt intelligently based on your performance level</p>
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.05, backgroundColor: 'rgba(255, 255, 255, 0.15)' }}
                  transition={{ duration: 0.3 }}
                  className="bg-white/10 backdrop-blur-lg rounded-xl p-8 border border-white/20 shadow-lg hover:shadow-xl"
                >
                  <Rocket className="w-12 h-12 text-blue-400 mb-6" />
                  <h3 className="text-xl font-semibold text-white mb-3">Smart Assessment</h3>
                  <p className="text-white/80 text-base">Get detailed insights into your mathematical abilities</p>
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.05, backgroundColor: 'rgba(255, 255, 255, 0.15)' }}
                  transition={{ duration: 0.3 }}
                  className="bg-white/10 backdrop-blur-lg rounded-xl p-8 border border-white/20 shadow-lg hover:shadow-xl"
                >
                  <Trophy className="w-12 h-12 text-yellow-400 mb-6" />
                  <h3 className="text-xl font-semibold text-white mb-3">Progress Tracking</h3>
                  <p className="text-white/80 text-base">Monitor your growth with detailed performance analytics</p>
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.05, backgroundColor: 'rgba(255, 255, 255, 0.15)' }}
                  transition={{ duration: 0.3 }}
                  className="bg-white/10 backdrop-blur-lg rounded-xl p-8 border border-white/20 shadow-lg hover:shadow-xl"
                >
                  <Star className="w-12 h-12 text-amber-400 mb-6" />
                  <h3 className="text-xl font-semibold text-white mb-3">Expert Guidance</h3>
                  <p className="text-white/80 text-base">Receive personalized recommendations for improvement</p>
                </motion.div>
              </div>
            </motion.div>

            <div className="w-full lg:w-auto max-w-md px-4 sm:px-0">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-white/10 backdrop-blur-xl rounded-2xl p-4 sm:p-8 border border-white/20 shadow-xl"
              >
                <div className="text-center mb-10">
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                    className="mb-8"
                  >
                    <div className="relative">
                      <Brain className="w-20 h-20 mx-auto text-purple-400" />
                      <motion.div
                        animate={{ 
                          scale: [1, 1.2, 1],
                          opacity: [0.3, 0.6, 0.3]
                        }}
                        transition={{
                          duration: 2,
                          repeat: Infinity,
                          repeatType: "reverse"
                        }}
                        className="absolute inset-0 bg-purple-400/30 rounded-full blur-2xl"
                      />
                    </div>
                  </motion.div>
                  
                  <motion.h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-white mb-4">
                    Ready to Excel?
                  </motion.h2>
                  <motion.div className="space-y-2 sm:space-y-3 mb-6 sm:mb-8">
                    <p className="text-white/90 text-base sm:text-lg">
                      Unlock your mathematical potential with our
                    </p>
                    <p className="text-purple-300 font-semibold text-lg sm:text-xl">
                      AI-Powered Adaptive Testing
                    </p>
                  </motion.div>
                </div>

                <motion.div 
                  className="space-y-4 sm:space-y-6"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.3 }}
                >
                  <div className="relative py-2">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-white/20" />
                    </div>
                    <div className="relative flex justify-center text-sm sm:text-base">
                      <span className="px-4 bg-transparent text-white/70">Begin Your Journey</span>
                    </div>
                  </div>
                  
                  <motion.div 
                    className="flex justify-center"
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.4 }}
                  >
                    <motion.div
                      className="relative p-1 rounded-full bg-gradient-to-r from-purple-500 via-blue-500 to-cyan-500"
                      variants={buttonVariants}
                      whileHover="hover"
                      onHoverStart={() => setIsHovered(true)}
                      onHoverEnd={() => setIsHovered(false)}
                    >
                      <motion.div
                        animate={{
                          rotate: isHovered ? 360 : 0
                        }}
                        transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                        className="absolute inset-0 bg-gradient-to-r from-purple-500 via-blue-500 to-cyan-500 rounded-full opacity-50 blur-md"
                      />
                      <div className="relative bg-black/10 rounded-full p-1">
                        <GoogleLogin
                          onSuccess={handleGoogleSuccess}
                          onError={() => console.error('Login Failed')}
                          useOneTap
                          theme="filled_blue"
                          size="large"
                          text="continue_with"
                          shape="circle"
                        />
                      </div>
                    </motion.div>
                  </motion.div>
                  
                  <motion.p 
                    className="text-center text-white/60 text-sm mt-4"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.5 }}
                  >
                    Your gateway to mathematical excellence
                  </motion.p>
                </motion.div>
              </motion.div>
            </div>
          </main>

          <footer className="py-6 text-center bg-black/10">
            <p className="text-white/60 text-sm">
              © 2025 Math Quotient. All rights reserved.
            </p>
          </footer>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default HomePage;