import React from 'react';
import { Search, Info } from 'lucide-react';
import Pagination from '../common/Pagination';

const StudentList = ({ 
  students, 
  loading, 
  onSelectStudent, 
  selectedEmail, 
  onSearch, 
  currentPage, 
  totalPages, 
  onPageChange 
}) => {
  // Format date helper function
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric'
    });
  };

  // Calculate score color based on average
  const getScoreColor = (score) => {
    if (score >= 80) return 'text-green-400';
    if (score >= 60) return 'text-blue-400';
    if (score >= 40) return 'text-yellow-400';
    return 'text-red-400';
  };

  return (
    <div className="bg-gradient-to-br from-indigo-900 to-purple-900 rounded-xl shadow-lg shadow-purple-900/30 border border-indigo-700/50">
      {/* Header Section */}
      <div className="p-6 border-b border-indigo-700/50 bg-indigo-900/30">
        <div className="flex items-center justify-between mb-4">
          <div>
            <h3 className="text-lg font-semibold text-indigo-100">Students</h3>
            <p className="text-sm text-indigo-300 mt-1">Manage your students</p>
          </div>
        </div>

        {/* Search Bar */}
        <div className="relative mt-6">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-indigo-300 w-5 h-5 pointer-events-none" />
          <input
            type="text"
            placeholder="Search students..."
            onChange={(e) => onSearch(e.target.value)}
            className="w-full pl-10 pr-4 py-2.5 bg-indigo-800/30 border border-indigo-700/50 rounded-lg 
                     focus:ring-2 focus:ring-pink-500 focus:border-transparent 
                     text-indigo-100 placeholder-indigo-400 
                     transition-all duration-300
                     hover:bg-indigo-800/40"
          />
        </div>
      </div>

      {/* Student List Section */}
      <div className="divide-y divide-indigo-700/50">
        {/* Loading State */}
        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin w-8 h-8 border-3 border-pink-500 border-t-transparent rounded-full mx-auto mb-4" />
            <p className="text-indigo-300 font-medium">Loading students...</p>
          </div>
        ) : students.length > 0 ? (
          // Students List
          <div className="max-h-[600px] overflow-y-auto custom-scrollbar">
            {students.map((student) => (
              <button
                key={student._id}
                onClick={() => onSelectStudent(student._id)}
                className={`w-full p-4 text-left transition-all duration-300 border-l-4
                  hover:bg-indigo-800/30
                  ${
                    selectedEmail === student._id 
                      ? 'bg-pink-900/20 border-l-pink-500' 
                      : 'border-l-transparent'
                  }
                `}
              >
                <div className="flex items-center justify-between">
                  {/* Student Info */}
                  <div className="flex items-center gap-3">
                    {/* Avatar */}
                    <div className="w-10 h-10 rounded-full bg-gradient-to-br from-pink-600 to-purple-600 
                                  flex items-center justify-center border border-pink-500/50
                                  shadow-lg shadow-purple-900/20">
                      <span className="text-pink-100 font-medium">
                        {student.name?.charAt(0) || 'U'}
                      </span>
                    </div>
                    {/* Student Details */}
                    <div>
                      <p className="font-medium text-indigo-100">{student.name}</p>
                      <div className="flex items-center gap-2 mt-1">
                        <p className="text-sm text-indigo-300">Grade {student.grade}</p>
                        <span className="h-1 w-1 rounded-full bg-indigo-400"></span>
                        <p className="text-sm text-indigo-300">{student.totalTests} questions</p>
                      </div>
                    </div>
                  </div>
                  {/* Score and Date */}
                  <div className="text-right">
                    <p className={`font-medium ${getScoreColor(student.averageScore)}`}>
                      {student.averageScore?.toFixed(1)}%
                    </p>
                    <p className="text-sm text-indigo-300">
                      {formatDate(student.lastTestDate)}
                    </p>
                  </div>
                </div>
              </button>
            ))}
          </div>
        ) : (
          // Empty State
          <div className="text-center py-12">
            <div className="w-16 h-16 bg-indigo-800/50 rounded-full flex items-center justify-center mx-auto mb-4 
                          border border-indigo-700 animate-pulse">
              <Info className="w-8 h-8 text-indigo-300" />
            </div>
            <p className="text-indigo-200 font-medium">No students found</p>
            <p className="text-sm text-indigo-300 mt-1">Try adjusting your search criteria</p>
          </div>
        )}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="p-4 border-t border-indigo-700/50 bg-indigo-900/30">
          <Pagination 
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            theme="dark"
          />
        </div>
      )}
    </div>
  );
};

export default StudentList;