import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Search, 
  Calendar,
  Filter,
  Download,
  AlertCircle,
  BarChart2,
  Clock,
  Users,
  FileText,
  Menu,
} from 'lucide-react';
import { Bar, BarChart } from 'recharts';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { ResponsiveContainer, Line, XAxis, YAxis, CartesianGrid, Tooltip, LineChart } from 'recharts';
import schoolService from '../../services/schoolService';
import Sidebar from './Sidebar';

// Test Summary Card Component
const TestSummaryCard = ({ title, value, icon: Icon, description, trend, color = "from-blue-400 to-teal-400" }) => (
  <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50">
    <CardContent className="p-6">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm text-gray-400">{title}</p>
          <p className="text-2xl font-bold text-gray-100 mt-1">{value}</p>
          {description && (
            <p className="text-sm text-gray-400 mt-1">{description}</p>
          )}
          {trend && (
            <p className={`text-sm mt-1 ${trend > 0 ? 'text-green-400' : 'text-red-400'}`}>
              {trend > 0 ? '↑' : '↓'} {Math.abs(trend)}% vs last month
            </p>
          )}
        </div>
        <div className={`p-3 rounded-lg bg-gradient-to-r ${color} bg-opacity-10`}>
          <Icon className="w-6 h-6 text-white" />
        </div>
      </div>
    </CardContent>
  </Card>
);

// Performance Distribution Chart Component
const PerformanceDistribution = ({ data }) => {
  if (!data) return null;

  const chartData = ['0-20', '21-40', '41-60', '61-80', '81-100'].map(range => ({
    range,
    students: data[range] || 0
  }));

  return (
    <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50">
      <CardHeader>
        <CardTitle className="text-lg font-semibold text-gray-100">Performance Distribution</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
              <XAxis dataKey="range" stroke="#9CA3AF" />
              <YAxis stroke="#9CA3AF" />
              <Tooltip
                contentStyle={{
                  backgroundColor: '#1F2937',
                  border: '1px solid #374151',
                  borderRadius: '0.5rem',
                  color: '#F3F4F6'
                }}
              />
              <Line 
                type="monotone"
                dataKey="students" 
                stroke="#36B9CC"
                strokeWidth={2}
                dot={{ fill: '#36B9CC', strokeWidth: 2 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

// Test List Component
const TestList = ({ tests = [], loading, selectedTest, onSelectTest }) => {
  const getScoreColor = (score) => {
    if (score >= 80) return 'text-green-400';
    if (score >= 60) return 'text-blue-400';
    return 'text-red-400';
  };

  if (loading) {
    return (
      <div className="p-8 text-center">
        <div className="animate-spin w-8 h-8 border-4 border-blue-400 border-t-transparent rounded-full mx-auto mb-4" />
        <p className="text-gray-400">Loading tests...</p>
      </div>
    );
  }

  if (!tests || tests.length === 0) {
    return (
      <div className="text-center py-8">
        <FileText className="w-12 h-12 text-gray-600 mx-auto mb-4" />
        <p className="text-gray-400">No tests found</p>
      </div>
    );
  }

  return (
    <div className="divide-y divide-gray-700">
      {tests.map((test) => (
        <button
          key={test.test_id}
          onClick={() => onSelectTest(test.test_id)}
          className={`w-full p-4 text-left hover:bg-gray-800/30 transition-colors ${
            selectedTest === test.test_id ? 'bg-gray-800/50' : ''
          }`}
        >
          <div className="flex justify-between items-start">
            <div>
              <p className="font-medium text-gray-200">Test #{test.test_id}</p>
              <p className="text-sm text-gray-400 mt-1">
                {new Date(test.date).toLocaleDateString()} • {test.students} students
              </p>
            </div>
            <div className="text-right">
              <p className={`font-semibold ${getScoreColor(test.averageScore || 0)}`}>
                {(test.averageScore || 0).toFixed(1)}%
              </p>
              <p className="text-sm text-gray-400 mt-1">
                Average Score
              </p>
            </div>
          </div>
        </button>
      ))}
    </div>
  );
};

// Test Details Component
const TestDetails = ({ test, loading, onExport }) => {
  if (loading) {
    return (
      <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50">
        <div className="p-8 text-center">
          <div className="animate-spin w-8 h-8 border-4 border-blue-400 border-t-transparent rounded-full mx-auto mb-4" />
          <p className="text-gray-400">Loading test details...</p>
        </div>
      </Card>
    );
  }

  if (!test) {
    return (
      <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50">
        <div className="p-8 text-center">
          <FileText className="w-12 h-12 text-gray-600 mx-auto mb-4" />
          <p className="text-gray-200 font-medium">No Test Selected</p>
          <p className="text-gray-400 mt-1">Select a test to view details</p>
        </div>
      </Card>
    );
  }

  // Helper function to get score color
  const getScoreColor = (score) => {
    const numScore = Number(score) || 0;
    if (numScore >= 80) return 'text-green-400';
    if (numScore >= 60) return 'text-blue-400';
    return 'text-red-400';
  };

  // Safe access to stats with defaults
  const stats = test.stats || {};
  const distribution = test.distribution || {};
  const results = test.results || [];
  const conceptPerformance = test.conceptPerformance || [];
  const timeAnalysis = test.timeAnalysis || {};

  return (
    <div className="space-y-6">
      {/* Test Overview with Graph */}
      <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50">
        <CardHeader className="flex flex-row items-center justify-between border-b border-gray-700">
          <CardTitle className="text-lg font-semibold text-gray-100">Test Overview</CardTitle>
          <button 
            onClick={onExport}
            className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-blue-400 to-teal-400 
                     text-white rounded-lg hover:from-blue-500 hover:to-teal-500 transition-colors"
          >
            <Download className="w-4 h-4" />
            <span>Export Report</span>
          </button>
        </CardHeader>
        <CardContent className="p-6">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mb-8">
            <div>
              <p className="text-sm text-gray-400">Date</p>
              <p className="font-medium text-gray-200">
                {new Date(test.date).toLocaleDateString()}
              </p>
            </div>
            <div>
              <p className="text-sm text-gray-400">Students</p>
              <p className="font-medium text-gray-200">{stats.totalStudents || 0}</p>
            </div>
            <div>
              <p className="text-sm text-gray-400">Average Score</p>
              <p className="font-medium text-gray-200">{(stats.averageScore || 0).toFixed(1)}%</p>
            </div>
            <div>
              <p className="text-sm text-gray-400">Average Duration</p>
              <p className="font-medium text-gray-200">{Math.round((stats.averageDuration || 0) / 60)} mins</p>
            </div>
          </div>

          {/* Performance Graph */}
          <div className="mt-6">
            <p className="text-sm font-medium text-gray-300 mb-4">Score Distribution</p>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={Object.entries(distribution).map(([range, count]) => ({
                  range,
                  students: count
                }))}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                  <XAxis 
                    dataKey="range" 
                    stroke="#9CA3AF"
                  />
                  <YAxis 
                    stroke="#9CA3AF"
                    tickFormatter={(value) => Math.round(value)}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: '#1F2937',
                      border: '1px solid #374151',
                      borderRadius: '0.5rem',
                      color: '#F3F4F6'
                    }}
                  />
                  <Bar 
                    dataKey="students" 
                    fill="#36B9CC"
                    radius={[4, 4, 0, 0]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </CardContent>
      </Card>


      {/* Concept Performance */}
      {conceptPerformance.length > 0 && (
        <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50">
          <CardHeader className="border-b border-gray-700">
            <CardTitle className="text-lg font-semibold text-gray-100">Concept Performance</CardTitle>
          </CardHeader>
          <CardContent className="p-6">
            <div className="space-y-4">
              {conceptPerformance
                .filter(concept => concept._id && concept._id !== ",") // Filter out invalid concepts
                .map((concept) => {
                  const accuracy = Number(concept.accuracy) || 0;
                  return (
                    <div 
                      key={concept._id}
                      className="flex items-center justify-between p-4 bg-gray-800/30 rounded-lg"
                    >
                      <div>
                        <p className="font-medium text-gray-200">{concept.concept_name || 'Unknown Concept'}</p>
                        <p className="text-sm text-gray-400">
                          {concept.correct}/{concept.total} correct
                        </p>
                      </div>
                      <div className="text-right">
                        <p className={`text-lg font-semibold ${getScoreColor(accuracy)}`}>
                          {accuracy.toFixed(1)}%
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </CardContent>
        </Card>
      )}

      {/* Student Results */}
      {results.length > 0 && (
        <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50">
          <CardHeader className="border-b border-gray-700">
            <CardTitle className="text-lg font-semibold text-gray-100">Student</CardTitle>
          </CardHeader>
          <CardContent className="p-6">
            <div className="space-y-4">
              {results.map((result) => (
                <div 
                  key={result.student_id}
                  className="flex items-center justify-between p-4 bg-gray-800/30 
                           rounded-lg hover:bg-gray-800/50 transition-colors"
                >
                  <div>
                    <p className="font-medium text-gray-200">{result.student_name}</p>
                    <p className="text-sm text-gray-400">Grade {result.grade}</p>
                  </div>
                  <div className="text-right">
                    <p className="text-sm text-gray-400">
                      {result.correct}/{result.total} correct • {((result.time_taken || 0) / 60).toFixed(1)} mins
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      )}

      {/* Time Analysis */}
      {timeAnalysis && Object.keys(timeAnalysis).length > 0 && (
        <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50">
          <CardHeader className="border-b border-gray-700">
            <CardTitle className="text-lg font-semibold text-gray-100">Time Analysis</CardTitle>
          </CardHeader>
          <CardContent className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="p-4 bg-gray-800/30 rounded-lg">
                <p className="text-sm text-gray-400">Average Time per Question</p>
                <p className="text-2xl font-bold text-gray-100 mt-2">
                  {(timeAnalysis.averageTimePerQuestion || 0).toFixed(1)}s
                </p>
              </div>
              <div className="p-4 bg-gray-800/30 rounded-lg">
                <p className="text-sm text-gray-400">Time Distribution</p>
                <div className="grid grid-cols-4 gap-2 mt-2">
                  {Object.entries(timeAnalysis.timeDistribution || {}).map(([range, count]) => (
                    <div key={range} className="text-center">
                      <p className="text-xs text-gray-400">{range}</p>
                      <p className="text-lg font-bold text-gray-100">{count}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

// Main SchoolTestsPage Component
const SchoolTestsPage = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [testsData, setTestsData] = useState({
    tests: [],
    stats: {
      totalTests: 0,
      averageScore: 0,
      totalStudents: 0,
      averageDuration: 0,
      testsTrend: 0,
      scoreTrend: 0
    }
  });
  const [selectedTest, setSelectedTest] = useState(null);
  const [testDetails, setTestDetails] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [timeRange, setTimeRange] = useState('month');
  const navigate = useNavigate();

  // Fetch tests list
  const fetchTests = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await schoolService.getTestList({
        search: searchTerm,
        timeRange
      });
      
      if (response.success) {
        setTestsData({
          tests: response.data.tests || [],
          stats: response.data.stats || {
            totalTests: 0,
            averageScore: 0,
            totalStudents: 0,
            averageDuration: 0,
            testsTrend: 0,
            scoreTrend: 0
          }
        });
      } else {
        setError(response.error || 'Failed to fetch tests');
      }
    } catch (error) {
      setError(error.message || 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  }, [searchTerm, timeRange]);

  // Fetch test details
  const fetchTestDetails = async (testId) => {
    try {
      setSelectedTest(testId);
      const response = await schoolService.getTestDetails(testId);
      if (response.success) {
        setTestDetails(response.data);
      } else {
        setError(response.error || 'Failed to fetch test details');
      }
    } catch (error) {
      setError(error.message || 'An unexpected error occurred');
    }
  };

  // Handle test export
  const handleExportTest = async () => {
    if (!selectedTest) return;
    try {
      await schoolService.exportTestResults(selectedTest);
    } catch (error) {
      setError('Failed to export test results');
    }
  };

  useEffect(() => {
    fetchTests();
  }, [fetchTests]);

  return (
    <div className="min-h-screen bg-gray-900">
      <Sidebar isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />

      <div className="lg:ml-64 min-h-screen">
        <div className="p-6 space-y-6">
          {/* Header */}
          <div className="bg-gradient-to-r from-gray-800 to-gray-900 rounded-xl shadow-xl p-6 border border-gray-700/50">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
              <div className="flex items-center gap-4">
                <button
                  onClick={() => setSidebarOpen(true)}
                  className="lg:hidden p-2 text-gray-400 hover:text-white"
                >
                  <Menu className="w-6 h-6" />
                </button>
                <div>
                  <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-400">
                    Tests
                  </h1>
                  <p className="text-gray-400">Monitor and analyze test performance</p>
                </div>
              </div>
              
              <div className="flex items-center gap-4">
                <select
                  value={timeRange}
                  onChange={(e) => setTimeRange(e.target.value)}
                  className="px-4 py-2 bg-gray-900/50 border border-gray-700 rounded-lg 
                           text-gray-300 focus:ring-2 focus:ring-blue-400/50"
                >
                  <option value="week">This Week</option>
                  <option value="month">This Month</option>
                  <option value="quarter">This Quarter</option>
                  <option value="year">This Year</option>
                </select>
                
                <button 
                  onClick={handleExportTest}
                  disabled={!selectedTest}
                  className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-blue-400 to-teal-400 
                           text-white rounded-lg hover:from-blue-500 hover:to-teal-500 transition-colors
                           disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <Download className="w-4 h-4" />
                  <span>Export Report</span>
                </button>
              </div>
            </div>
          </div>

          {/* Error Alert */}
          {error && (
            <div className="bg-red-900/20 text-red-400 p-4 rounded-xl flex items-center gap-2 border border-red-900/50">
              <AlertCircle className="w-5 h-5 flex-shrink-0" />
              <span>{error}</span>
            </div>
          )}

          {/* Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <TestSummaryCard
              title="Total Tests"
              value={testsData.stats.totalTests}
              icon={FileText}
              trend={testsData.stats.testsTrend}
              color="from-blue-400 to-teal-400"
            />
            <TestSummaryCard
              title="Average Score"
              value={`${testsData.stats.averageScore.toFixed(1)}%`}
              icon={BarChart2}
              trend={testsData.stats.scoreTrend}
              color="from-teal-400 to-green-400"
            />
            <TestSummaryCard
              title="Total Students"
              value={testsData.stats.totalStudents}
              icon={Users}
              description="Participated in tests"
              color="from-blue-400 to-indigo-400"
            />
            <TestSummaryCard
              title="Average Duration"
              value={`${testsData.stats.averageDuration} sec`}
              icon={Clock}
              color="from-indigo-400 to-purple-400"
            />
          </div>

          {/* Content Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {/* Test List */}
            <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50">
              <CardHeader className="border-b border-gray-700">
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <CardTitle className="text-lg font-semibold text-gray-100">Test History</CardTitle>
                    <button className="p-2 hover:bg-gray-800 rounded-lg">
                      <Filter className="w-5 h-5 text-gray-400" />
                    </button>
                  </div>
                  
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                    <input
                      type="text"
                      placeholder="Search tests..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="w-full pl-10 pr-4 py-2 bg-gray-900/50 border border-gray-700 rounded-lg 
                               text-gray-300 placeholder-gray-500 focus:ring-2 focus:ring-blue-400/50"
                    />
                  </div>
                </div>
              </CardHeader>
              <div className="max-h-[800px] overflow-y-auto">
                <TestList
                  tests={testsData.tests}
                  loading={loading}
                  selectedTest={selectedTest}
                  onSelectTest={fetchTestDetails}
                />
              </div>
            </Card>

            {/* Test Details */}
            <div className="lg:col-span-2">
              <TestDetails 
                test={testDetails}
                loading={loading}
                onExport={handleExportTest}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolTestsPage;