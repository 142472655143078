import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

export const TestHistoryCard = ({ test }) => {
  const navigate = useNavigate();
  
  const handleViewDetails = () => {
    const userPicture = sessionStorage.getItem('userPicture');
    sessionStorage.setItem('testId', test.test_id);
    sessionStorage.setItem('studentName', test.student_info.name);
    sessionStorage.setItem('studentGrade', test.student_info.grade);
    sessionStorage.setItem('studentWhatsapp', test.student_info.whatsapp);
    sessionStorage.setItem('studentEmail', test.student_info.email);
    sessionStorage.setItem('viewingHistory', 'true');

    if (userPicture) {
      sessionStorage.setItem('studentPicture', userPicture);
    }

    navigate(`/results/${test.test_id}`);
  };

  const getAccuracyColor = (accuracy) => {
    if (accuracy >= 80) return 'from-green-500 to-green-400';
    if (accuracy >= 60) return 'from-yellow-500 to-yellow-400';
    return 'from-red-500 to-red-400';
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ 
        scale: 1.02,
        boxShadow: "0 10px 20px rgba(147, 51, 234, 0.1)",
      }}
      className="bg-white rounded-xl shadow-sm p-6 border border-gray-200 
        hover:border-purple-300 transition-all duration-300"
    >
      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
        <div className="flex items-center gap-4">
          <motion.div
            whileHover={{ rotate: 360 }}
            transition={{ duration: 0.5 }}
            className="w-12 h-12 bg-purple-50 rounded-lg flex items-center justify-center flex-shrink-0
              ring-2 ring-purple-100 ring-offset-2"
          >
            <svg className="w-6 h-6 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
            </svg>
          </motion.div>
          <div>
            <div className="flex items-center gap-3">
              <motion.h3 
                whileHover={{ scale: 1.05 }}
                className="font-semibold text-gray-900"
              >
                Test #{test.test_id}
              </motion.h3>
              <span className="text-sm text-gray-500 bg-gray-100 px-2 py-1 rounded-md">
                {new Date(test.createdAt).toLocaleDateString()}
              </span>
            </div>
            <div className="flex items-center gap-4 mt-2">
              <span className="text-sm bg-purple-50 text-purple-700 px-2 py-1 rounded-md font-medium">
                Questions: {test.summary.totalQuestions}
              </span>
              <span className="text-sm bg-purple-50 text-purple-700 px-2 py-1 rounded-md font-medium">
                Time: {Math.round(test.summary.totalTime / 60)} mins
              </span>
            </div>
          </div>
        </div>
  
        <div className="flex items-center gap-4">
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="px-4 py-2 rounded-lg text-sm font-medium flex items-center gap-2
              bg-gradient-to-r text-white shadow-lg"
            style={{ 
              background: `linear-gradient(90deg, 
                ${test.summary.accuracy >= 80 ? '#10B981' : 
                  test.summary.accuracy >= 60 ? '#F59E0B' : '#EF4444'} 0%,
                ${test.summary.accuracy >= 80 ? '#34D399' : 
                  test.summary.accuracy >= 60 ? '#FBBF24' : '#F87171'} 100%)`
            }}
          >
            <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
            </svg>
            {test.summary.accuracy.toFixed(1)}%
          </motion.div>
          
          <motion.button
            whileHover={{ scale: 1.05, x: 5 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleViewDetails}
            className="px-4 py-2 bg-purple-600 text-white rounded-lg font-medium
              hover:bg-purple-700 transition-all duration-300 shadow-lg
              flex items-center gap-2 group"
          >
            View Details
            <svg 
              className="w-4 h-4 group-hover:translate-x-1 transition-transform" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M13 7l5 5m0 0l-5 5m5-5H6" />
            </svg>
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
};