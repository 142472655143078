import React, { useState, useEffect } from 'react';
import { Search, Filter, RefreshCw, X, Info, Upload, CheckCircle, AlertCircle } from 'lucide-react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card } from "@/components/ui/card";
import MathDisplay from '../common/MathDisplay';
import CSVUploadModal from './CSVUploadModal';
import QuestionsList from './QuestionsList';
import adminService from '../../services/adminService';
import QuestionEditModal from './QuestionEditModal';

const QuestionManagement = ({onTotalItemsChange, onError, onSuccess}) => {
  // State management
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshTrigger, setRefreshTrigger] = useState(0); // New state for triggering refreshes
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState('all');
  const [activeTab, setActiveTab] = useState("validated");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [previewQuestion, setPreviewQuestion] = useState(null);

  const handleValidationToggle = async (questionId, newValidationStatus) => {
    try {
      const questionToUpdate = questions.find(q => q._id === questionId);
      if (!questionToUpdate) return;

      const updatedQuestion = {
        ...questionToUpdate,
        isValidated: newValidationStatus
      };

      const response = await adminService.updateQuestion(questionId, updatedQuestion);
      
      if (response.success) {
        setQuestions(questions.map(q => 
          q._id === questionId ? { ...q, isValidated: newValidationStatus } : q
        ));
        onSuccess(`Question ${newValidationStatus ? 'validated' : 'unvalidated'} successfully`);
      } else {
        onError('Failed to update question validation status');
      }
    } catch (error) {
      onError('Failed to update question validation status: ' + error.message);
    }
  };

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const response = await adminService.getAllQuestions();
      if (response.success) {
        setQuestions(response.questions);
        onTotalItemsChange(response.questions.length);
      } else {
        onError('Failed to fetch questions');
      }
    } catch (err) {
      onError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, [refreshTrigger]); // Now depends on refreshTrigger

  const handleEditQuestion = (question) => {
    setEditingQuestion(question);
  };

  const handlePreviewQuestion = (question) => {
    setPreviewQuestion(question);
  };

  const handleUpdateQuestion = async (updatedQuestion) => {
    try {
      const response = await adminService.updateQuestion(
        updatedQuestion._id,
        updatedQuestion
      );
      if (response.success) {
        setQuestions(questions.map(q => 
          q._id === updatedQuestion._id ? updatedQuestion : q
        ));
        setEditingQuestion(null);
        onSuccess('Question updated successfully');
      } else {
        onError('Failed to update question');
      }
    } catch (error) {
      onError('Failed to update question: ' + error.message);
    }
  };

  const handleDeleteQuestion = async (questionId) => {
    try {
      const response = await adminService.deleteQuestion(questionId);
      if (response.success) {
        setQuestions(questions.filter(q => q._id !== questionId));
        onSuccess('Question deleted successfully');
      } else {
        onError('Failed to delete question');
      }
    } catch (error) {
      onError('Failed to delete question: ' + error.message);
    }
  };

  const handleRefresh = () => {
    setRefreshTrigger(prev => prev + 1); // Trigger refresh
  };

  // Filter functions
  const validatedQuestions = questions.filter(q => q.isValidated);
  const nonValidatedQuestions = questions.filter(q => !q.isValidated);

  const getFilteredQuestions = (questionList) => {
    return questionList.filter(question => {
      const matchesSearch = 
        question.question_text.toLowerCase().includes(searchTerm.toLowerCase()) ||
        question.question_id.toString().includes(searchTerm);
      const matchesGrade = selectedGrade === 'all' || 
        (question.difficulty_levels && question.difficulty_levels[`grade_${selectedGrade}`] > 0);
      return matchesSearch && matchesGrade;
    });
  };

  // Updated CSV upload handler
  const handleCSVUpload = async (result) => {
    if (result.success) {
      onSuccess(result.message);
      setShowUploadModal(false);
      handleRefresh(); // Refresh the questions list
    } else {
      onError(result.message || 'Failed to upload questions');
    }
  };

  return (
    <div className="space-y-6">
      {/* Search and Filters */}
      <Card className="p-6 bg-gray-800 border-gray-700/50">
        <div className="flex flex-wrap gap-4 items-center justify-between">
          <div className="relative flex-1 min-w-[300px]">
            <Search 
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" 
              size={20} 
            />
            <input
              type="text"
              placeholder="Search questions..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 text-gray-100 placeholder-gray-400"
            />
          </div>

          <div className="flex gap-4">
            <button
              onClick={() => setShowUploadModal(true)}
              className="flex items-center px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
            >
              <Upload size={20} className="mr-2" />
              Upload CSV
            </button>

            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center px-4 py-2 bg-gray-700 text-gray-200 rounded-lg hover:bg-gray-600 transition-colors"
            >
              <Filter size={20} className="mr-2" />
              Filters
              {selectedGrade !== 'all' && (
                <span className="ml-2 px-2 py-0.5 bg-gray-600 rounded-full text-sm">
                  Grade {selectedGrade}
                </span>
              )}
            </button>

            <button
              onClick={handleRefresh}
              disabled={loading}
              className="flex items-center px-4 py-2 bg-gray-700 text-gray-200 rounded-lg hover:bg-gray-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <RefreshCw 
                size={20} 
                className={`mr-2 ${loading ? 'animate-spin' : ''}`} 
              />
              Refresh
            </button>
          </div>
        </div>

        {showFilters && (
          <div className="mt-4 pt-4 border-t border-gray-700">
            <div className="flex flex-wrap gap-4">
              <select
                value={selectedGrade}
                onChange={(e) => setSelectedGrade(e.target.value)}
                className="px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg focus:ring-2 focus:ring-purple-500 text-gray-200"
              >
                <option value="all">All Grades</option>
                {[...Array(12)].map((_, i) => (
                  <option key={i + 1} value={i + 1}>Grade {i + 1}</option>
                ))}
              </select>
            </div>
          </div>
        )}
      </Card>

      {/* Tabs */}
      <Tabs defaultValue="validated" className="w-full" onValueChange={setActiveTab}>
        <TabsList className="grid w-full grid-cols-2 mb-4">
          <TabsTrigger value="validated" className="text-lg">
            <CheckCircle className="w-4 h-4 mr-2" />
            Validated Questions ({validatedQuestions.length})
          </TabsTrigger>
          <TabsTrigger value="non-validated" className="text-lg">
            <AlertCircle className="w-4 h-4 mr-2" />
            Non-validated Questions ({nonValidatedQuestions.length})
          </TabsTrigger>
        </TabsList>

        <TabsContent value="validated">
          <QuestionsList 
            questions={getFilteredQuestions(validatedQuestions)}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            onPageChange={setCurrentPage}
            onItemsPerPageChange={setItemsPerPage}
            loading={loading}
            onEditQuestion={handleEditQuestion}
            onPreviewQuestion={handlePreviewQuestion}
            onDeleteQuestion={handleDeleteQuestion}
            onValidate={handleValidationToggle}
          />
        </TabsContent>

        <TabsContent value="non-validated">
          <QuestionsList 
            questions={getFilteredQuestions(nonValidatedQuestions)}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            onPageChange={setCurrentPage}
            onItemsPerPageChange={setItemsPerPage}
            loading={loading}
            onEditQuestion={handleEditQuestion}
            onPreviewQuestion={handlePreviewQuestion}
            onDeleteQuestion={handleDeleteQuestion}
            onValidate={handleValidationToggle}
          />
        </TabsContent>
      </Tabs>

      {/* Modals */}
      {showUploadModal && (
        <CSVUploadModal
          onClose={() => setShowUploadModal(false)}
          onUpload={handleCSVUpload}
        />
      )}

      {editingQuestion && (
        <QuestionEditModal
          question={editingQuestion}
          onSave={handleUpdateQuestion}
          onClose={() => setEditingQuestion(null)}
        />
      )}

      {/* Question Preview Modal */}
      {previewQuestion && (
        <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
          <div className="bg-gray-800 rounded-xl w-full max-w-3xl max-h-[90vh] overflow-y-auto border border-gray-700">
            <div className="flex justify-between items-center p-4 border-b border-gray-700">
              <h2 className="text-xl font-semibold text-gray-100">Question Preview</h2>
              <button
                onClick={() => setPreviewQuestion(null)}
                className="p-2 hover:bg-gray-700 rounded-lg transition-colors text-gray-400 hover:text-gray-200"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            <div className="p-6">
              <div className="mb-6">
                <div className="text-sm text-gray-400 mb-2">
                  Question ID: {previewQuestion.question_id}
                </div>
                <div className="text-lg text-gray-100">
                  <MathDisplay content={previewQuestion.question_text} />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                {Object.entries(previewQuestion.options).map(([key, value]) => (
                  <div
                    key={key}
                    className={`p-4 rounded-lg border ${
                      previewQuestion.correct_answer === key
                        ? 'bg-green-900/20 border-green-700'
                        : 'bg-gray-700/50 border-gray-600'
                    }`}
                  >
                    <div className="font-medium mb-2 text-gray-200">Option {key}</div>
                    <MathDisplay content={value} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionManagement;