// StudentForm.jsx
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FormStep } from './FormStep';
import { ErrorMessage } from './ErrorMessage';
import { LoadingSpinner } from './LoadingSpinner';
import NameInput from './NameInput';
import GradeSelect from './GradeSelect';
import WhatsAppInput from './WhatsAppInput';
import EmailInput from './EmailInput';
import { storageService } from '../../services/storageService';
import api from '../../services/api';

const StudentForm = ({ onSuccess, initialData, googleAuthenticated = false }) => {
  const [formData, setFormData] = useState({
    name: '',
    grade: '',
    whatsapp: '',
    email: '',
    picture: ''
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isReturningUser, setIsReturningUser] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (initialData) {
      setFormData(prev => ({
        ...prev,
        name: initialData.name || prev.name,
        email: initialData.email || prev.email,
        picture: initialData.picture || prev.picture
      }));
      checkExistingUser(initialData.email);
    }
  }, [initialData]);

  const checkExistingUser = async (email) => {
    try {
      const response = await api.get(`/user-questions?email=${encodeURIComponent(email)}`);
      if (response.data.success && response.data.userExists) {
        setIsReturningUser(true);
        if (response.data.whatsapp) {
          setFormData(prev => ({ ...prev, whatsapp: response.data.whatsapp }));
        }
      }
    } catch (error) {
      console.error('Error checking user:', error);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!formData.grade) {
      newErrors.grade = 'Please select your grade';
      isValid = false;
    }

    if (!isReturningUser && currentStep === getTotalSteps() - 1) {
      if (!formData.whatsapp?.trim()) {
        newErrors.whatsapp = 'WhatsApp number is required';
        isValid = false;
      }

      if (!googleAuthenticated) {
        if (!formData.name?.trim()) newErrors.name = 'Name is required';
        if (!formData.email?.trim()) newErrors.email = 'Email is required';
        if (Object.keys(newErrors).length > 0) isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
    if (errors[field]) {
      setErrors(prev => ({ ...prev, [field]: '' }));
    }
  };

  const getTotalSteps = () => {
    if (isReturningUser) return 1;
    return googleAuthenticated ? 2 : 4;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      const response = await api.post('/start-test', {
        name: formData.name.trim(),
        grade: formData.grade,
        whatsapp: formData.whatsapp,
        email: formData.email.trim().toLowerCase(),
        googleAuthenticated,
        picture: formData.picture,
        isReturningUser
      });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to start test');
      }

      storageService.setUserData({
        test_id: response.data.test_id,
        name: formData.name,
        grade: formData.grade,
        whatsapp: formData.whatsapp,
        email: formData.email,
        picture: formData.picture
      });

      storageService.setTestStartData(response.data.test_id);
      onSuccess?.();

    } catch (error) {
      setErrors(prev => ({
        ...prev,
        general: error.message || 'Failed to start test. Please try again.'
      }));
    } finally {
      setLoading(false);
    }
  };

  const renderFormSteps = () => {
    if (isReturningUser) {
      return (
        <FormStep isVisible={true}>
          <GradeSelect
            value={formData.grade}
            onChange={(value) => handleInputChange('grade', value)}
            error={errors.grade}
            disabled={loading}
          />
        </FormStep>
      );
    }

    if (googleAuthenticated) {
      return (
        <>
          <FormStep isVisible={currentStep === 0}>
            <GradeSelect
              value={formData.grade}
              onChange={(value) => handleInputChange('grade', value)}
              error={errors.grade}
              disabled={loading}
            />
          </FormStep>
          <FormStep isVisible={currentStep === 1}>
            <WhatsAppInput
              value={formData.whatsapp}
              onChange={(value) => handleInputChange('whatsapp', value)}
              error={errors.whatsapp}
              disabled={loading}
            />
          </FormStep>
        </>
      );
    }

    return (
      <>
        <FormStep isVisible={currentStep === 0}>
          <NameInput
            value={formData.name}
            onChange={(value) => handleInputChange('name', value)}
            error={errors.name}
            disabled={loading}
          />
        </FormStep>
        <FormStep isVisible={currentStep === 1}>
          <EmailInput
            value={formData.email}
            onChange={(value) => handleInputChange('email', value)}
            error={errors.email}
            disabled={loading}
          />
        </FormStep>
        <FormStep isVisible={currentStep === 2}>
          <GradeSelect
            value={formData.grade}
            onChange={(value) => handleInputChange('grade', value)}
            error={errors.grade}
            disabled={loading}
          />
        </FormStep>
        <FormStep isVisible={currentStep === 3}>
          <WhatsAppInput
            value={formData.whatsapp}
            onChange={(value) => handleInputChange('whatsapp', value)}
            error={errors.whatsapp}
            disabled={loading}
          />
        </FormStep>
      </>
    );
  };

  return (
    <div className="w-full max-w-md mx-auto">
      {/* Form Header */}
      {googleAuthenticated && formData.picture && (
        <motion.div 
          className="flex flex-col items-center gap-4 mb-6"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
        >
          <div className="relative">
            <motion.div
              className="absolute -inset-1 bg-gradient-to-r from-[#7F56D9] to-[#EA4B0D] rounded-full blur-sm"
              animate={{ rotate: 360 }}
              transition={{ duration: 8, repeat: Infinity, ease: "linear" }}
            />
            <img 
              src={formData.picture} 
              alt={formData.name} 
              className="relative w-20 h-20 rounded-full shadow-lg border-2 border-white"
            />
          </div>
          <div className="text-center">
            <h3 className="text-white font-medium">
              {isReturningUser ? `Welcome back, ${formData.name}!` : formData.name}
            </h3>
            <p className="text-white/70 text-sm">{formData.email}</p>
          </div>
        </motion.div>
      )}

      {/* Progress Steps */}
      {!isReturningUser && (
        <div className="flex gap-2 justify-center mb-6">
          {[...Array(getTotalSteps())].map((_, index) => (
            <motion.div
              key={index}
              className={`h-1 rounded-full ${
                index <= currentStep 
                  ? 'bg-gradient-to-r from-[#7F56D9] to-[#EA4B0D]' 
                  : 'bg-white/20'
              }`}
              style={{ width: `${100 / getTotalSteps()}%` }}
              initial={{ scale: 0.8 }}
              animate={{ scale: index === currentStep ? 1.1 : 1 }}
              transition={{ duration: 0.3 }}
            />
          ))}
        </div>
      )}

      {/* Form Content */}
      <form onSubmit={handleSubmit} className="space-y-6">
        <ErrorMessage error={errors.general} />
        
        {renderFormSteps()}

        {/* Form Navigation */}
        <div className="flex gap-3">
          {currentStep > 0 && !isReturningUser && (
            <motion.button
              type="button"
              onClick={() => setCurrentStep(prev => prev - 1)}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="px-6 py-3 text-white border border-white/30 rounded-xl
                hover:bg-white/10 transition-colors duration-300 text-sm font-medium"
            >
              Back
            </motion.button>
          )}
          
          <motion.button
            type={isReturningUser || currentStep === getTotalSteps() - 1 ? 'submit' : 'button'}
            onClick={() => !loading && currentStep < getTotalSteps() - 1 && validateForm() && setCurrentStep(prev => prev + 1)}
            disabled={loading}
            whileHover={{ scale: loading ? 1 : 1.02 }}
            whileTap={{ scale: loading ? 1 : 0.98 }}
            className={`flex-1 py-3 px-6 rounded-xl text-white font-medium text-sm
              transition-all duration-300 relative overflow-hidden
              ${loading 
                ? 'bg-[#7F56D9]/60 cursor-not-allowed' 
                : 'bg-gradient-to-r from-[#7F56D9] to-[#EA4B0D] hover:opacity-90'
              }
              shadow-lg shadow-[#7F56D9]/20`}
          >
            {loading ? <LoadingSpinner /> : (
              isReturningUser || currentStep === getTotalSteps() - 1 ? 'Start Test' : 'Continue'
            )}
          </motion.button>
        </div>

        {/* Form Footer */}
        {(isReturningUser || currentStep === getTotalSteps() - 1) && (
          <motion.div 
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center space-y-3 pt-4"
          >
            <p className="text-white/50 text-xs">
              By starting the test, you agree to our Terms of Service and Privacy Policy
            </p>
          </motion.div>
        )}
      </form>
    </div>
  );
};

export default StudentForm;