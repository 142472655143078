// FormStep.jsx
import { motion, AnimatePresence } from 'framer-motion';

export const FormStep = ({ children, isVisible }) => (
  <AnimatePresence mode="wait">
    {isVisible && (
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -20 }}
        transition={{ 
          type: "spring",
          stiffness: 300,
          damping: 30
        }}
        className="min-h-[120px] w-full"
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);