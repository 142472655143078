import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { 
    Users, GraduationCap, Download, 
    Book
  } from 'lucide-react';

  import { 
    LineChart, 
    Line, 
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Tooltip, 
    ResponsiveContainer 
} from 'recharts';

// StudentDetails Component
const StudentDetails = ({ student, loading, onExport }) => {
    if (loading) {
      return (
        <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50">
          <div className="p-8 text-center">
            <div className="animate-spin w-8 h-8 border-4 border-blue-400 border-t-transparent 
                          rounded-full mx-auto mb-4" />
            <p className="text-gray-400">Loading student details...</p>
          </div>
        </Card>
      );
    }
  
    if (!student) {
      return (
        <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50">
          <div className="p-8 text-center">
            <Users className="w-12 h-12 text-gray-600 mx-auto mb-4" />
            <p className="text-gray-200 font-medium">No Student Selected</p>
            <p className="text-gray-400 mt-1">Select a student to view their details</p>
          </div>
        </Card>
      );
    }
  
    return (
      <div className="space-y-6">
        {/* Student Info Card */}
        <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50">
          <CardHeader className="flex flex-row items-center justify-between border-b border-gray-700">
            <CardTitle className="text-lg font-semibold text-gray-100">Student Information</CardTitle>
            <button
              onClick={onExport}
              className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-blue-400 to-teal-400 
                       text-white rounded-lg hover:from-blue-500 hover:to-teal-500 transition-colors"
            >
              <Download className="w-4 h-4" />
              <span>Export Report</span>
            </button>
          </CardHeader>
          <CardContent className="p-6">
            <div className="grid grid-cols-2 gap-6">
              <div>
                <p className="text-sm text-gray-400">Name</p>
                <p className="font-medium text-gray-200">{student.name}</p>
              </div>
              <div>
                <p className="text-sm text-gray-400">Grade</p>
                <p className="font-medium text-gray-200">{student.grade}</p>
              </div>
              <div>
                <p className="text-sm text-gray-400">Email</p>
                <p className="font-medium text-gray-200">{student.email}</p>
              </div>
              <div>
                <p className="text-sm text-gray-400">Tests Taken</p>
                <p className="font-medium text-gray-200">{student.totalTests}</p>
              </div>
            </div>
          </CardContent>
        </Card>
  
        {/* Performance Stats */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50">
            <CardContent className="p-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm text-gray-400">Average Score</p>
                  <p className="text-2xl font-bold text-gray-100">
                    {student.averageScore?.toFixed(1)}%
                  </p>
                </div>
                <LineChart className="w-8 h-8 text-blue-400" />
              </div>
            </CardContent>
          </Card>
  
          <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50">
            <CardContent className="p-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm text-gray-400">Tests Completed</p>
                  <p className="text-2xl font-bold text-gray-100">
                    {student.totalTests}
                  </p>
                </div>
                <Book className="w-8 h-8 text-teal-400" />
              </div>
            </CardContent>
          </Card>
  
          <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50">
            <CardContent className="p-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm text-gray-400">Concepts Mastered</p>
                  <p className="text-2xl font-bold text-gray-100">
                    {student.conceptsMastered || 0}
                  </p>
                </div>
                <GraduationCap className="w-8 h-8 text-purple-400" />
              </div>
            </CardContent>
          </Card>
        </div>
  
        {/* Performance Chart */}
        {student.performanceData && student.performanceData.length > 0 && (
          <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50">
            <CardHeader>
              <CardTitle className="text-lg font-semibold text-gray-100">Performance History</CardTitle>
            </CardHeader>
            <CardContent className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={student.performanceData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                  <XAxis dataKey="date" stroke="#9CA3AF" />
                  <YAxis stroke="#9CA3AF" />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: '#1F2937',
                      border: '1px solid #374151',
                      borderRadius: '0.5rem',
                      color: '#F3F4F6'
                    }}
                  />
                  <Line 
                    type="monotone" 
                    dataKey="score" 
                    stroke="#36B9CC" 
                    strokeWidth={2}
                    dot={{ fill: '#36B9CC', strokeWidth: 2 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        )}
  
        {/* Recent Tests */}
        <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50">
          <CardHeader className="border-b border-gray-700">
            <CardTitle className="text-lg font-semibold text-gray-100">Recent Tests</CardTitle>
          </CardHeader>
          <CardContent className="p-6">
            <div className="space-y-4">
              {student.recentTests?.map((test) => (
                <div 
                  key={test._id} 
                  className="flex items-center justify-between p-4 bg-gray-800/30 
                           rounded-lg hover:bg-gray-800/50 transition-colors cursor-pointer"
                >
                  <div>
                    <p className="font-medium text-gray-200">Test #{test.test_id}</p>
                    <p className="text-sm text-gray-400">
                      {new Date(test.date).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="text-right">
                    <p className={`text-lg font-semibold ${
                      (test.correct_answers / test.total_questions * 100) >= 80 
                        ? 'text-green-400' 
                        : (test.correct_answers / test.total_questions * 100) >= 60 
                          ? 'text-blue-400' 
                          : 'text-red-400'
                    }`}>
                      {(test.correct_answers / test.total_questions * 100).toFixed(1)}%
                    </p>
                    <p className="text-sm text-gray-400">
                      {test.correct_answers}/{test.total_questions} correct
                    </p>
                  </div>
                </div>
              ))}
              
              {!student.recentTests?.length && (
                <div className="text-center py-8">
                  <Book className="w-12 h-12 text-gray-600 mx-auto mb-3" />
                  <p className="text-gray-400">No recent tests found</p>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };

  export default StudentDetails;