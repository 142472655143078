import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const GradeSelect = ({ value, onChange, error, disabled }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const gradeOptions = [
    { value: 'G5', label: 'Grade 5', category: 'Primary' },
    { value: 'G6', label: 'Grade 6', category: 'Primary' },
    { value: 'G7', label: 'Grade 7', category: 'Middle' },
    { value: 'G8', label: 'Grade 8', category: 'Middle' },
    { value: 'G9', label: 'Grade 9', category: 'High' },
    { value: 'G10', label: 'Grade 10', category: 'High' },
    { value: 'G11', label: 'Grade 11', category: 'High' },
    { value: 'G12', label: 'Grade 12', category: 'High' }
  ];

  const groupedGrades = gradeOptions.reduce((acc, grade) => {
    if (!acc[grade.category]) {
      acc[grade.category] = [];
    }
    acc[grade.category].push(grade);
    return acc;
  }, {});

  const handleSelect = (gradeValue) => {
    onChange(gradeValue);
    setIsOpen(false);
  };

  const selectedGrade = gradeOptions.find(grade => grade.value === value);

  return (
    <div className="relative">
      {/* Custom Select Button */}
      <motion.button
        type="button"
        onClick={() => !disabled && setIsOpen(!isOpen)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        disabled={disabled}
        whileHover={{ scale: disabled ? 1 : 1.01 }}
        className={`
          w-full px-6 py-4 rounded-xl 
          border text-base font-medium
          transition-all duration-200 relative
          ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:bg-white/15'}
          ${error 
            ? 'border-red-400 bg-red-400/10' 
            : isFocused
              ? 'border-white/50 bg-white/15 shadow-lg'
              : 'border-white/20 bg-white/10'
          }
        `}
      >
        {/* Safari-compatible text rendering */}
        <div className="relative z-10">
          <span className={`block truncate ${value ? 'text-white' : 'text-white'}`}>
            {selectedGrade ? selectedGrade.label : 'Select Grade'}
          </span>
        </div>
        
        {/* Dropdown Arrow */}
        <motion.span
          animate={{ rotate: isOpen ? 180 : 0 }}
          className="absolute right-4 top-1/2 -translate-y-1/2 z-10"
        >
          <svg 
            className={`w-5 h-5 ${error ? 'text-red-400' : 'text-white'}`}
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M19 9l-7 7-7-7" 
            />
          </svg>
        </motion.span>

        {/* Selection Check Mark */}
        {value && (
          <motion.span
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            className="absolute right-12 top-1/2 -translate-y-1/2 z-10"
          >
            <svg 
              className="w-5 h-5 text-green-400" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M5 13l4 4L19 7" 
              />
            </svg>
          </motion.span>
        )}
      </motion.button>

      {/* Dropdown Menu */}
      <AnimatePresence>
        {isOpen && !disabled && (
          <>
            {/* Backdrop */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 z-30 bg-black/20"
              onClick={() => setIsOpen(false)}
            />

            {/* Options List */}
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="absolute z-40 w-full mt-2 bg-gray-800/95
                rounded-xl border border-white/20 shadow-xl overflow-hidden"
            >
              <div className="max-h-60 overflow-auto py-1">
                {Object.entries(groupedGrades).map(([category, grades]) => (
                  <div key={category} className="border-b border-white/10 last:border-b-0">
                    <div className="px-6 py-3 bg-black/20">
                      <span className="text-sm font-medium text-white/70">
                        {category} School
                      </span>
                    </div>
                    {grades.map((grade) => (
                      <motion.div
                        key={grade.value}
                        whileHover={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
                        whileTap={{ backgroundColor: 'rgba(255, 255, 255, 0.15)' }}
                        onClick={() => handleSelect(grade.value)}
                        className={`px-6 py-3 cursor-pointer flex items-center justify-between text-base
                          ${value === grade.value ? 'bg-white/20 text-white' : 'text-white/90'}
                        `}
                      >
                        <span>
                          {grade.label}
                        </span>
                        {value === grade.value && (
                          <motion.span
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                          >
                            <svg 
                              className="w-5 h-5 text-green-400" 
                              fill="none" 
                              viewBox="0 0 24 24" 
                              stroke="currentColor"
                            >
                              <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth={2} 
                                d="M5 13l4 4L19 7" 
                              />
                            </svg>
                          </motion.span>
                        )}
                      </motion.div>
                    ))}
                  </div>
                ))}
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>

      {/* Error Message */}
      {error && (
        <motion.p
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-sm text-red-400 mt-2"
        >
          {error}
        </motion.p>
      )}
    </div>
  );
};

export default GradeSelect;