import React from 'react';
import { HelpCircle, AlertTriangle, Check } from 'lucide-react';

const DifficultyLevel = ({ level, isSelected, onClick }) => {
  const baseClasses = "w-12 h-12 rounded-lg flex items-center justify-center font-medium transition-all duration-200";
  
  // Enhanced styling for different levels
  const getLevelStyle = () => {
    if (!isSelected) {
      return "bg-gray-900/50 text-gray-400 hover:bg-gray-700/50 border border-gray-700";
    }
    
    // Color coding for different difficulty levels
    switch (level) {
      case 0:
        return "bg-gray-600 text-white ring-2 ring-gray-500/50 ring-offset-2 ring-offset-gray-800";
      case 1:
      case 2:
        return "bg-green-600 text-white ring-2 ring-green-500/50 ring-offset-2 ring-offset-gray-800";
      case 3:
        return "bg-yellow-600 text-white ring-2 ring-yellow-500/50 ring-offset-2 ring-offset-gray-800";
      case 4:
      case 5:
        return "bg-red-600 text-white ring-2 ring-red-500/50 ring-offset-2 ring-offset-gray-800";
      default:
        return "bg-purple-600 text-white ring-2 ring-purple-500/50 ring-offset-2 ring-offset-gray-800";
    }
  };

  return (
    <button
      type="button"
      className={`${baseClasses} ${getLevelStyle()}`}
      onClick={() => onClick(level)}
    >
      {level}
    </button>
  );
};

const GradeDifficultySelector = ({ grade, value, onChange }) => {
  const levels = [0, 1, 2, 3, 4, 5];

  const getDifficultyDescription = (value) => {
    switch (value) {
      case 0:
        return {
          text: "Not suitable for this grade",
          icon: <AlertTriangle size={16} className="text-gray-400" />
        };
      case 1:
        return {
          text: "Very Easy - Basic concept application",
          icon: <Check size={16} className="text-green-400" />
        };
      case 2:
        return {
          text: "Easy - Direct application of concepts",
          icon: <Check size={16} className="text-green-400" />
        };
      case 3:
        return {
          text: "Medium - Multiple concept application",
          icon: <Check size={16} className="text-yellow-400" />
        };
      case 4:
        return {
          text: "Hard - Complex problem solving",
          icon: <Check size={16} className="text-red-400" />
        };
      case 5:
        return {
          text: "Very Hard - Advanced problem solving",
          icon: <Check size={16} className="text-red-400" />
        };
      default:
        return {
          text: "",
          icon: null
        };
    }
  };

  return (
    <div className="p-6 border border-gray-700/50 rounded-xl bg-gray-800">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-3">
          <h3 className="text-lg font-medium text-gray-100">Grade {grade}</h3>
          {value > 0 && (
            <span className={`inline-flex items-center gap-1.5 px-2 py-0.5 rounded-full text-sm 
              ${value <= 2 ? 'bg-green-900/20 text-green-400 border-green-700/50' :
                value === 3 ? 'bg-yellow-900/20 text-yellow-400 border-yellow-700/50' :
                'bg-red-900/20 text-red-400 border-red-700/50'} border`}
            >
              Level {value}
            </span>
          )}
        </div>
        <div className="relative group">
          <HelpCircle size={20} className="text-gray-400 cursor-help" />
          <div className="absolute right-0 w-64 p-4 bg-gray-900 border border-gray-700 rounded-lg shadow-lg 
                        opacity-0 group-hover:opacity-100 transition-opacity z-10
                        invisible group-hover:visible">
            <h4 className="font-medium text-gray-200 mb-2">Difficulty Levels:</h4>
            <div className="space-y-1.5 text-sm text-gray-300">
              <p>0: Not suitable</p>
              <p>1: Very Easy</p>
              <p>2: Easy</p>
              <p>3: Medium</p>
              <p>4: Hard</p>
              <p>5: Very Hard</p>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-6 gap-2">
        {levels.map(level => (
          <DifficultyLevel
            key={level}
            level={level}
            isSelected={value === level}
            onClick={() => onChange(grade, level)}
          />
        ))}
      </div>

      <div className="mt-4 flex items-start gap-2 text-sm text-gray-400">
        {getDifficultyDescription(value).icon}
        <p>{getDifficultyDescription(value).text}</p>
      </div>
    </div>
  );
};

const QuestionDifficultyForm = ({ data, onChange }) => {
  const handleDifficultyChange = (grade, level) => {
    onChange({
      ...data,
      difficulty_levels: {
        ...data.difficulty_levels,
        [`grade_${grade}`]: level
      }
    }, false);
  };

  const getGradeSummary = () => {
    const levels = data.difficulty_levels || {};
    const activeGrades = Object.entries(levels)
      .filter(([_, level]) => level > 0)
      .map(([grade, level]) => ({
        grade: grade.replace('grade_', ''),
        level
      }));

    return activeGrades.length > 0 
      ? `Active in ${activeGrades.length} grades` 
      : 'No active grades';
  };

  return (
    <div className="space-y-6">
      {/* Summary Section */}
      <div className="bg-gray-900/50 p-6 rounded-xl border border-gray-700/50">
        <h3 className="text-lg font-medium text-gray-100 mb-2">Difficulty Summary</h3>
        <div className="flex items-center gap-2">
          <span className="inline-flex items-center gap-1.5 px-2.5 py-1 rounded-lg text-sm bg-purple-900/20 text-purple-400 border border-purple-700/50">
            {getGradeSummary()}
          </span>
        </div>
      </div>

      {/* Grade Selectors */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {[...Array(12)].map((_, index) => {
          const grade = index + 1;
          return (
            <GradeDifficultySelector
              key={grade}
              grade={grade}
              value={data.difficulty_levels?.[`grade_${grade}`] || 0}
              onChange={handleDifficultyChange}
            />
          );
        })}
      </div>

      {/* Quick Actions */}
      <div className="flex flex-wrap gap-2 pt-6 border-t border-gray-700">
        <button
          type="button"
          onClick={() => {
            const newLevels = {};
            for (let i = 1; i <= 12; i++) {
              newLevels[`grade_${i}`] = 0;
            }
            onChange({
              ...data,
              difficulty_levels: newLevels
            }, false);
          }}
          className="px-4 py-2 text-sm border border-gray-700 rounded-lg hover:bg-gray-700/50 text-gray-300 transition-all duration-200"
        >
          Reset All
        </button>
      </div>
    </div>
  );
};

export default QuestionDifficultyForm;