// client/src/services/api.js
import axios from 'axios';
import { apiConfig, apiLogger } from '../config/api.config';

const api = axios.create(apiConfig);

// Add auth token to requests
api.interceptors.request.use(
  (config) => {
    // Apply logger first
    config = apiLogger.request(config);
    
    // Then add auth token if present
    const token = localStorage.getItem('adminToken');
    if (token && config.url?.includes('/admin')) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor with auth handling
api.interceptors.response.use(
  apiLogger.response,
  (error) => {
    return apiLogger.error(error);
  }
);

// Existing test service
export const testService = {
  checkExistingUser: async (email) => {
    const response = await api.get(apiConfig.endpoints.checkUser, {
      params: { email }
    });
    return response.data;
  },

  startTest: async (studentInfo) => {
    const response = await api.post(apiConfig.endpoints.startTest, studentInfo);
    return response.data;
  },

  getQuestions: async (grade) => {
    const response = await api.get(apiConfig.endpoints.questions, { 
      params: { grade }
    });
    return response.data;
  },

  submitResponse: async (responseData) => {
    const response = await api.post(apiConfig.endpoints.responses, responseData);
    return response.data;
  },

  getTestResults: async (testId) => {
    const response = await api.get(apiConfig.endpoints.results(testId));
    return response.data;
  },
  
  getTestHistory: async (email) => {
    const response = await api.get(apiConfig.endpoints.testHistory, {
      params: { email }
    });
    return response.data;
  }
};

export default api;