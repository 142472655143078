// src/routes/SchoolProtectedRoute.jsx
import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { SchoolContext } from '../contexts/SchoolContext';

const SchoolProtectedRoute = ({ children }) => {
  const { school, loading, isAuthenticated } = useContext(SchoolContext);
  const location = useLocation();

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-900">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-blue-400 border-t-transparent rounded-full animate-spin mx-auto mb-4" />
          <p className="text-gray-400 font-medium">Loading...</p>
        </div>
      </div>
    );
  }

  if (!isAuthenticated || !school) {
    return <Navigate to="/school/login" state={{ from: location }} replace />;
  }

  return children;
};

export default SchoolProtectedRoute;