import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Users, 
  GraduationCap,
  BarChart2,
  Calendar,
  Search,
  Bell,
  Download,
  RefreshCcw,
  Info,
  FileText,
  Menu,
} from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import schoolService from '../../services/schoolService';
import Sidebar from './Sidebar';

// Stat Card Component
const StatCard = ({ title, value, icon: Icon, description, color = "from-blue-400 to-teal-400" }) => (
  <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50">
    <CardHeader className="flex flex-row items-center justify-between pb-2">
      <CardTitle className="text-sm font-medium text-gray-300">
        {title}
      </CardTitle>
      <div className={`p-2 rounded-lg bg-gradient-to-r ${color} bg-opacity-10`}>
        <Icon className="w-4 h-4 text-white" />
      </div>
    </CardHeader>
    <CardContent>
      <div className="text-2xl font-bold text-white">{value}</div>
      {description && (
        <div className="text-xs text-gray-400 mt-1">{description}</div>
      )}
    </CardContent>
  </Card>
);

// Grade Performance Chart Component
const GradePerformanceChart = ({ data }) => (
  <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50 p-6">
    <CardHeader>
      <CardTitle className="text-lg font-semibold text-gray-100">Grade Performance</CardTitle>
    </CardHeader>
    <CardContent>
      <div className="h-80">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
            <XAxis dataKey="grade" stroke="#9CA3AF" />
            <YAxis stroke="#9CA3AF" />
            <Tooltip
              contentStyle={{
                backgroundColor: '#1F2937',
                border: '1px solid #374151',
                borderRadius: '0.5rem',
                color: '#F3F4F6'
              }}
            />
            <Line 
              type="monotone" 
              dataKey="accuracy" 
              stroke="#36B9CC" 
              strokeWidth={2}
              dot={{ fill: '#36B9CC', strokeWidth: 2 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </CardContent>
  </Card>
);

// Main Dashboard Component
const SchoolDashboard = () => {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dashboardData, setDashboardData] = useState({
    totalStudents: 0,
    totalTests: 0,
    averageScore: 0,
    gradeDistribution: {},
    recentTests: []
  });
  const [timeRange, setTimeRange] = useState('week');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('all');
  const [showNotifications, setShowNotifications] = useState(false);

  // Debug logging for dashboard data changes
  useEffect(() => {
    console.log('Current Dashboard Data:', dashboardData);
  }, [dashboardData]);

  // Fetch dashboard data using schoolService
  const fetchDashboardData = async () => {
    try {
        setLoading(true);
        setError(null);
        const response = await schoolService.getDashboardStats({ timeRange });
        
        console.log('API Response:', response);
        
        if (response.success) {
            const responseData = response.data;
            console.log('Dashboard Data:', responseData);

            // Process grade distribution data with null check
            const gradeDistribution = {};
            if (responseData.data.gradeDistribution) {
                Object.entries(responseData.data.gradeDistribution).forEach(([grade, stats]) => {
                    gradeDistribution[grade] = {
                        total: parseInt(stats.total) || 0,
                        correct: parseInt(stats.correct) || 0,
                        accuracy: ((stats.correct / stats.total) * 100).toFixed(1)
                    };
                });
            }

            console.log('Processed Grade Distribution:', gradeDistribution);

            // Format recent tests with null check
            const recentTests = (responseData.data.recentTests || []).map(test => ({
                _id: test._id,
                student_name: test.student_name || '',
                grade: test.grade || '',
                date: new Date(test.date),
                correct_answers: parseInt(test.correct_answers) || 0,
                total_questions: parseInt(test.total_questions) || 0,
                score: ((test.correct_answers / test.total_questions) * 100).toFixed(1)
            }));

            console.log('Processed Recent Tests:', recentTests);

            // Update dashboard data with correct data nesting
            setDashboardData({
                totalStudents: parseInt(responseData.data.totalStudents) || 0,
                totalTests: parseInt(responseData.data.totalTests) || 0,
                averageScore: parseFloat(responseData.data.averageScore).toFixed(2) || 0,
                gradeDistribution,
                recentTests
            });

        } else {
            setError(response.error || 'Failed to fetch dashboard data');
        }
    } catch (error) {
        console.error('Dashboard Error:', error);
        setError(error.message || 'An unexpected error occurred');
    } finally {
        setLoading(false);
    }
};

  useEffect(() => {
    fetchDashboardData();
    const interval = setInterval(fetchDashboardData, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, [timeRange]);

  const handleExportReport = async () => {
    try {
      await schoolService.generateReport({ timeRange });
    } catch (error) {
      setError('Failed to export report');
    }
  };

  // Prepare grade performance data for chart with null check
const gradePerformanceData = dashboardData.gradeDistribution 
? Object.entries(dashboardData.gradeDistribution).map(([grade, data]) => ({
    grade: grade,
    accuracy: parseFloat(data.accuracy) || 0,
    total: data.total || 0
  }))
: [];

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-900">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-blue-400 border-t-transparent rounded-full animate-spin mx-auto mb-4" />
          <p className="text-gray-400 font-medium">Loading dashboard...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900">
      <Sidebar isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />

      <div className="lg:ml-64 min-h-screen">
        <div className="p-6 space-y-6">
          {/* Header */}
          <div className="bg-gradient-to-r from-gray-800 to-gray-900 rounded-xl shadow-xl p-6 border border-gray-700/50">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
              <div className="flex items-center gap-4">
                <button
                  onClick={() => setSidebarOpen(true)}
                  className="lg:hidden p-2 text-gray-400 hover:text-white"
                >
                  <Menu className="w-6 h-6" />
                </button>
                <div>
                  <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-400">
                    School Dashboard
                  </h1>
                  <p className="text-gray-400">Track your school's performance and student progress</p>
                </div>
              </div>
              
              <div className="flex items-center gap-4">
                <button 
                  onClick={fetchDashboardData}
                  className="p-2 text-gray-400 hover:text-gray-200 rounded-lg hover:bg-gray-800 transition-colors"
                >
                  <RefreshCcw className="w-5 h-5" />
                </button>

                <button 
                  onClick={handleExportReport}
                  className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-blue-400 to-teal-400 
                           text-white rounded-lg hover:from-blue-500 hover:to-teal-500 transition-colors"
                >
                  <Download className="w-4 h-4" />
                  <span>Export Report</span>
                </button>

                <button 
                  onClick={() => setShowNotifications(!showNotifications)}
                  className="relative p-2 bg-gray-900/50 border border-gray-700 rounded-lg 
                           text-gray-300 hover:bg-gray-800 transition-colors"
                >
                  <Bell className="w-5 h-5" />
                  <span className="absolute top-0 right-0 w-2 h-2 bg-blue-400 rounded-full"></span>
                </button>

                <select 
                  value={timeRange}
                  onChange={(e) => setTimeRange(e.target.value)}
                  className="px-4 py-2 bg-gray-900/50 border border-gray-700 rounded-lg text-gray-300 
                           focus:ring-2 focus:ring-blue-400/50"
                >
                  <option value="week">This Week</option>
                  <option value="month">This Month</option>
                  <option value="quarter">This Quarter</option>
                  <option value="year">This Year</option>
                </select>
              </div>
            </div>
          </div>

          {/* Error Alert */}
          {error && (
            <div className="bg-red-900/20 text-red-400 p-4 rounded-xl flex items-center gap-2 border border-red-900/50">
              <Info className="w-5 h-5 flex-shrink-0" />
              <span>{error}</span>
            </div>
          )}

          {/* Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <StatCard 
              title="Total Students"
              value={dashboardData.totalStudents}
              icon={Users}
              description="Active students"
              color="from-blue-400 to-teal-400"
            />
            <StatCard 
              title="Total Tests"
              value={dashboardData.totalTests}
              icon={Calendar}
              description="Tests completed"
              color="from-teal-400 to-green-400"
            />
            <StatCard 
              title="Average Score"
              value={`${dashboardData.averageScore}%`}
              icon={BarChart2}
              description="Overall performance"
              color="from-blue-400 to-indigo-400"
            />
            <StatCard 
              title="Active Grades"
              value={Object.keys(dashboardData.gradeDistribution).length}
              icon={GraduationCap}
              description="Participating grades"
              color="from-indigo-400 to-purple-400"
            />
          </div>

          {/* Grade Performance Chart */}
          <GradePerformanceChart data={gradePerformanceData} />

          {/* Recent Tests */}
          <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50 p-6">
            <CardHeader className="flex flex-row items-center justify-between">
              <CardTitle className="text-lg font-semibold text-gray-100">Recent Tests</CardTitle>
              <div className="flex items-center gap-4">
                <div className="relative">
                  <Search className="w-4 h-4 text-gray-500 absolute left-3 top-1/2 -translate-y-1/2" />
                  <input 
                    type="text"
                    placeholder="Search tests..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="pl-10 pr-4 py-2 bg-gray-900/50 border border-gray-700 rounded-lg 
                             text-gray-300 placeholder-gray-500 focus:ring-2 focus:ring-blue-400/50"
                  />
                </div>
                <select
                  value={selectedGrade}
                  onChange={(e) => setSelectedGrade(e.target.value)}
                  className="px-4 py-2 bg-gray-900/50 border border-gray-700 rounded-lg 
                           text-gray-300 focus:ring-2 focus:ring-blue-400/50"
                >
                  <option value="all">All Grades</option>
                  {Object.keys(dashboardData.gradeDistribution).map((grade) => (
                    <option key={grade} value={grade}>{grade}</option>
                  ))}
                </select>
              </div>
            </CardHeader>
            <CardContent>
              <div className="divide-y divide-gray-700">
                {dashboardData.recentTests
                  .filter(test => 
                    (selectedGrade === 'all' || test.grade === selectedGrade) &&
                    (test.student_name?.toLowerCase().includes(searchTerm.toLowerCase()))
                  )
                  .map((test) => (
                    <div 
                      key={test._id} 
                      className="py-4 flex items-center justify-between hover:bg-gray-800/30 
                               rounded-lg transition-colors cursor-pointer"
                      onClick={() => navigate(`/school/tests/${test._id}`)}
                    >
                      <div>
                        <p className="font-medium text-gray-200">{test.student_name}</p>
                        <p className="text-sm text-gray-400">
                          {test.correct_answers}/{test.total_questions} correct
                        </p>
                      </div>
                    </div>
                  ))}

                {dashboardData.recentTests.length === 0 && (
                  <div className="py-8 text-center">
                    <FileText className="w-12 h-12 text-gray-600 mx-auto mb-3" />
                    <p className="text-gray-400">No recent tests found</p>
                  </div>
                )}
              </div>

              {dashboardData.recentTests.length > 0 && (
                <div className="mt-6 text-center">
                  <Link
                    to="/school/tests"
                    className="text-blue-400 hover:text-blue-300 text-sm font-medium"
                  >
                    View All Tests
                  </Link>
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default SchoolDashboard;