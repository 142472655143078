// SchoolStudentsPage.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Filter, Download, 
  AlertCircle, Menu, Plus,
} from 'lucide-react';
import Sidebar from './Sidebar';
import schoolService from '../../services/schoolService';
import AddStudentModal from './AddStudentModal';
import StudentList from './StudentList';
import StudentDetails from './StudentDetails';

const SchoolStudentsPage = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [studentDetails, setStudentDetails] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const navigate = useNavigate();

  // Fetch students list
  const fetchStudents = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await schoolService.getStudentList({
        page: currentPage,
        search: searchTerm,
        limit: 10
      });
      
      if (response.success) {
        // Format the data to match your requirements
        const formattedStudents = response.data.map(student => ({
          _id: student.student_id || student._id,
          name: student.student_info?.name || 'Unnamed Student',
          grade: student.student_info?.grade || 'N/A',
          totalTests: student.totalTests || 0,
          averageScore: student.averageScore || 0,
          lastTestDate: student.lastTestDate || student.createdAt || new Date(),
          email: student.student_info?.email || ''
        }));
        
        setStudents(formattedStudents);
        setTotalPages(response.pagination?.totalPages || 1);
      } else {
        setError(response.error || 'Failed to fetch students');
      }
    } catch (error) {
      setError(error.message || 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  }, [currentPage, searchTerm]);

  // Handle adding new student
  const handleAddStudent = async (email) => {
    try {
      const response = await schoolService.addStudent(email);
      if (response.success) {
        await fetchStudents(); // Refresh the list
        return true;
      } else {
        throw new Error(response.error || 'Failed to add student');
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const fetchStudentDetails = async (studentEmail) => {
    try {
        setSelectedStudent(studentEmail);
        setStudentDetails(null); // Clear previous details
        const response = await schoolService.getStudentDetails(studentEmail);
        
        if (response.success) {
            setStudentDetails(response.data);
            setError(null);
        } else {
            setError(response.error || 'Failed to fetch student details');
            setStudentDetails(null);
        }
    } catch (error) {
        setError(error.message || 'An unexpected error occurred');
        setStudentDetails(null);
    }
};

  // Handle student export
  const handleExportStudent = async () => {
    if (!selectedStudent) return;
    try {
      await schoolService.generateReport({ studentId: selectedStudent });
    } catch (error) {
      setError('Failed to export student report');
    }
  };

  // Handle search
  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  return (
    <div className="min-h-screen bg-gray-900">
      <Sidebar isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />

      <div className="lg:ml-64 min-h-screen">
        <div className="p-6 space-y-6">
          {/* Header */}
          <div className="bg-gradient-to-r from-gray-800 to-gray-900 rounded-xl shadow-xl p-6 border border-gray-700/50">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
              <div className="flex items-center gap-4">
                <button
                  onClick={() => setSidebarOpen(true)}
                  className="lg:hidden p-2 text-gray-400 hover:text-white"
                >
                  <Menu className="w-6 h-6" />
                </button>
                <div>
                  <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-400">
                    Students
                  </h1>
                  <p className="text-gray-400">Manage and monitor student progress</p>
                </div>
              </div>
              
              <div className="flex items-center gap-4">
                <button 
                  onClick={() => setIsAddModalOpen(true)}
                  className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-blue-400 to-teal-400 
                           text-white rounded-lg hover:from-blue-500 hover:to-teal-500"
                >
                  <Plus className="w-4 h-4" />
                  <span>Add Student</span>
                </button>

                <button 
                  className="flex items-center gap-2 px-4 py-2 bg-gray-800 text-gray-300 
                           rounded-lg hover:bg-gray-700 transition-colors"
                >
                  <Filter className="w-4 h-4" />
                  <span>Filter</span>
                </button>
                
                <button 
                  onClick={handleExportStudent}
                  disabled={!selectedStudent}
                  className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg 
                           hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <Download className="w-4 h-4" />
                  <span>Export</span>
                </button>
              </div>
            </div>
          </div>

          {/* Error Alert */}
          {error && (
            <div className="bg-red-900/20 text-red-400 p-4 rounded-xl flex items-center gap-2 border border-red-900/50">
              <AlertCircle className="w-5 h-5 flex-shrink-0" />
              <span>{error}</span>
            </div>
          )}

          {/* Student List and Details Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {/* Student List */}
            <StudentList
              students={students}
              loading={loading}
              selectedStudent={selectedStudent}
              onSelectStudent={fetchStudentDetails}
              onSearch={handleSearch}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />

            {/* Student Details */}
            <div className="lg:col-span-2">
              <StudentDetails 
                student={studentDetails}
                loading={loading}
                onExport={handleExportStudent}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Add Student Modal */}
      <AddStudentModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onAdd={handleAddStudent}
      />
    </div>
  );
};

export default SchoolStudentsPage;