import React from 'react';
import { ArrowUpRight, ArrowDownRight } from 'lucide-react';

const StatCard = ({ title, value, icon: Icon, color, trend, trendLabel }) => {
  const isPositive = trend >= 0;
  const TrendIcon = isPositive ? ArrowUpRight : ArrowDownRight;
  
  return (
    <div className="relative group">
      {/* Background glow effect */}
      <div className="absolute -inset-0.5 bg-gradient-to-r opacity-75 blur-sm group-hover:opacity-100 transition-opacity duration-300 rounded-xl"></div>
      
      {/* Main card content */}
      <div className="relative bg-gradient-to-br from-gray-800 to-gray-900 rounded-xl p-6 border border-gray-700/50 shadow-xl hover:shadow-2xl transition-all duration-300">
        <div className="flex items-start justify-between mb-4">
          <div className={`p-2 rounded-lg bg-gradient-to-br ${color} bg-opacity-15`}>
            <Icon className="w-6 h-6 text-white" />
          </div>
        </div>
        
        <h3 className="text-sm font-medium text-gray-400 mb-1">{title}</h3>
        <div className="flex items-baseline gap-2">
          <span className="text-2xl font-bold text-gray-100">{value}</span>
          
          {trend !== undefined && (
            <div className={`flex items-center gap-1 text-sm ${
              isPositive ? 'text-green-400' : 'text-red-400'
            }`}>
              <TrendIcon className="w-4 h-4" />
              <span>{Math.abs(trend)}%</span>
            </div>
          )}
        </div>
        
        {trendLabel && (
          <p className="text-xs text-gray-500 mt-1">{trendLabel}</p>
        )}
        
        {/* Decorative gradient line */}
        <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r rounded-b-xl opacity-50 group-hover:opacity-75 transition-opacity duration-300"></div>
      </div>
    </div>
  );
};

export default StatCard;