import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Info,
  ArrowUpRight,
  Clock,
  BookOpen
} from 'lucide-react';
import adminService from '../../services/adminService';

const RecentTests = ({ tests = [] }) => {
    const navigate = useNavigate();
    const [selectedTest, setSelectedTest] = useState(null);
    const [testDetails, setTestDetails] = useState(null);
    const [loadingTest, setLoadingTest] = useState(false);

    const formatScore = (score) => {
      if (typeof score === 'number') {
        return `${score.toFixed(1)}%`;
      }
      return 'N/A';
    };
  
    const formatDate = (date) => {
      if (!date) return 'N/A';
      try {
        return new Date(date).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        });
      } catch (error) {
        return 'Invalid Date';
      }
    };

    const handleTestClick = async (test) => {
      if (selectedTest?.test_id === test.test_id) {
        setSelectedTest(null);
        setTestDetails(null);
        return;
      }

      setSelectedTest(test);
      setLoadingTest(true);

      try {
        const response = await adminService.getTestDetails(test.test_id);
        if (response.success) {
          setTestDetails(response.data);
        }
      } catch (error) {
        console.error('Error fetching test details:', error);
      } finally {
        setLoadingTest(false);
      }
    };
  
    return (
      <div className="bg-gray-800 rounded-xl shadow-md p-6 border border-gray-700/50">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h3 className="text-lg font-semibold text-gray-100">Recent Tests</h3>
            <p className="text-sm text-gray-400 mt-1">Latest student assessments</p>
          </div>
          <button 
            onClick={() => navigate('/admin/students')}
            className="flex items-center gap-2 px-3 py-1.5 rounded-lg text-purple-400 hover:text-purple-300 hover:bg-gray-700/50 transition-all duration-300"
          >
            <span className="text-sm font-medium">View All</span>
            <ArrowUpRight className="w-4 h-4" />
          </button>
        </div>
  
        <div className="space-y-3">
          {tests.map((test, index) => (
            <div key={index}>
              <button 
                onClick={() => handleTestClick(test)}
                className="w-full flex items-center justify-between p-4 bg-gray-700/30 rounded-xl hover:bg-gray-700/50 transition-all duration-300 border border-gray-700/50"
              >
                <div className="flex items-center gap-3">
                  <div className="w-10 h-10 rounded-full bg-purple-900/50 flex items-center justify-center border border-purple-700/50">
                    <span className="text-purple-400 font-medium">
                      {test.student_name?.charAt(0) || 'U'}
                    </span>
                  </div>
                  <div>
                    <p className="font-medium text-gray-100">{test.student_name || 'Unknown Student'}</p>
                    <p className="text-sm text-gray-400">Grade {test.grade || 'N/A'}</p>
                  </div>
                </div>
                <div className="text-right">
                  <p className="font-semibold text-purple-400">{formatScore(test.score)}</p>
                  <p className="text-sm text-gray-400">{formatDate(test.date)}</p>
                </div>
              </button>

              {/* Test Details Expansion Panel */}
              {selectedTest?.test_id === test.test_id && (
                <div className="mt-2 p-4 bg-gray-700/20 rounded-xl border border-gray-700/30">
                  {loadingTest ? (
                    <div className="text-center py-4">
                      <div className="animate-spin w-8 h-8 border-3 border-purple-500 border-t-transparent rounded-full mx-auto mb-2" />
                      <p className="text-gray-400">Loading test details...</p>
                    </div>
                  ) : testDetails ? (
                    <>
                      <div className="grid grid-cols-3 gap-4 mb-4">
                        <div className="p-3 bg-gray-700/30 rounded-lg">
                          <p className="text-sm text-gray-400">Time Taken</p>
                          <div className="flex items-center mt-1">
                            <Clock className="w-4 h-4 text-gray-400 mr-2" />
                            <p className="text-gray-100">{Math.round(testDetails.summary?.totalTime / 60)} mins</p>
                          </div>
                        </div>
                        <div className="p-3 bg-gray-700/30 rounded-lg">
                          <p className="text-sm text-gray-400">Questions</p>
                          <div className="flex items-center mt-1">
                            <BookOpen className="w-4 h-4 text-gray-400 mr-2" />
                            <p className="text-gray-100">{testDetails.summary?.totalQuestions}</p>
                          </div>
                        </div>
                        <div className="p-3 bg-gray-700/30 rounded-lg">
                          <p className="text-sm text-gray-400">Correct</p>
                          <div className="flex items-center mt-1">
                            <div className="w-4 h-4 rounded-full bg-green-500 mr-2" />
                            <p className="text-gray-100">{testDetails.summary?.correctAnswers}</p>
                          </div>
                        </div>
                      </div>

                      {/* Questions List */}
                      <div className="space-y-3 mt-6">
                        <h5 className="text-gray-100 font-medium mb-3">Questions</h5>
                        {testDetails.questions?.map((question, idx) => (
                          <div 
                            key={idx}
                            className="p-3 bg-gray-700/30 rounded-lg border border-gray-700/30"
                          >
                            <div className="flex items-start justify-between">
                              <div className="flex-1">
                                <p className="text-gray-100">{question.text}</p>
                                <p className="text-sm text-gray-400 mt-2">
                                  Student Answer: {question.studentAnswer}
                                  {question.correct ? (
                                    <span className="text-green-400 ml-2">(Correct)</span>
                                  ) : (
                                    <span className="text-red-400 ml-2">
                                      (Incorrect - Correct: {question.correctAnswer})
                                    </span>
                                  )}
                                </p>
                              </div>
                              <div className="ml-4">
                                <p className="text-sm text-gray-400">
                                  {question.timeTaken}s
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <p className="text-center text-gray-400">No test details available</p>
                  )}
                </div>
              )}
            </div>
          ))}
  
          {tests.length === 0 && (
            <div className="text-center py-12">
              <div className="w-16 h-16 bg-gray-700/50 rounded-full flex items-center justify-center mx-auto mb-4 border border-gray-700">
                <Info className="w-8 h-8 text-gray-400" />
              </div>
              <p className="text-gray-300 font-medium">No recent tests found</p>
              <p className="text-sm text-gray-400 mt-1">Tests will appear here once students complete them</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  export default RecentTests;