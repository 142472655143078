import React, { useState, useCallback } from 'react';
import { Upload, CheckCircle, XCircle, AlertCircle, ChevronDown, ChevronRight, X, Loader } from 'lucide-react';
import Papa from 'papaparse';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/components/ui/alert";
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription } from "@/components/ui/alert-dialog";
import MathDisplay from '../common/MathDisplay';
import adminService from '../../services/adminService';

const CSVUploadModal = ({ onClose, onUpload }) => {
  // State management
  const [csvData, setCsvData] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const [validatingStatus, setValidatingStatus] = useState('idle'); // idle, validating, uploading
  const [selectedFile, setSelectedFile] = useState(null);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [previewQuestion, setPreviewQuestion] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const [duplicateIds, setDuplicateIds] = useState([]);

  // Validation helpers
  const isNumeric = (value) => !isNaN(value) && value !== null && value !== '';
  const isInRange = (value, min, max) => isNumeric(value) && value >= min && value <= max;
  
  const validateQuestion = (question, index) => {
    const errors = [];
    
    // Required fields validation
    const requiredFields = [
      'Question ID', 'Question', 
      'Option A', 'Option B', 'Option C', 'Option D', 
      'Correct Option'
    ];
    
    requiredFields.forEach(field => {
      if (!question[field]?.toString().trim()) {
        errors.push({
          row: index + 1,
          field,
          error: `Missing required field: ${field}`
        });
      }
    });

    // Question ID validation
    if (!isNumeric(question['Question ID'])) {
      errors.push({
        row: index + 1,
        field: 'Question ID',
        error: 'Question ID must be a number'
      });
    }

    // Correct Option validation
    if (!['A', 'B', 'C', 'D'].includes(question['Correct Option'])) {
      errors.push({
        row: index + 1,
        field: 'Correct Option',
        error: 'Correct Option must be A, B, C, or D'
      });
    }

    // Difficulty levels validation
    for (let i = 1; i <= 12; i++) {
      const field = `D Level Grade ${i}`;
      const value = question[field];
      if (value && !isInRange(value, 0, 5)) {
        errors.push({
          row: index + 1,
          field,
          error: 'Difficulty level must be between 0 and 5'
        });
      }
    }

    return errors;
  };

  const validateQuestions = useCallback((questions) => {
    let allErrors = [];
    questions.forEach((question, index) => {
      const questionErrors = validateQuestion(question, index);
      allErrors = [...allErrors, ...questionErrors];
    });
    return allErrors;
  }, []);

  // File handling
  const handleFileDrop = useCallback((event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) handleFile(file);
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleFileUpload = useCallback((event) => {
    const file = event.target.files[0];
    if (file) handleFile(file);
  }, []);

  const handleFile = (file) => {
    setSelectedFile(file);
    setValidatingStatus('validating');
    setUploadProgress(0);
    setCsvData(null);
    setValidationErrors([]);
    setUploadError(null);
    setDuplicateIds([]);
    
    const reader = new FileReader();
    reader.onload = (event) => {
      const csvText = event.target.result;
      
      // Pre-process the CSV content
      const lines = csvText.split('\n');
      const headerLine = lines[0].trim();
      const dataLines = lines.slice(1).filter(line => line.trim().length > 0);
      const processedCsv = [headerLine, ...dataLines].join('\n');
      
      Papa.parse(processedCsv, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header) => header.trim(),
        complete: (results) => {
          if (!results.data || results.data.length === 0) {
            setValidationErrors([{
              row: 0,
              field: 'CSV Content',
              error: 'Failed to parse CSV content properly'
            }]);
            setValidatingStatus('validated');
            return;
          }
          
          // Filter out empty rows
          const filteredData = results.data.filter(row => 
            Object.values(row).some(value => 
              value !== null && value !== undefined && value.toString().trim() !== ''
            )
          );
          
          // Validate structure
          const requiredFields = [
            'Question ID', 'Question', 
            'Option A', 'Option B', 'Option C', 'Option D', 
            'Correct Option'
          ];
          
          const actualFields = Object.keys(filteredData[0] || {});
          const missingFields = requiredFields.filter(
            field => !actualFields.includes(field)
          );
          
          if (missingFields.length > 0) {
            setValidationErrors([{
              row: 0,
              field: 'CSV Structure',
              error: `Missing required columns: ${missingFields.join(', ')}`
            }]);
            setValidatingStatus('validated');
            return;
          }
  
          setCsvData(filteredData);
          const errors = validateQuestions(filteredData);
          setValidationErrors(errors);
          setValidatingStatus('validated');
          setUploadProgress(100);
        },
        error: (error) => {
          setValidationErrors([{ 
            row: 0, 
            field: 'CSV Parsing',
            error: `Failed to parse CSV: ${error.message}` 
          }]);
          setValidatingStatus('validated');
        }
      });
    };
    
    reader.onerror = (error) => {
      setValidationErrors([{
        row: 0,
        field: 'File Reading',
        error: 'Failed to read the file'
      }]);
      setValidatingStatus('validated');
    };
    
    reader.readAsText(file);
  };

  // Row expansion handling
  const toggleRowExpand = (index) => {
    const newExpanded = new Set(expandedRows);
    if (newExpanded.has(index)) {
      newExpanded.delete(index);
    } else {
      newExpanded.add(index);
    }
    setExpandedRows(newExpanded);
  };

  // Upload handling
  const handleUpload = async () => {
    if (!csvData || validationErrors.length > 0) return;
    
    try {
      setValidatingStatus('uploading');
      setUploadError(null);
      setDuplicateIds([]);
      setUploadProgress(0);

      // Format data for upload
      const formattedData = csvData.map(row => ({
        question_id: parseInt(row['Question ID']),
        question_text: row['Question'],
        options: {
          A: row['Option A'],
          B: row['Option B'],
          C: row['Option C'],
          D: row['Option D']
        },
        correct_answer: row['Correct Option'],
        difficulty_levels: Object.fromEntries(
          [...Array(12)].map((_, i) => [
            `grade_${i + 1}`,
            parseInt(row[`D Level Grade ${i + 1}`]) || 0
          ])
        ),
        concepts: {
          correct_option: row['Concepts in Correct Option'] || '',
          option_a: row['Concepts in Option A'] || '',
          option_b: row['Concepts in Option B'] || '',
          option_c: row['Concepts in Option C'] || '',
          option_d: row['Concepts in Option D'] || ''
        },
        misconceptions: row['Misconceptions in Incorrect Options'] || '',
        analysis_notes: row['Analysis Notes'] || '',
        isValidated: false
      }));

      // Process in batches
      const BATCH_SIZE = 50;
      let successfulUploads = 0;

      for (let i = 0; i < formattedData.length; i += BATCH_SIZE) {
        const batch = formattedData.slice(i, i + BATCH_SIZE);
        try {
          const response = await adminService.createBulkQuestions(batch);
          
          if (response.success) {
            successfulUploads += batch.length;
          } else {
            if (response.message === 'Some question IDs already exist') {
              setDuplicateIds(response.existingIds || []);
              throw new Error('Duplicate question IDs found in database');
            }
            throw new Error(response.message || 'Failed to upload questions');
          }

          const progress = Math.round(((i + batch.length) / formattedData.length) * 100);
          setUploadProgress(progress);

        } catch (error) {
          setUploadError(error.message);
          return;
        }
      }

      onUpload({ 
        success: true, 
        message: `Successfully uploaded ${successfulUploads} questions` 
      });
      onClose();

    } catch (error) {
      setUploadError(error.message);
    } finally {
      setValidatingStatus('validated');
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <Card className="w-full max-w-6xl h-[90vh] flex flex-col">
        <CardHeader className="border-b p-6 flex flex-row items-center justify-between shrink-0">
          <CardTitle>Upload Questions CSV</CardTitle>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
            disabled={validatingStatus === 'uploading'}
          >
            <X className="h-5 w-5" />
          </button>
        </CardHeader>
        
        <CardContent className="p-6 overflow-y-auto flex-grow">
          {!csvData ? (
            <div 
              className="border-2 border-dashed rounded-lg p-12 text-center"
              onDrop={handleFileDrop}
              onDragOver={handleDragOver}
            >
              <div className="flex flex-col items-center gap-4">
                <Upload className="h-12 w-12 text-gray-400" />
                <div>
                  <p className="text-lg font-semibold">
                    Drop your CSV file here or{' '}
                    <label className="text-purple-600 hover:text-purple-700 cursor-pointer">
                      browse
                      <input
                        type="file"
                        className="hidden"
                        accept=".csv"
                        onChange={handleFileUpload}
                      />
                    </label>
                  </p>
                  <p className="text-sm text-gray-500 mt-2">
                    Your CSV should include Question ID, Question Text, Options, and Grade-wise Difficulty Levels
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="space-y-6">
              {/* File Info & Stats */}
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <div className="bg-purple-100 p-2 rounded-lg">
                    <Upload className="h-5 w-5 text-purple-600" />
                  </div>
                  <div>
                    <p className="font-medium">{selectedFile.name}</p>
                    <p className="text-sm text-gray-500">
                      {csvData.length} questions • {validationErrors.length} errors
                    </p>
                  </div>
                </div>
                {(validatingStatus === 'validating' || validatingStatus === 'uploading') && (
                  <div className="flex items-center gap-2">
                    <Loader className="h-5 w-5 animate-spin" />
                    <div className="h-2 w-24 bg-gray-200 rounded-full overflow-hidden">
                      <div 
                        className="h-full bg-purple-600 transition-all duration-300"
                        style={{ width: `${uploadProgress}%` }}
                      />
                    </div>
                    <span className="text-sm text-gray-500">
                      {uploadProgress}%
                    </span>
                  </div>
                )}
              </div>

              {/* Error Display */}
              {uploadError && (
                <Alert variant="destructive">
                  <AlertCircle className="h-4 w-4" />
                  <AlertTitle>Upload Error</AlertTitle>
                  <AlertDescription>
                    {uploadError}
                    {duplicateIds.length > 0 && (
                      <div className="mt-2">
                        <p>Duplicate Question IDs:</p>
                        <p className="text-sm mt-1">{duplicateIds.join(', ')}</p>
                      </div>
                    )}
                  </AlertDescription>
                </Alert>
              )}

              {/* Questions Table */}
              <div className="border rounded-lg overflow-hidden">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-10"></TableHead>
                      <TableHead>Question ID</TableHead>
                      <TableHead className="w-1/2">Question</TableHead>
                      <TableHead>Correct Option</TableHead>
                      <TableHead>Status</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {csvData.map((row, index) => {
                      const hasError = validationErrors.some(e => e.row === index + 1);
                      const isDuplicate = duplicateIds.includes(parseInt(row['Question ID']));
                      
                      return (
                        <React.Fragment key={index}>
                          <TableRow 
                            className={`cursor-pointer hover:bg-gray-50 ${isDuplicate ? 'bg-red-50' : ''}`}
                            onClick={() => toggleRowExpand(index)}
                          >
                            <TableCell>
                              {expandedRows.has(index) ? 
                                <ChevronDown className="h-4 w-4" /> : 
                                <ChevronRight className="h-4 w-4" />
                              }
                            </TableCell>
                            <TableCell>{row['Question ID']}</TableCell>
                            <TableCell className="max-w-md truncate">
                              <MathDisplay content={row['Question']} />
                            </TableCell>
                            <TableCell>{row['Correct Option']}</TableCell>
                            <TableCell>
                              {isDuplicate ? (
                                <span className="inline-flex items-center gap-1 text-red-600">
                                  <AlertCircle className="h-4 w-4" />
                                  Duplicate ID
                                </span>
                              ) : hasError ? (
                                <span className="inline-flex items-center gap-1 text-red-600">
                                  <AlertCircle className="h-4 w-4" />
                                  Invalid
                                </span>
                              ) : (
                                <span className="inline-flex items-center gap-1 text-green-600">
                                  <CheckCircle className="h-4 w-4" />
                                  Valid
                                </span>
                              )}
                            </TableCell>
                          </TableRow>
                          
                          {expandedRows.has(index) && (
                            <TableRow>
                              <TableCell colSpan={5} className="bg-gray-50 p-6">
                                <div className="space-y-6">
                                  {/* Options Grid */}
                                  <div className="grid grid-cols-2 gap-4">
                                    {['A', 'B', 'C', 'D'].map(option => (
                                      <div
                                        key={option}
                                        className={`p-4 rounded-lg ${
                                          row['Correct Option'] === option
                                            ? 'bg-green-50 border-green-200'
                                            : 'bg-white border'
                                        } border`}
                                      >
                                        <p className="font-medium mb-2">
                                          Option {option}
                                        </p>
                                        <MathDisplay 
                                          content={row[`Option ${option}`]} 
                                        />
                                      </div>
                                    ))}
                                  </div>
                                  
                                  {/* Difficulty Levels Grid */}
                                  <div>
                                    <h4 className="font-medium mb-3">
                                      Difficulty Levels
                                    </h4>
                                    <div className="grid grid-cols-6 gap-3">
                                      {[...Array(12)].map((_, i) => (
                                        <div
                                          key={i}
                                          className="p-3 bg-white border rounded-lg"
                                        >
                                          <p className="text-sm text-gray-500">
                                            Grade {i + 1}
                                          </p>
                                          <p className="font-medium mt-1">
                                            {row[`D Level Grade ${i + 1}`] || '0'}
                                          </p>
                                        </div>
                                      ))}
                                    </div>
                                  </div>

                                  {/* Validation Errors */}
                                  {validationErrors
                                    .filter(error => error.row === index + 1)
                                    .map((error, errorIndex) => (
                                      <div
                                        key={errorIndex}
                                        className="bg-red-50 border border-red-200 rounded-lg p-4 text-red-600"
                                      >
                                        <p>
                                          <span className="font-medium">
                                            {error.field}:
                                          </span>{' '}
                                          {error.error}
                                        </p>
                                      </div>
                                    ))}

                                  {/* Additional Fields */}
                                  {(row['Concepts in Correct Option'] || 
                                   row['Misconceptions in Incorrect Options'] || 
                                   row['Analysis Notes']) && (
                                    <div className="space-y-4">
                                      {row['Concepts in Correct Option'] && (
                                        <div>
                                          <h4 className="font-medium mb-2">Concepts in Correct Option</h4>
                                          <p className="text-sm">{row['Concepts in Correct Option']}</p>
                                        </div>
                                      )}
                                      {row['Misconceptions in Incorrect Options'] && (
                                        <div>
                                          <h4 className="font-medium mb-2">Misconceptions</h4>
                                          <p className="text-sm">{row['Misconceptions in Incorrect Options']}</p>
                                        </div>
                                      )}
                                      {row['Analysis Notes'] && (
                                        <div>
                                          <h4 className="font-medium mb-2">Analysis Notes</h4>
                                          <p className="text-sm">{row['Analysis Notes']}</p>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </div>
          )}
        </CardContent>

        {csvData && (
          <CardFooter className="border-t p-6 flex justify-end gap-3 shrink-0">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
              disabled={validatingStatus === 'uploading'}
            >
              Cancel
            </button>
            <button
              onClick={handleUpload}
              disabled={!csvData || validationErrors.length > 0 || validatingStatus === 'uploading'}
              className={`px-4 py-2 rounded-lg transition-colors flex items-center gap-2 ${
                !csvData || validationErrors.length > 0 || validatingStatus === 'uploading'
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-purple-600 text-white hover:bg-purple-700'
              }`}
            >
              {validatingStatus === 'uploading' && (
                <Loader className="h-4 w-4 animate-spin" />
              )}
              {validatingStatus === 'uploading' ? 'Uploading...' : `Upload ${csvData?.length} Questions`}
            </button>
          </CardFooter>
        )}
      </Card>

      {/* Question Preview Modal */}
      {previewQuestion && (
        <AlertDialog open={!!previewQuestion} onOpenChange={() => setPreviewQuestion(null)}>
          <AlertDialogContent className="max-w-3xl max-h-[90vh] overflow-y-auto">
            <AlertDialogHeader>
              <AlertDialogTitle className="flex justify-between items-center">
                Question Preview
                <button
                  onClick={() => setPreviewQuestion(null)}
                  className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  <X className="h-5 w-5" />
                </button>
              </AlertDialogTitle>
            </AlertDialogHeader>
            
            <div className="mt-6 space-y-6">
              <div>
                <p className="text-sm text-gray-500 mb-2">
                  Question ID: {previewQuestion?.['Question ID']}
                </p>
                <div className="text-lg">
                  <MathDisplay content={previewQuestion?.['Question']} />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                {['A', 'B', 'C', 'D'].map(option => (
                  <div
                    key={option}
                    className={`p-4 rounded-lg ${
                      previewQuestion?.['Correct Option'] === option
                        ? 'bg-green-50 border-green-200'
                        : 'bg-gray-50 border-gray-200'
                    } border`}
                  >
                    <p className="font-medium mb-2">Option {option}</p>
                    <MathDisplay content={previewQuestion?.[`Option ${option}`]} />
                  </div>
                ))}
              </div>

              <div>
                <h4 className="font-medium mb-3">Difficulty Levels by Grade</h4>
                <div className="grid grid-cols-6 gap-3">
                  {[...Array(12)].map((_, i) => (
                    <div
                      key={i}
                      className="p-3 bg-gray-50 border border-gray-200 rounded-lg"
                    >
                      <p className="text-sm text-gray-500">Grade {i + 1}</p>
                      <p className="font-medium mt-1">
                        {previewQuestion?.[`D Level Grade ${i + 1}`] || '0'}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </div>
  );
};

export default CSVUploadModal;