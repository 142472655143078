// client/src/services/authService.js
import api from './api';

export const authService = {
  async login(email, password) {
    try {
      const response = await api.post('/admin/login', { email, password });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Login failed');
    }
  },

  async verifyToken() {
    try {
      const response = await api.get('/admin/verify-token');
      return response.data;
    } catch (error) {
      throw new Error('Token verification failed');
    }
  },

  async getProfile() {
    try {
      const response = await api.get('/admin/profile');
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch profile');
    }
  }
};