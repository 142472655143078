import React from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';

const SidebarMenu = ({ isOpen, onToggle }) => {
  const location = useLocation();
  
  const menuItems = [
    {
      title: 'Dashboard',
      icon: (
        <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
      ),
      path: '/dashboard',
      disabled: false
    },
    {
      title: 'Practice Tests',
      icon: (
        <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
        </svg>
      ),
      path: '/practice',
      disabled: true
    },
    {
      title: 'Progress',
      icon: (
        <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg>
      ),
      path: '/progress',
      disabled: true
    },
    {
      title: 'Resources',
      icon: (
        <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
        </svg>
      ),
      path: '/resources',
      disabled: true
    }
  ];

  return (
    <motion.div
      initial={false}
      animate={{
        width: isOpen ? 'w-64' : 'w-20',
        transition: { duration: 0.3 }
      }}
      className={`hidden lg:flex flex-col bg-white border-r border-gray-200 h-screen sticky top-0 ${isOpen ? 'w-64' : 'w-20'}`}
    >
      <div className="p-4 flex justify-end">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={onToggle}
          className="p-2 rounded-lg hover:bg-gray-100 text-gray-600"
        >
          <svg className={`w-5 h-5 transition-transform ${isOpen ? 'rotate-180' : ''}`} 
            fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
              d="M9 5l7 7-7 7" />
          </svg>
        </motion.button>
      </div>

      <nav className="flex-1 px-2 py-4 space-y-2">
        {menuItems.map((item) => (
          <div
            key={item.title}
            className={`flex items-center gap-3 px-4 py-3 rounded-lg transition-colors
              ${item.disabled 
                ? 'opacity-50 cursor-not-allowed bg-gray-50' 
                : location.pathname === item.path 
                  ? 'bg-purple-50 text-purple-600 cursor-pointer' 
                  : 'text-gray-600 hover:bg-gray-50 cursor-pointer'}`}
          >
            <div className={`${item.disabled ? 'text-gray-400' : ''}`}>
              {item.icon}
            </div>
            {isOpen && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.1 }}
                className="font-medium"
              >
                {item.title}
                {item.disabled && (
                  <span className="ml-2 text-xs text-gray-400">(Coming Soon)</span>
                )}
              </motion.span>
            )}
          </div>
        ))}
      </nav>
    </motion.div>
  );
};

export default SidebarMenu;