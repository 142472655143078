// client/src/components/admin/forms/QuestionConceptsForm.jsx
import React, { useState, useEffect } from 'react';
import { X, Plus, Search } from 'lucide-react';

const ConceptTag = ({ concept, onRemove }) => (
  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm 
                 bg-purple-900/50 text-purple-300 mr-2 mb-2 border border-purple-700/50">
    {concept}
    <button
      onClick={() => onRemove(concept)}
      className="ml-1 hover:text-purple-200"
    >
      <X size={14} />
    </button>
  </span>
);


const ConceptInput = ({ label, concepts = [], onChange, availableConcepts = [] }) => {
  const [inputValue, setInputValue] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredConcepts, setFilteredConcepts] = useState([]);
  
  useEffect(() => {
    if (inputValue) {
      const filtered = availableConcepts.filter(concept => 
        concept.toLowerCase().includes(inputValue.toLowerCase()) &&
        !concepts.includes(concept)
      );
      setFilteredConcepts(filtered);
    } else {
      setFilteredConcepts([]);
    }
  }, [inputValue, concepts, availableConcepts]);

  const handleAddConcept = (concept) => {
    if (!concepts.includes(concept)) {
      onChange([...concepts, concept]);
    }
    setInputValue('');
    setShowSuggestions(false);
  };

  const handleRemoveConcept = (conceptToRemove) => {
    onChange(concepts.filter(c => c !== conceptToRemove));
  };

  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-gray-300">
        {label}
      </label>
      
      <div className="min-h-[40px] p-2 border border-gray-700 rounded-lg bg-gray-900/50">
        {concepts.map(concept => (
          <ConceptTag
            key={concept}
            concept={concept}
            onRemove={handleRemoveConcept}
          />
        ))}
      </div>

      <div className="relative">
        <div className="relative">
          <input
            type="text"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              setShowSuggestions(true);
            }}
            onFocus={() => setShowSuggestions(true)}
            className="w-full p-2 pr-10 bg-gray-900/50 border border-gray-700 rounded-lg focus:ring-2 focus:ring-purple-500 text-gray-100 placeholder-gray-500"
            placeholder="Type to search concepts..."
          />
          <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
        </div>

        {showSuggestions && filteredConcepts.length > 0 && (
          <div className="absolute z-10 w-full mt-1 bg-gray-800 border border-gray-700 rounded-lg shadow-lg max-h-60 overflow-auto">
            {filteredConcepts.map(concept => (
              <button
                key={concept}
                onClick={() => handleAddConcept(concept)}
                className="w-full px-4 py-2 text-left hover:bg-gray-700/50 flex items-center text-gray-100"
              >
                <Plus size={16} className="mr-2 text-gray-400" />
                {concept}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const QuestionConceptsForm = ({ data, onChange }) => {
  // This would typically come from your API or context
  const [availableConcepts, setAvailableConcepts] = useState([
    'ALGEBR', 'GEOM', 'TRIG', 'CALC', 'STATS',
    // Add more concepts here
  ]);

  const handleConceptChange = (type, concepts) => {
    onChange({
      ...data,
      concepts: {
        ...data.concepts,
        [type]: concepts
      }
    });
  };

  return (
    <div className="space-y-6">
      <ConceptInput
        label="Correct Answer Concepts"
        concepts={data.concepts.correct_option}
        onChange={(concepts) => handleConceptChange('correct_option', concepts)}
        availableConcepts={availableConcepts}
      />

      {['A', 'B', 'C', 'D'].map(option => (
        <div key={option} className={`p-4 rounded-lg border ${
          data.correct_answer === option ? 'border-green-500' : 'border-gray-200'
        }`}>
          <ConceptInput
            label={`Option ${option} Concepts ${
              data.correct_answer === option ? '(Correct Answer)' : ''
            }`}
            concepts={data.concepts[`option_${option.toLowerCase()}`]}
            onChange={(concepts) => handleConceptChange(`option_${option.toLowerCase()}`, concepts)}
            availableConcepts={availableConcepts}
          />
        </div>
      ))}
    </div>
  );
};

export default QuestionConceptsForm;