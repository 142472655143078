import React, { useState } from 'react';
import { Info } from 'lucide-react';
import QuestionCard from './QuestionCard';
import Pagination from '../common/Pagination';

const QuestionsList = ({
  questions,
  currentPage,
  itemsPerPage,
  onPageChange,
  onItemsPerPageChange,
  loading,
  onEditQuestion,
  onPreviewQuestion,
  onDeleteQuestion,
  onValidate
}) => {
  const [expandedQuestions, setExpandedQuestions] = useState(new Set());
  
  const totalPages = Math.ceil(questions.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedQuestions = questions.slice(startIndex, startIndex + itemsPerPage);

  const handleToggleExpand = (questionId) => {
    const newExpanded = new Set(expandedQuestions);
    if (newExpanded.has(questionId)) {
      newExpanded.delete(questionId);
    } else {
      newExpanded.add(questionId);
    }
    setExpandedQuestions(newExpanded);
  };

  if (loading) {
    return (
      <div className="text-center py-12">
        <div className="animate-spin w-16 h-16 border-4 border-purple-600 border-t-transparent rounded-full mb-4"></div>
        <p className="text-gray-400">Loading questions...</p>
      </div>
    );
  }

  if (questions.length === 0) {
    return (
      <div className="text-center py-12 bg-white rounded-xl border">
        <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-4">
          <Info className="w-8 h-8 text-gray-400" />
        </div>
        <p className="text-gray-600 font-medium">No questions found</p>
        <p className="text-sm text-gray-400 mt-1">Try adjusting your search criteria</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        {paginatedQuestions.map(question => (
          <QuestionCard
            key={question._id}
            question={question}
            onEdit={() => onEditQuestion(question)}
            onPreview={() => onPreviewQuestion(question)}
            onDelete={() => onDeleteQuestion(question._id)}
            onValidate={onValidate}
            expanded={expandedQuestions.has(question._id)}
            onToggleExpand={() => handleToggleExpand(question._id)}
          />
        ))}
      </div>

      {questions.length > itemsPerPage && (
        <div className="mt-6">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={onItemsPerPageChange}
          />
        </div>
      )}
    </div>
  );
};

export default QuestionsList;