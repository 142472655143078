// client/src/components/admin/forms/QuestionBasicForm.jsx
import React from 'react';
import MathDisplay from '../../common/MathDisplay';

const QuestionBasicForm = ({ data, onChange }) => {
  const handleChange = (field, value) => {
    onChange({ ...data, [field]: value });
  };

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-300 mb-2">
          Question ID
        </label>
        <input
          type="text"
          value={data.question_id}
          onChange={(e) => handleChange('question_id', e.target.value)}
          className="w-full p-2 bg-gray-900/50 border border-gray-700 rounded-lg text-gray-100"
          disabled
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-300 mb-2">
          Question Text
        </label>
        <textarea
          value={data.question_text}
          onChange={(e) => handleChange('question_text', e.target.value)}
          rows={4}
          className="w-full p-2 bg-gray-900/50 border border-gray-700 rounded-lg resize-none focus:ring-2 focus:ring-purple-500 text-gray-100"
        />
        <div className="mt-2">
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Preview
          </label>
          <div className="text-gray-100">
            <MathDisplay content={data.question_text} />
          </div>
        </div>
      </div>
    </div>
  );
};


export default QuestionBasicForm;