import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Home,
  Users, 
  BookOpen, 
  Settings,
  BarChart2,
} from 'lucide-react';
import AdminNavbar from './AdminNavbar';
import useAdminAuth from '../../../hooks/useAdminAuth';

const navigationItems = [
  { 
    name: 'Dashboard',
    icon: Home,
    path: '/admin/dashboard'
  },
  { 
    name: 'Students',
    icon: Users,
    path: '/admin/students'
  },
  { 
    name: 'Content',
    icon: BookOpen,
    path: '/admin/content'
  },
  { 
    name: 'Analytics',
    icon: BarChart2,
    path: '/admin/'
  },
  { 
    name: 'Settings',
    icon: Settings,
    path: '/admin/'
  }
];

const Sidebar = ({ isOpen }) => {
  const location = useLocation();

  return (
    <aside className={`
      fixed lg:sticky top-16 left-0
      h-[calc(100vh-4rem)] 
      w-64
      bg-gray-900 border-r border-gray-700
      transition-transform duration-300
      ${isOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
      z-40
      overflow-y-auto
    `}>
      <div className="p-4">
        {/* Navigation Section */}
        <div className="mb-6">
          <h3 className="px-3 text-xs font-semibold text-gray-400 uppercase tracking-wider mb-3">
            Main Navigation
          </h3>
          <nav className="space-y-1">
            {navigationItems.map((item) => {
              const Icon = item.icon;
              const isActive = location.pathname === item.path;
              
              return (
                <Link
                  key={item.name}
                  to={item.path}
                  className={`
                    flex items-center gap-3 px-3 py-2 rounded-lg
                    transition-colors duration-200
                    ${isActive 
                      ? 'bg-purple-600/20 text-purple-400' 
                      : 'text-gray-400 hover:bg-gray-800 hover:text-gray-200'
                    }
                  `}
                >
                  <Icon className="w-5 h-5" />
                  <span className="font-medium">{item.name}</span>
                </Link>
              );
            })}
          </nav>
        </div>
      </div>
    </aside>
  );
};

const AdminLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { adminEmail, logout } = useAdminAuth();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-800">
      {/* Navbar */}
      <AdminNavbar 
        adminEmail={adminEmail}
        onLogout={handleLogout}
        onMenuToggle={() => setIsSidebarOpen(!isSidebarOpen)}
        isMenuOpen={isSidebarOpen}
      />

      {/* Mobile Overlay */}
      {isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black/50 lg:hidden z-30"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}

      {/* Main Layout */}
      <div className="pt-16 lg:flex">
        <Sidebar isOpen={isSidebarOpen} />
        
        <main className="flex-1 p-6">
          <div className="max-w-7xl mx-auto">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;