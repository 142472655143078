// client/src/routes/AppRoutes.jsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { TestProvider } from '../contexts/TestContext.jsx';
import { AdminProvider } from '../contexts/AdminContext.jsx';
import { SchoolProvider } from '../contexts/SchoolContext.jsx';

// Common Pages
import HomePage from '../pages/HomePage.jsx';
import TestPage from '../pages/TestPage.jsx';
import ResultsPage from '../pages/ResultsPage.jsx';
import Dashboard from '../pages/Dashboard.jsx';

// Admin Components
import ContentDashboard from '../components/admin/ContentDashboard';
import AdminAuth from '../auth/AdminAuth';
import AdminDashboard from '../components/admin/AdminDashboard.jsx';
import AdminStudentsPage from '../components/admin/AdminStudentsPage.jsx';

// School Components
import SchoolAuth from '../auth/SchoolAuth.jsx';
import SchoolDashboard from '../components/school/SchoolDashboard';
import SchoolStudentsPage from '../components/school/SchoolStudentsPage';
import SchoolTestsPage from '../components/school/SchoolTestsPage';
// import SchoolSettingsPage from '../components/school/SchoolSettingsPage';

// Protected Routes
import ProtectedRoute from './ProtectedRoute.jsx';
import AdminProtectedRoute from './AdminProtectedRoute.jsx';
import SchoolProtectedRoute from './SchoolProtectedRoute.jsx';

const StudentRoutes = () => {
  const hasSession = sessionStorage.getItem('studentEmail');
  const isStartingNewTest = sessionStorage.getItem('startingNewTest');

  return (
    <TestProvider>
      <Routes>
        {/* Home Route - Show HomePage if starting new test or no session */}
        <Route 
          path="/" 
          element={
            hasSession && !isStartingNewTest ? 
              <Navigate to="/dashboard" replace /> : 
              <HomePage />
          } 
        />
        
        {/* Dashboard Route - Protected, requires email */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute requiresTest={false}>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        
        {/* Test Route - Protected, requires both email and testId */}
        <Route
          path="/test"
          element={
            <ProtectedRoute requiresTest={true}>
              <TestPage />
            </ProtectedRoute>
          }
        />
        
        {/* Results Route - Protected, requires both email and testId */}
        <Route
          path="/results/:testId"
          element={
            <ProtectedRoute requiresTest={true}>
              <ResultsPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </TestProvider>
  );
};

const AdminRoutes = () => {
  return (
    <AdminProvider>
      <Routes>
        <Route path="/login" element={<AdminAuth />} />
        
        {/* Admin Dashboard */}
        <Route
          path="/dashboard"
          element={
            <AdminProtectedRoute>
              <AdminDashboard />
            </AdminProtectedRoute>
          }
        />

        {/* Students Management */}
        <Route
          path="/students"
          element={
            <AdminProtectedRoute>
              <AdminStudentsPage />
            </AdminProtectedRoute>
          }
        />
        
        {/* Content Management */}
        <Route
          path="/content"
          element={
            <AdminProtectedRoute>
              <ContentDashboard />
            </AdminProtectedRoute>
          }
        />
        
        {/* Root admin route - Redirect to dashboard */}
        <Route
          path="/"
          element={
            <AdminProtectedRoute>
              <Navigate to="/admin/dashboard" replace />
            </AdminProtectedRoute>
          }
        />
      </Routes>
    </AdminProvider>
  );
};

const SchoolRoutes = () => {
  return (
    <SchoolProvider>
      <Routes>
        {/* School Auth */}
        <Route path="/login" element={<SchoolAuth />} />
        
        {/* School Dashboard */}
        <Route
          path="/dashboard"
          element={
            <SchoolProtectedRoute>
              <SchoolDashboard />
            </SchoolProtectedRoute>
          }
        />

        {/* School Students Management */}
        <Route
          path="/students"
          element={
            <SchoolProtectedRoute>
              <SchoolStudentsPage />
            </SchoolProtectedRoute>
          }
        />

        {/* School Tests Management */}
        <Route
          path="/tests"
          element={
            <SchoolProtectedRoute>
              <SchoolTestsPage />
            </SchoolProtectedRoute>
          }
        />

        {/* School Settings */}
        {/* <Route
          path="/settings"
          element={
            <SchoolProtectedRoute>
              <SchoolSettingsPage />
            </SchoolProtectedRoute>
          }
        /> */}
        
        {/* Root school route - Redirect to dashboard */}
        <Route
          path="/"
          element={
            <SchoolProtectedRoute>
              <Navigate to="/school/dashboard" replace />
            </SchoolProtectedRoute>
          }
        />
      </Routes>
    </SchoolProvider>
  );
};

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* Admin routes */}
        <Route path="/admin/*" element={<AdminRoutes />} />
        
        {/* School routes */}
        <Route path="/school/*" element={<SchoolRoutes />} />
        
        {/* Student routes */}
        <Route path="/*" element={<StudentRoutes />} />
        
        {/* Catch all route - Redirect to home */}
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>
    </Router>
  );
};

export default AppRoutes;