import React, { useState, useEffect, useCallback } from 'react';
import { Info, Search, Users, AlertTriangle } from 'lucide-react';
import AdminLayout from './layout/AdminLayout';
import adminService from '../../services/adminService';
import StudentList from './StudentList';
import StudentDetails from './StudentDetails';

const AdminStudentsPage = () => {
  // State management
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [studentLoading, setStudentLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalStudents, setTotalStudents] = useState(0);

  // Fetch students with search and pagination
  const fetchStudents = useCallback(async () => {
    try {
      setError(null);
      const response = await adminService.getStudentList({
        page: currentPage,
        limit: 10,
        search: searchTerm.trim(),
        sort: 'lastTestDate',
        order: 'desc'
      });
      
      if (response.success) {
        setStudents(response.data);
        setTotalPages(response.pagination.totalPages);
        setTotalStudents(response.pagination.total);
      } else {
        setError(response.error || 'Failed to fetch students');
      }
    } catch (error) {
      setError(error.message || 'An unexpected error occurred');
      console.error('Students fetch error:', error);
    } finally {
      setLoading(false);
    }
  }, [currentPage, searchTerm]);

  // Fetch individual student details
  const fetchStudentDetails = async (studentId) => {
    if (!studentId) return;
    
    try {
      setStudentLoading(true);
      setError(null);
  
      // Fetch both student details and test history in parallel
      const [detailsResponse, testHistoryResponse] = await Promise.all([
        adminService.getStudentDetails(studentId),
        adminService.getStudentTestHistory(studentId)
      ]);
      
      if (detailsResponse.success) {
        setSelectedStudent({
          ...detailsResponse.data,
          testHistory: testHistoryResponse.success ? testHistoryResponse : { data: [] }
        });
      } else {
        setError(detailsResponse.error || 'Failed to fetch student details');
      }
    } catch (error) {
      setError(error.message || 'An unexpected error occurred');
      console.error('Student details fetch error:', error);
    } finally {
      setStudentLoading(false);
    }
  };

  // Initial load and search effects
  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentPage(1);
      fetchStudents();
    }, 300);

    return () => clearTimeout(timer);
  }, [searchTerm, fetchStudents]);

  return (
    <AdminLayout>
      <div className="space-y-6 mb-8">
        {/* Header Section */}
        <div className="bg-gradient-to-br from-indigo-900 to-purple-900 rounded-xl shadow-lg shadow-purple-900/30 border border-indigo-700/50 p-6">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-2xl font-bold text-indigo-100">Students</h1>
              <p className="text-indigo-300 mt-1">
                Manage and monitor student progress 
                {totalStudents > 0 && (
                  <span className="ml-2 px-2 py-1 bg-indigo-800/50 rounded-full text-sm">
                    {totalStudents} total
                  </span>
                )}
              </p>
            </div>
            <div className="w-12 h-12 bg-indigo-800/50 rounded-full flex items-center justify-center border border-indigo-700/50">
              <Users className="w-6 h-6 text-indigo-300" />
            </div>
          </div>
        </div>

        {/* Global Search Bar */}
        <div className="relative max-w-2xl">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-indigo-300" />
          <input
            type="search"
            placeholder="Search students by name or email..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-3 bg-indigo-800/30 border border-indigo-700/50 rounded-lg 
                     focus:ring-2 focus:ring-pink-500 focus:border-transparent 
                     text-indigo-100 placeholder-indigo-400
                     transition-all duration-300 hover:bg-indigo-800/40"
          />
        </div>

        {/* Error Display */}
        {error && (
          <div className="bg-red-900/20 rounded-xl p-4 flex items-center gap-3 border border-red-900/50 animate-fade-in">
            <div className="w-10 h-10 bg-red-900/30 rounded-full flex items-center justify-center border border-red-900/50">
              <AlertTriangle className="w-5 h-5 text-red-400" />
            </div>
            <div>
              <h3 className="text-red-400 font-medium">Error</h3>
              <p className="text-red-300/80 text-sm mt-1">{error}</p>
            </div>
          </div>
        )}

        {/* Content Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Student List Section */}
          <div className="lg:col-span-1">
            <StudentList
              students={students}
              loading={loading}
              selectedEmail={selectedStudent?.studentInfo?.email}
              onSelectStudent={fetchStudentDetails}
              onSearch={setSearchTerm}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />
          </div>

          {/* Student Details Section */}
          <div className="lg:col-span-2">
            <StudentDetails 
              student={selectedStudent}
              loading={studentLoading}
              onError={setError}
            />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminStudentsPage;