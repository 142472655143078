import { motion } from 'framer-motion';

export const StatCard = ({ label, value, icon, trend }) => {
  // Animation variants for the card
  const cardVariants = {
    hover: {
      scale: 1.02,
      boxShadow: "0 10px 20px rgba(147, 51, 234, 0.1)",
    }
  };

  // Animation variants for the icon
  const iconVariants = {
    hover: { 
      rotate: 360,
      scale: 1.1,
      transition: { duration: 0.5 }
    }
  };

  // Animation variants for the value
  const valueVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    hover: { scale: 1.05 }
  };

  return (
    <motion.div
      variants={cardVariants}
      whileHover="hover"
      className="bg-white rounded-xl shadow-sm p-6 border border-gray-200 
        hover:border-purple-300 transition-all duration-300 
        transform-gpu cursor-pointer"
    >
      <div className="flex items-start justify-between">
        <div className="flex items-center gap-4">
          <motion.div 
            variants={iconVariants}
            className="p-3 bg-purple-50 text-purple-600 rounded-lg 
              ring-2 ring-purple-100 ring-offset-2 ring-offset-white"
          >
            {icon}
          </motion.div>
          <div>
            <p className="text-sm font-medium text-purple-500 mb-1">{label}</p>
            <motion.p 
              variants={valueVariants}
              initial="initial"
              animate="animate"
              className="text-2xl font-bold bg-clip-text text-transparent 
                bg-gradient-to-r from-purple-600 to-purple-400"
            >
              {value}
            </motion.p>
          </div>
        </div>
        {trend && (
          <motion.div
            whileHover={{ scale: 1.05 }}
            className={`px-3 py-1.5 rounded-full text-xs font-medium flex items-center gap-1.5
              shadow-sm ${trend > 0 
                ? 'bg-green-50 text-green-700 ring-1 ring-green-200' 
                : 'bg-red-50 text-red-700 ring-1 ring-red-200'}`}
          >
            {trend > 0 ? (
              <svg className="w-3.5 h-3.5 animate-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
              </svg>
            ) : (
              <svg className="w-3.5 h-3.5 animate-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
              </svg>
            )}
            {trend > 0 ? '+' : ''}{trend}%
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};