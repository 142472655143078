// client/src/config/api.config.js
// Base URL Configuration
const getBaseUrl = () => {
  const environment = process.env.NODE_ENV;
  const configuredApiUrl = import.meta.env.VITE_API_URL;

  if (configuredApiUrl) {
    return `${configuredApiUrl}`;
  }

  switch(environment) {
    case 'production':
      console.warn('VITE_API_URL is not set in production environment');
      return 'http://localhost:3000/api';
    
    case 'development':
      return 'http://localhost:3000/api';
    
    default:
      return 'http://localhost:3000/api';
  }
};

// API Configuration
const apiConfig = {
  baseURL: getBaseUrl(),
  headers: {
    'Content-Type': 'application/json',
  },
  endpoints: {
    startTest: '/start-test',
    questions: '/questions',
    responses: '/responses',
    results: (testId) => `/results/${testId}`,
    checkUser: '/user-questions',
    testHistory: '/test-history',
    admin: {
      // Existing admin endpoints
      questions: '/admin/questions',
      bulkQuestions: '/admin/questions/bulk',
      updateQuestion: (id) => `/admin/questions/${id}`,
      deleteQuestion: (id) => `/admin/questions/${id}`,
      // New auth endpoints
      login: '/admin/login',
      verifyToken: '/admin/verify-token',
      profile: '/admin/profile',
      dashboardStats: '/admin/dashboard/stats',
      studentDetails: (id) => `/admin/students/${id}`,

      // New concept endpoints
      concepts: '/admin/concepts',
      updateConcept: (id) => `/admin/concepts/${id}`,
      deleteConcept: (id) => `/admin/concepts/${id}`,
      
      // Student test history endpoints
      studentDetails: (id) => `/admin/students/${id}`,
      studentTestHistory: (id) => `/admin/students/${id}/tests`,
      testDetails: (testId) => `/admin/tests/${testId}`,
      studentQuestions: (id) => `/admin/students/${id}/questions`,
    },
    school: {
      // Auth endpoints
      login: '/school/login',
      verifyToken: '/school/verify-token',
      addStudent: '/school/students/add',
      profile: '/school/profile',
      settings: '/school/settings',
      
      // Dashboard and analytics
      dashboard: '/school/dashboard/stats',
      gradeAnalytics: '/school/analytics/grades',
      conceptAnalytics: '/school/analytics/concepts',
      performanceTimeline: '/school/analytics/timeline',
      
      // Student management
      students: '/school/students',
      studentDetails: (id) => `/school/students/${id}`,
      studentPerformance: (id) => `/school/students/${id}/performance`,
      
      // Test management
      tests: '/school/tests',
      testDetails: (id) => `/school/tests/${id}`,
      scheduleTest: '/school/tests/schedule',
      
      // Reports and exports
      generateReport: '/school/reports/generate',
      exportResults: (testId) => `/school/export-results/${testId}`,
      
      // Notifications
      notifications: '/school/notifications',
      markNotificationRead: (id) => `/school/notifications/${id}/read`
    }
  },
  timeout: 10000
};

// Update apiLogger to handle auth headers securely
const apiLogger = {
  request: (config) => {
    if (process.env.NODE_ENV !== 'production') {
      const logConfig = {
        url: config.url,
        method: config.method,
        data: config.data,
        params: config.params,
        baseURL: config.baseURL
      };
      // Don't log authorization headers in development
      if (config.headers?.Authorization) {
        logConfig.headers = { Authorization: 'Bearer [REDACTED]' };
      }
      console.log('API Request:', logConfig);
    }
    return config;
  },
  response: (response) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('API Response:', {
        url: response.config.url,
        status: response.status,
        data: response.data,
        baseURL: response.config.baseURL
      });
    }
    return response;
  },
  error: (error) => {
    const errorDetails = {
      url: error.config?.url,
      baseURL: error.config?.baseURL,
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    };
    
    // Handle auth errors
    if (error.response?.status === 401) {
      localStorage.removeItem('adminToken');
      if (window.location.pathname.startsWith('/admin')) {
        window.location.href = '/admin/login';
      }
    }
    
    console.error('API Error:', errorDetails);
    return Promise.reject(error);
  }
};

export { apiConfig, apiLogger };