import React, { useState } from 'react';
import { Info, ChevronDown, ChevronUp, Calendar, Clock, BookOpen } from 'lucide-react';
import adminService from '../../services/adminService';

const StudentDetails = ({ student, loading }) => {
  const [isConceptsExpanded, setIsConceptsExpanded] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);
  const [testDetails, setTestDetails] = useState(null);
  const [loadingTest, setLoadingTest] = useState(false);
  const INITIAL_DISPLAY_COUNT = 3;

  // Helper function to determine gradient color based on mastery level
  const getGradientByMastery = (mastery) => {
    if (mastery >= 80) return 'bg-gradient-to-r from-green-500 to-emerald-400';
    if (mastery >= 60) return 'bg-gradient-to-r from-blue-500 to-cyan-400';
    if (mastery >= 40) return 'bg-gradient-to-r from-yellow-500 to-amber-400';
    return 'bg-gradient-to-r from-red-500 to-pink-400';
  };

  // Helper function to format date
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Function to handle test selection
  const handleTestSelect = async (test) => {
    if (selectedTest?.test_id === test.test_id) {
      setSelectedTest(null);
      setTestDetails(null);
      return;
    }

    setSelectedTest(test);
    setLoadingTest(true);

    try {
      const response = await adminService.getTestDetails(test.test_id);
      if (response.success) {
        setTestDetails(response.data);
      }
    } catch (error) {
      console.error('Error fetching test details:', error);
    } finally {
      setLoadingTest(false);
    }
  };

  // Loading state
  if (loading) {
    return (
      <div className="text-center py-12 bg-gradient-to-br from-indigo-900 to-purple-900 rounded-xl">
        <div className="animate-spin w-12 h-12 border-4 border-pink-500 border-t-transparent rounded-full mx-auto mb-4" />
        <p className="text-indigo-200 font-medium">Loading student details...</p>
      </div>
    );
  }

  // No student selected state
  if (!student) {
    return (
      <div className="bg-gradient-to-br from-indigo-900 to-purple-900 rounded-xl p-12 text-center border border-indigo-700/50 shadow-lg shadow-purple-900/30">
        <div className="w-20 h-20 bg-indigo-800/50 rounded-full flex items-center justify-center mx-auto mb-4 border border-indigo-600 animate-pulse">
          <Info className="w-10 h-10 text-indigo-300" />
        </div>
        <h3 className="text-lg font-medium text-indigo-100 mb-2">No Student Selected</h3>
        <p className="text-indigo-300">Select a student from the list to view their details</p>
      </div>
    );
  }

  const displayedConcepts = isConceptsExpanded 
    ? student.conceptMastery 
    : student.conceptMastery.slice(0, INITIAL_DISPLAY_COUNT);

  return (
    <div className="space-y-6">
      {/* Student Information Card */}
      <div className="bg-gradient-to-br from-indigo-900 to-purple-900 rounded-xl shadow-lg shadow-purple-900/30 border border-indigo-700/50">
        <div className="p-6 border-b border-indigo-700/50 bg-indigo-900/30">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-lg font-semibold text-indigo-100">Student Information</h3>
              <p className="text-sm text-indigo-300 mt-1">Personal details and performance</p>
            </div>
          </div>
        </div>
        <div className="p-6 bg-gradient-to-b from-transparent to-purple-900/30">
          <div className="grid grid-cols-2 gap-6">
            <div className="p-4 bg-indigo-800/30 rounded-lg border border-indigo-700/50 hover:bg-indigo-800/40 transition-all duration-300">
              <p className="text-sm text-indigo-300 mb-1">Name</p>
              <p className="font-medium text-indigo-100">{student.studentInfo.name}</p>
            </div>
            <div className="p-4 bg-indigo-800/30 rounded-lg border border-indigo-700/50 hover:bg-indigo-800/40 transition-all duration-300">
              <p className="text-sm text-indigo-300 mb-1">Grade</p>
              <p className="font-medium text-indigo-100">{student.studentInfo.grade}</p>
            </div>
            <div className="p-4 bg-indigo-800/30 rounded-lg border border-indigo-700/50 hover:bg-indigo-800/40 transition-all duration-300">
              <p className="text-sm text-indigo-300 mb-1">Email</p>
              <p className="font-medium text-indigo-100">{student.studentInfo.email}</p>
            </div>
            <div className="p-4 bg-indigo-800/30 rounded-lg border border-indigo-700/50 hover:bg-indigo-800/40 transition-all duration-300">
              <p className="text-sm text-indigo-300 mb-1">WhatsApp</p>
              <p className="font-medium text-indigo-100">{student.studentInfo.whatsapp}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Test History Section */}
      <div className="bg-gradient-to-br from-indigo-900 to-purple-900 rounded-xl shadow-lg shadow-purple-900/30 border border-indigo-700/50">
        <div className="p-6 border-b border-indigo-700/50 bg-indigo-900/30">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-lg font-semibold text-indigo-100">Test History</h3>
              <p className="text-sm text-indigo-300 mt-1">Previous test attempts and performance</p>
            </div>
          </div>
        </div>
        <div className="p-6 bg-gradient-to-b from-transparent to-purple-900/30">
          <div className="space-y-4">
            {(student?.testHistory?.data || []).map((test) => (
              <div key={test.test_id} className="relative">
                <button
                  onClick={() => handleTestSelect(test)}
                  className="w-full p-4 bg-indigo-800/30 rounded-lg border border-indigo-700/50 
                           hover:bg-indigo-800/40 transition-all duration-300 text-left"
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-4">
                      <div className="w-10 h-10 bg-indigo-700/30 rounded-full flex items-center justify-center">
                        <Calendar className="w-5 h-5 text-indigo-300" />
                      </div>
                      <div>
                        <h4 className="text-indigo-100 font-medium">Test #{test.test_id}</h4>
                        <p className="text-sm text-indigo-300">{formatDate(test.date)}</p>
                      </div>
                    </div>
                    <div className="text-right">
                      <p className="text-lg font-medium text-pink-400">{test.score.toFixed(1)}%</p>
                      <p className="text-sm text-indigo-300">
                        {test.totalQuestions} questions
                      </p>
                    </div>
                  </div>
                </button>

                {/* Test Details Expansion Panel */}
                {selectedTest?.test_id === test.test_id && (
                  <div className="mt-4 p-4 bg-indigo-800/20 rounded-lg border border-indigo-700/30">
                    {loadingTest ? (
                      <div className="text-center py-4">
                        <div className="animate-spin w-8 h-8 border-3 border-pink-500 border-t-transparent rounded-full mx-auto mb-2" />
                        <p className="text-indigo-300">Loading test details...</p>
                      </div>
                    ) : testDetails ? (
                      <>
                        <div className="grid grid-cols-3 gap-4 mb-4">
                          <div className="p-3 bg-indigo-800/30 rounded-lg">
                            <p className="text-sm text-indigo-300">Time Taken</p>
                            <div className="flex items-center mt-1">
                              <Clock className="w-4 h-4 text-indigo-400 mr-2" />
                              <p className="text-indigo-100">{Math.round(testDetails.summary?.totalTime / 60)} mins</p>
                            </div>
                          </div>
                          <div className="p-3 bg-indigo-800/30 rounded-lg">
                            <p className="text-sm text-indigo-300">Questions</p>
                            <div className="flex items-center mt-1">
                              <BookOpen className="w-4 h-4 text-indigo-400 mr-2" />
                              <p className="text-indigo-100">{testDetails.summary?.totalQuestions}</p>
                            </div>
                          </div>
                          <div className="p-3 bg-indigo-800/30 rounded-lg">
                            <p className="text-sm text-indigo-300">Correct</p>
                            <div className="flex items-center mt-1">
                              <div className="w-4 h-4 rounded-full bg-green-500 mr-2" />
                              <p className="text-indigo-100">{testDetails.summary?.correctAnswers}</p>
                            </div>
                          </div>
                        </div>

                        {/* Questions List */}
                        <div className="space-y-3 mt-6">
                          <h5 className="text-indigo-100 font-medium mb-3">Questions</h5>
                          {testDetails.questions?.map((question, index) => (
                            <div 
                              key={index}
                              className="p-3 bg-indigo-800/30 rounded-lg border border-indigo-700/30"
                            >
                              <div className="flex items-start justify-between">
                                <div className="flex-1">
                                  <p className="text-indigo-100">{question.text}</p>
                                  <p className="text-sm text-indigo-300 mt-2">
                                    Student Answer: {question.studentAnswer}
                                    {question.correct ? (
                                      <span className="text-green-400 ml-2">(Correct)</span>
                                    ) : (
                                      <span className="text-red-400 ml-2">
                                        (Incorrect - Correct: {question.correctAnswer})
                                      </span>
                                    )}
                                  </p>
                                </div>
                                <div className="ml-4">
                                  <p className="text-sm text-indigo-300">
                                    {question.timeTaken}s
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <p className="text-center text-indigo-300">No test details available</p>
                    )}
                  </div>
                )}
              </div>
            ))}

            {/* Empty State for Test History */}
            {(!student.testHistory?.data || student.testHistory.data.length === 0) && (
              <div className="text-center py-8">
                <div className="w-16 h-16 bg-indigo-800/50 rounded-full flex items-center justify-center mx-auto mb-4 border border-indigo-700 animate-pulse">
                  <Calendar className="w-8 h-8 text-indigo-300" />
                </div>
                <p className="text-indigo-200 font-medium">No test history available</p>
                <p className="text-sm text-indigo-300 mt-1">
                  Student hasn't taken any tests yet
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Concept Mastery Section */}
      <div className="bg-gradient-to-br from-indigo-900 to-purple-900 rounded-xl shadow-lg shadow-purple-900/30 border border-indigo-700/50">
        <div className="p-6 border-b border-indigo-700/50 bg-indigo-900/30">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-lg font-semibold text-indigo-100">Concept Mastery</h3>
              <p className="text-sm text-indigo-300 mt-1">Student's understanding by concept</p>
            </div>
            {student.conceptMastery?.length > 0 && (
              <p className="text-sm text-indigo-300">
                Showing {displayedConcepts.length} of {student.conceptMastery.length}
              </p>
            )}
          </div>
        </div>
        <div className="p-6 bg-gradient-to-b from-transparent to-purple-900/30">
          <div className="space-y-6">
            {displayedConcepts?.map((concept) => (
              <div 
                key={concept._id} 
                className="p-4 bg-indigo-800/30 rounded-lg border border-indigo-700/50 hover:bg-indigo-800/40 transition-all duration-300"
              >
                <div className="flex items-center justify-between mb-3">
                  <div className="flex-1 min-w-0 mr-4">
                    <p className="font-medium text-indigo-100 truncate">{concept.name}</p>
                    <p className="text-sm text-indigo-300 truncate">{concept.description}</p>
                  </div>
                  <span className="font-medium text-pink-400 ml-4">
                    {concept.mastery.toFixed(1)}%
                  </span>
                </div>
                <div className="h-3 bg-indigo-950 rounded-full overflow-hidden p-0.5">
                  <div
                    className={`h-full rounded-full transition-all duration-500 ${getGradientByMastery(concept.mastery)}`}
                    style={{ width: `${concept.mastery}%` }}
                  />
                </div>
              </div>
            ))}
            
            {/* Empty State for Concept Mastery */}
            {(!student.conceptMastery || student.conceptMastery.length === 0) && (
              <div className="text-center py-8">
                <div className="w-16 h-16 bg-indigo-800/50 rounded-full flex items-center justify-center mx-auto mb-4 border border-indigo-700 animate-pulse">
                  <Info className="w-8 h-8 text-indigo-300" />
                </div>
                <p className="text-indigo-200 font-medium">No concept mastery data available</p>
                <p className="text-sm text-indigo-300 mt-1">
                  Data will appear once the student completes relevant tests
                </p>
              </div>
            )}

            {/* Show More/Less Button */}
            {student.conceptMastery?.length > INITIAL_DISPLAY_COUNT && (
              <button
                onClick={() => setIsConceptsExpanded(!isConceptsExpanded)}
                className="w-full mt-4 p-3 flex items-center justify-center gap-2 
                           bg-indigo-800/30 rounded-lg border border-indigo-700/50 
                           hover:bg-indigo-800/40 transition-all duration-300
                           text-indigo-100 font-medium"
              >
                {isConceptsExpanded ? (
                  <>
                    Show Less <ChevronUp className="w-4 h-4" />
                  </>
                ) : (
                  <>
                    Show More <ChevronDown className="w-4 h-4" />
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDetails;