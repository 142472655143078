// src/components/school/Sidebar.jsx
import React, { useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { 
  Home,
  Users,
  FileText,
  Settings,
  LogOut,
  X
} from 'lucide-react';
import { SchoolContext } from '../../contexts/SchoolContext';

const Sidebar = ({ isOpen, setIsOpen }) => {
  const { logout } = useContext(SchoolContext);
  const navigate = useNavigate();
  const location = useLocation();
  const schoolName = localStorage.getItem('schoolName');

  const handleLogout = async () => {
    await logout();
    navigate('/school/login');
  };

  const menuItems = [
    { icon: Home, label: 'Dashboard', path: '/school/dashboard' },
    { icon: Users, label: 'Students', path: '/school/students' },
    { icon: FileText, label: 'Tests', path: '/school/tests' },
    { icon: Settings, label: 'Settings', path: '/school/' },
  ];

  const isActivePath = (path) => {
    return location.pathname === path;
  };

  return (
    <>
      {/* Mobile Overlay */}
      {isOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
          onClick={() => setIsOpen(false)}
        />
      )}

      {/* Sidebar */}
      <div className={`
        fixed top-0 left-0 h-full bg-gradient-to-b from-gray-900 to-gray-800 
        text-gray-100 w-64 shadow-xl transition-transform duration-200 ease-in-out z-50
        ${isOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
      `}>
        {/* Header */}
        <div className="p-6 border-b border-gray-700">
          <div className="flex items-center justify-between">
            <div>
              <img
                src="/MQ_Logo_Tagline.png"
                alt="Math Quotient"
                className="h-8 w-auto mb-2"
              />
              <h2 className="text-sm font-medium text-gray-400 truncate">
                {schoolName || 'School Portal'}
              </h2>
            </div>
            <button 
              onClick={() => setIsOpen(false)}
              className="lg:hidden text-gray-400 hover:text-white"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>

        {/* Navigation */}
        <nav className="p-4 space-y-2">
          {menuItems.map((item) => (
            <Link
              key={item.label}
              to={item.path}
              onClick={() => setIsOpen(false)}
              className={`flex items-center gap-3 px-4 py-3 rounded-lg transition-colors ${
                isActivePath(item.path)
                  ? 'bg-gradient-to-r from-blue-400/10 to-teal-400/10 text-white'
                  : 'text-gray-300 hover:text-white hover:bg-gray-800'
              }`}
            >
              <item.icon className="w-5 h-5" />
              <span>{item.label}</span>
            </Link>
          ))}
        </nav>

        {/* Logout Button */}
        <div className="absolute bottom-0 w-full p-4">
          <button
            onClick={handleLogout}
            className="flex items-center gap-3 px-4 py-3 w-full text-gray-300 
                     hover:text-white hover:bg-gray-800 rounded-lg transition-colors"
          >
            <LogOut className="w-5 h-5" />
            <span>Logout</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;