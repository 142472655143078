// client/src/services/adminService.js
import api from './api';
import { apiConfig } from '../config/api.config';

class AdminService {
  // Question Management Methods
  async getAllQuestions() {
    try {
      console.log('Fetching questions from:', apiConfig.baseURL + apiConfig.endpoints.admin.questions);
      const response = await api.get(apiConfig.endpoints.admin.questions);
      return {
        success: true,
        questions: response.data.questions || []
      };
    } catch (error) {
      console.error('Error fetching questions:', error);
      return {
        success: false,
        error: error.message || 'Failed to fetch questions'
      };
    }
  }

  async createQuestion(questionData) {
    try {
      const response = await api.post(
        apiConfig.endpoints.admin.questions,
        questionData
      );
      return {
        success: true,
        question: response.data.question
      };
    } catch (error) {
      console.error('Error creating question:', error);
      return {
        success: false,
        error: error.message || 'Failed to create question'
      };
    }
  }

  async createBulkQuestions(questionsData) {
    try {
      // Add timeout and size configurations
      const response = await api.post(
        apiConfig.endpoints.admin.bulkQuestions,
        { questions: questionsData },
        {
          timeout: 30000, // Increase timeout to 30 seconds
          maxContentLength: Infinity,
          maxBodyLength: Infinity
        }
      );
      return {
        success: true,
        questions: response.data.questions
      };
    } catch (error) {
      console.error('Error creating bulk questions:', error);
      // Add more specific error handling
      if (error.response?.status === 413) {
        return {
          success: false,
          error: 'The file is too large. Please try uploading a smaller batch of questions.'
        };
      }
      return {
        success: false,
        error: error.message || 'Failed to create questions'
      };
    }
  }

  async updateQuestion(questionId, questionData) {
    try {
      const response = await api.put(
        apiConfig.endpoints.admin.updateQuestion(questionId),
        questionData
      );
      return {
        success: true,
        question: response.data.question
      };
    } catch (error) {
      console.error('Error updating question:', error);
      return {
        success: false,
        error: error.message || 'Failed to update question'
      };
    }
  }

  async deleteQuestion(questionId) {
    try {
      const response = await api.delete(
        apiConfig.endpoints.admin.deleteQuestion(questionId)
      );
      return {
        success: true,
        message: response.data.message || 'Question deleted successfully'
      };
    } catch (error) {
      console.error('Error deleting question:', error);
      return {
        success: false,
        error: error.message || 'Failed to delete question'
      };
    }
  }

  async updateQuestionValidation(questionId, isValidated) {
    try {
      const response = await api.put(
        apiConfig.endpoints.admin.updateQuestion(questionId),
        { isValidated }
      );
      return {
        success: true,
        question: response.data.question
      };
    } catch (error) {
      console.error('Error updating question validation:', error);
      return {
        success: false,
        error: error.message || 'Failed to update question validation'
      };
    }
  }

  // Concept Management Methods
  async getAllConcepts() {
    try {
      const response = await api.get(apiConfig.endpoints.admin.concepts);
      return {
        success: true,
        data: response.data.data || []
      };
    } catch (error) {
      console.error('Error fetching concepts:', error);
      return {
        success: false,
        error: error.message || 'Failed to fetch concepts'
      };
    }
  }

async createConcept(conceptData) {
  try {
    const response = await api.post(
      apiConfig.endpoints.admin.concepts,
      conceptData
    );
    return {
      success: true,
      concept: response.data.concept
    };
  } catch (error) {
    console.error('Error creating concept:', error);
    return {
      success: false,
      error: error.message || 'Failed to create concept'
    };
  }
}

async updateConcept(conceptId, conceptData) {
  try {
    const response = await api.put(
      apiConfig.endpoints.admin.updateConcept(conceptId),
      conceptData
    );
    return {
      success: true,
      concept: response.data.concept
    };
  } catch (error) {
    console.error('Error updating concept:', error);
    return {
      success: false,
      error: error.message || 'Failed to update concept'
    };
  }
}

  async deleteConcept(conceptId) {
    try {
      const response = await api.delete(
        apiConfig.endpoints.admin.deleteConcept(conceptId)
      );
      return {
        success: true,
        message: response.data.message || 'Concept deleted successfully'
      };
    } catch (error) {
      console.error('Error deleting concept:', error);
      return {
        success: false,
        error: error.message || 'Failed to delete concept'
      };
    }
  }

  // Auth Methods
  async login(email, password) {
    try {
      const response = await api.post('/admin/login', { email, password });
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.message || 'Login failed'
      };
    }
  }

  async logout() {
    try {
      localStorage.removeItem('adminToken');
      localStorage.removeItem('adminEmail');
      return {
        success: true
      };
    } catch (error) {
      console.error('Logout error:', error);
      return {
        success: false,
        error: error.message
      };
    }
  }

  async verifyToken() {
    try {
      const response = await api.get('/admin/verify-token');
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.message || 'Token verification failed'
      };
    }
  }

  // Dashboard Methods
  async getDashboardStats() {
    try {
      const response = await api.get('/admin/dashboard/stats');
      return response.data;
    } catch (error) {
      console.error('Error fetching dashboard stats:', error);
      return {
        success: false,
        error: error.message
      };
    }
  }

  // Student Management Methods
  async getStudentList() {
    try {
      const response = await api.get('/admin/students');
      return response.data;
    } catch (error) {
      console.error('Error fetching student list:', error);
      return {
        success: false,
        error: error.message
      };
    }
  }

  async getStudentDetails(studentEmail) {
    try {
      const response = await api.get(`/admin/students/${studentEmail}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching student details:', error);
      return {
        success: false,
        error: error.message
      };
    }
  }

  // Helper methods
  handleResponse(response) {
    if (!response.data) {
      throw new Error('Invalid response format');
    }
    return response.data;
  }

  handleError(error) {
    const message = error.response?.data?.message || error.message || 'An error occurred';
    return {
      success: false,
      error: message
    };
  }

  async getStudentTestHistory(studentEmail) {
    try {
      const response = await api.get(apiConfig.endpoints.admin.studentTestHistory(studentEmail));
      
      // Ensure we're returning an array even if the response is empty
      return {
        success: true,
        data: response.data?.data || []
      };
    } catch (error) {
      console.error('Error fetching student test history:', error);
      return {
        success: false,
        error: error.message || 'Failed to fetch test history',
        data: [] // Always provide an empty array as fallback
      };
    }
  }

  async getTestDetails(testId) {
    try {
      const response = await api.get(apiConfig.endpoints.admin.testDetails(testId));
      return {
        success: true,
        data: response.data?.data || {
          summary: {},
          questions: []
        }
      };
    } catch (error) {
      console.error('Error fetching test details:', error);
      return {
        success: false,
        error: error.message || 'Failed to fetch test details',
        data: {
          summary: {},
          questions: []
        }
      };
    }
  }

async getStudentQuestionHistory(studentEmail) {
  try {
    const response = await api.get(`/admin/students/${studentEmail}/questions`);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    console.error('Error fetching student question history:', error);
    return {
      success: false,
      error: error.message || 'Failed to fetch question history'
    };
  }
}
}

const adminService = new AdminService();
export default adminService;