// ConceptManagement.jsx
import React, { useState, useEffect } from 'react';
import { Search, Filter, RefreshCw, X, Info, Plus, Edit2, Trash2 } from 'lucide-react';
import adminService from '../../services/adminService';
import Pagination from '../common/Pagination';

// ConceptCard Component
const ConceptCard = ({ concept, onEdit, onDelete }) => (
  <div className="bg-gray-800 rounded-xl shadow-md p-6 border border-gray-700/50">
    <div className="flex justify-between items-start">
      <div>
        <div className="flex items-center gap-3 mb-3">
          <span className="px-3 py-1 bg-gray-900/50 text-gray-300 rounded-lg text-sm font-medium border border-gray-700/50">
            ID: {concept.concept_id}
          </span>
        </div>
        <h3 className="text-lg font-medium text-gray-100 mb-2">{concept.name}</h3>
        <p className="text-gray-400">{concept.description}</p>
      </div>
      <div className="flex gap-2">
        <button
          onClick={() => onEdit(concept)}
          className="p-2 text-gray-400 hover:text-purple-400 hover:bg-purple-900/20 rounded-lg transition-colors"
          title="Edit Concept"
        >
          <Edit2 size={18} />
        </button>
        <button
          onClick={() => onDelete(concept.concept_id)}
          className="p-2 text-gray-400 hover:text-red-400 hover:bg-red-900/20 rounded-lg transition-colors"
          title="Delete Concept"
        >
          <Trash2 size={18} />
        </button>
      </div>
    </div>
  </div>
);

// ConceptEditModal Component
const ConceptEditModal = ({ concept, onSave, onClose }) => {
  const [formData, setFormData] = useState(concept || { name: '', description: '', concept_id: '' });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-gray-800 rounded-xl w-full max-w-2xl border border-gray-700">
        <div className="flex items-center justify-between p-6 border-b border-gray-700">
          <h2 className="text-xl font-semibold text-gray-100">
            {concept?.concept_id ? 'Edit Concept' : 'Add New Concept'}
          </h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-700 rounded-lg transition-colors text-gray-400"
          >
            <X size={20} />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="p-6">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Concept ID
              </label>
              <input
                type="text"
                value={formData.concept_id}
                onChange={(e) => setFormData({ ...formData, concept_id: e.target.value })}
                className="w-full px-4 py-2 bg-gray-900/50 border border-gray-700 rounded-lg text-gray-100"
                placeholder="Enter concept ID"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Name
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="w-full px-4 py-2 bg-gray-900/50 border border-gray-700 rounded-lg text-gray-100"
                placeholder="Enter concept name"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Description
              </label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                className="w-full px-4 py-2 bg-gray-900/50 border border-gray-700 rounded-lg text-gray-100 h-32"
                placeholder="Enter concept description"
                required
              />
            </div>
          </div>
          <div className="mt-6 flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-300 hover:bg-gray-700 rounded-lg transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const ConceptManagement = ({ onTotalItemsChange }) => {
    const [concepts, setConcepts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [editingConcept, setEditingConcept] = useState(null);
  
    const fetchConcepts = async () => {
      try {
        setLoading(true);
        const response = await adminService.getAllConcepts();
        if (response.success) {
          setConcepts(response.data);
          // Update total items in parent component
          onTotalItemsChange(response.data.length);
        } else {
          setError('Failed to fetch concepts');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchConcepts();
    }, []);
  
    // Update total items when search results change
    useEffect(() => {
      onTotalItemsChange(filteredConcepts.length);
    }, [searchTerm, concepts]);


const handleSaveConcept = async (conceptData) => {
    try {
      let response;
      
      if (editingConcept.concept_id) {
        // Update existing concept
        response = await adminService.updateConcept(
          editingConcept.concept_id,
          conceptData
        );
      } else {
        // Create new concept
        response = await adminService.createConcept(conceptData);
      }
  
      if (response.success) {
        await fetchConcepts();
        setEditingConcept(null);
      } else {
        setError(response.error || 'Failed to save concept');
      }
    } catch (error) {
      setError(error.message || 'Failed to save concept');
    }
  };

  const handleDeleteConcept = async (conceptId) => {
    if (window.confirm('Are you sure you want to delete this concept?')) {
      try {
        const response = await adminService.deleteConcept(conceptId);
        if (response.success) {
          fetchConcepts();
        }
      } catch (error) {
        setError('Failed to delete concept');
      }
    }
  };

  const filteredConcepts = concepts.filter(concept =>
    concept.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    concept.concept_id.toString().includes(searchTerm)
  );

  const totalPages = Math.ceil(filteredConcepts.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedConcepts = filteredConcepts.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div className="space-y-6">
      {/* Search and Add Concept */}
      <div className="bg-gray-800 rounded-xl shadow-md p-6 border border-gray-700/50">
        <div className="flex flex-wrap gap-4 items-center justify-between">
          <div className="relative flex-1 min-w-[300px]">
            <Search 
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" 
              size={20} 
            />
            <input
              type="text"
              placeholder="Search concepts..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 bg-gray-900/50 border border-gray-700 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent text-gray-100 placeholder-gray-500"
            />
          </div>

          <div className="flex gap-4">
            <button
              onClick={() => setEditingConcept({})}
              className="flex items-center px-4 py-2 text-purple-400 bg-purple-900/20 rounded-lg hover:bg-purple-900/40 transition-colors border border-purple-700/50"
            >
              <Plus size={20} className="mr-2" />
              Add Concept
            </button>

            <button
              onClick={fetchConcepts}
              className="flex items-center px-4 py-2 text-purple-400 bg-purple-900/20 rounded-lg hover:bg-purple-900/40 transition-colors border border-purple-700/50"
            >
              <RefreshCw size={20} className="mr-2" />
              Refresh
            </button>
          </div>
        </div>
      </div>

      {/* Concepts List */}
      <div className="space-y-6">
        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin w-16 h-16 border-4 border-purple-600 border-t-transparent rounded-full mb-4"></div>
            <p className="text-gray-400">Loading concepts...</p>
          </div>
        ) : error ? (
          <div className="bg-red-900/20 text-red-400 p-4 rounded-xl border border-red-900/50">
            <p>Error: {error}</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-6">
            {paginatedConcepts.length > 0 ? (
              paginatedConcepts.map(concept => (
                <ConceptCard
                  key={concept.concept_id}
                  concept={concept}
                  onEdit={() => setEditingConcept(concept)}
                  onDelete={handleDeleteConcept}
                />
              ))
            ) : (
              <div className="text-center py-12 bg-gray-800 rounded-xl border border-gray-700/50">
                <div className="w-16 h-16 bg-gray-700/50 rounded-full flex items-center justify-center mx-auto mb-4">
                  <Info className="w-8 h-8 text-gray-400" />
                </div>
                <p className="text-gray-300 font-medium">No concepts found</p>
                <p className="text-sm text-gray-400 mt-1">Try adjusting your search criteria</p>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Pagination */}
      {!loading && paginatedConcepts.length > 0 && (
        <div className="mt-6">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={setItemsPerPage}
          />
        </div>
      )}

      {/* Concept Edit Modal */}
      {editingConcept !== null && (
        <ConceptEditModal
          concept={editingConcept}
          onSave={handleSaveConcept}
          onClose={() => setEditingConcept(null)}
        />
      )}
    </div>
  );
};

export default ConceptManagement;