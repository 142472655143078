// client/src/services/schoolService.js
import api from './api';
import { apiConfig } from '../config/api.config';

class SchoolService {
  constructor() {
    // Initialize auth header if token exists
    const token = this.getAuthToken();
    if (token) {
      this.setAuthToken(token);
    }
  }

  // Authentication Methods
  async login(code, password) {
    try {
      const response = await api.post(apiConfig.endpoints.school.login, { code, password });
      if (response.data.token) {
        this.setAuthToken(response.data.token);
        this.setSchoolData(response.data.school);
      }
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      console.error('Login error:', error);
      return this.handleError(error, 'Login failed');
    }
  }

  async logout() {
    try {
      this.clearAuthData();
      return { success: true };
    } catch (error) {
      console.error('Logout error:', error);
      return this.handleError(error, 'Logout failed');
    }
  }

  async verifyToken() {
    try {
      const token = this.getAuthToken();
      if (!token) {
        return {
          success: false,
          error: 'No token found'
        };
      }

      const response = await api.get(apiConfig.endpoints.school.verifyToken);
      
      if (response.data) {
        this.setSchoolData(response.data.school);
      }

      return {
        success: true,
        data: {
          school: {
            code: localStorage.getItem('schoolCode'),
            name: localStorage.getItem('schoolName'),
            ...response.data?.school
          }
        }
      };
    } catch (error) {
      if (error.response?.status === 401) {
        this.clearAuthData();
      }
      return this.handleError(error, 'Token verification failed');
    }
  }

  // Auth Helper Methods
  setAuthToken(token) {
    if (token) {
      localStorage.setItem('schoolToken', token);
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      this.clearAuthData();
    }
  }

  getAuthToken() {
    return localStorage.getItem('schoolToken');
  }

  setSchoolData(school) {
    if (school) {
      localStorage.setItem('schoolCode', school.code);
      localStorage.setItem('schoolName', school.name);
    }
  }

  clearAuthData() {
    localStorage.removeItem('schoolToken');
    localStorage.removeItem('schoolCode');
    localStorage.removeItem('schoolName');
    delete api.defaults.headers.common['Authorization'];
  }

  isAuthenticated() {
    const token = this.getAuthToken();
    return !!token;
  }

  handleError(error, defaultMessage = 'An error occurred') {
    console.error('School service error:', error);

    if (error.response?.status === 401) {
      this.clearAuthData();
      
      if (window.location.pathname.startsWith('/school') && 
          window.location.pathname !== '/school/login') {
        window.history.pushState({}, '', '/school/login');
        window.dispatchEvent(new CustomEvent('schoolAuthRedirect'));
      }
    }

    return {
      success: false,
      error: error.response?.data?.message || error.message || defaultMessage
    };
  }

  async getTestList(params = {}) {
    try {
        const {
            search = '',
            timeRange = 'month',
        } = params;

        const response = await api.get(apiConfig.endpoints.school.tests, {
            params: {
                search,
                timeRange
            }
        });

        // Ensure we have a proper data structure even if response is empty
        const responseData = response.data?.data || {};
        const tests = responseData.tests || [];
        const stats = responseData.stats || {};

        // Format the response data with safe defaults
        return {
            success: true,
            data: {
                tests: tests.map(test => ({
                    test_id: test.test_id || '',
                    date: new Date(test.date || Date.now()),
                    students: Number(test.students) || 0,
                    averageScore: Number(test.averageScore) || 0,
                    duration: Math.round(Number(test.duration) || 0),
                })),
                stats: {
                    totalTests: Number(stats.totalTests) || 0,
                    averageScore: Number(parseFloat(stats.averageScore || 0).toFixed(1)),
                    totalStudents: Number(stats.totalStudents) || 0,
                    averageDuration: Math.round(Number(stats.averageDuration) || 0),
                    testsTrend: Number(parseFloat(stats.testsTrend || 0).toFixed(1)),
                    scoreTrend: Number(parseFloat(stats.scoreTrend || 0).toFixed(1))
                }
            }
        };
    } catch (error) {
        console.error('Error fetching test list:', error);
        return this.handleError(error, 'Failed to fetch test list');
    }
}
  async getTestDetails(testId) {
    try {
      const response = await api.get(apiConfig.endpoints.school.testDetails(testId));
      
      if (response.data.success) {
        // Format the test details data
        const data = response.data.data;
        return {
          success: true,
          data: {
            test_id: data.test_id,
            date: new Date(data.date),
            duration: data.duration || 0,
            students: data.students || 0,
            averageScore: data.averageScore || 0,
            distribution: data.distribution || {
              '0-20': 0,
              '21-40': 0,
              '41-60': 0,
              '61-80': 0,
              '81-100': 0
            },
            results: (data.results || []).map(result => ({
              student_id: result.student_id,
              student_name: result.student_name,
              grade: result.grade,
              correct: result.correct || 0,
              total: result.total || 0,
              time_taken: result.time_taken || 0
            }))
          }
        };
      } else {
        throw new Error(response.data.message || 'Failed to fetch test details');
      }
    } catch (error) {
      return this.handleError(error, 'Failed to fetch test details');
    }
  }

  async exportTestResults(testId) {
    try {
      const response = await api.get(apiConfig.endpoints.school.exportResults(testId), {
        responseType: 'blob' // Important for file download
      });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `test-${testId}-results.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      return {
        success: true
      };
    } catch (error) {
      return this.handleError(error, 'Failed to export test results');
    }
  }

  // Dashboard Statistics
  async getDashboardStats(params = {}) {
    try {
      const response = await api.get(apiConfig.endpoints.school.dashboard, { params });
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return this.handleError(error, 'Failed to fetch dashboard statistics');
    }
  }

  // Student Management Methods
  async addStudent(studentEmail) {
    try {
      const response = await api.post(apiConfig.endpoints.school.addStudent, {
        studentEmail
      });
      
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      return this.handleError(
        error, 
        error.response?.data?.message || 'Failed to add student'
      );
    }
  }

  async getStudentList(params = {}) {
    try {
      const { page = 1, limit = 10, search = '', sort = 'lastTestDate', order = 'desc' } = params;
      const response = await api.get(apiConfig.endpoints.school.students, {
        params: { page, limit, search, sort, order }
      });
      return {
        success: true,
        data: response.data.data,
        pagination: response.data.pagination
      };
    } catch (error) {
      return this.handleError(error, 'Failed to fetch student list');
    }
  }

  async getStudentDetails(studentId) {
    try {
      const response = await api.get(apiConfig.endpoints.school.studentDetails(studentId));
      
      if (response.data.success) {
        const data = response.data.data;
        return {
          success: true,
          data: {
            name: data.name || 'Unnamed Student',
            email: data.email || '',
            grade: data.grade || 'N/A',
            totalTests: data.totalTests || 0,
            averageScore: data.averageScore || 0,
            conceptsMastered: data.conceptsMastered || 0,
            lastTestDate: data.lastTestDate || null,
            performanceData: (data.performanceData || []).map(item => ({
              date: new Date(item.date).toLocaleDateString(),
              score: item.score || 0
            })),
            recentTests: (data.recentTests || []).map(test => ({
              ...test,
              date: new Date(test.date),
              score: (test.correct_answers / test.total_questions * 100) || 0
            }))
          }
        };
      } else {
        throw new Error(response.data.message || 'Failed to fetch student details');
      }
    } catch (error) {
      return this.handleError(error, 'Failed to fetch student details');
    }
  }
}

const schoolService = new SchoolService();
export default schoolService;