import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Building2, Lock, AlertCircle } from 'lucide-react';
import { SchoolContext } from '../contexts/SchoolContext';

const SchoolAuth = () => {
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { login, isAuthenticated } = useContext(SchoolContext);
  const navigate = useNavigate();
  const location = useLocation();

  // Redirect if already authenticated
  useEffect(() => {
    if (isAuthenticated) {
      const from = location.state?.from?.pathname || '/school/dashboard';
      navigate(from, { replace: true });
    }
  }, [isAuthenticated, navigate, location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const result = await login(code, password);
      if (result.success) {
        // Navigation will be handled by the useEffect above
      } else {
        setError(result.error || 'Invalid credentials');
      }
    } catch (err) {
      setError(err.message || 'Login failed');
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    // Implement forgot password functionality
    window.location.href = 'mailto:support@mathquotient.org?subject=Password Reset Request';
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-gray-900 to-gray-800 p-4">
      <div className="w-full max-w-md space-y-8">
        {/* Logo and Header */}
        <div className="text-center">
          <img
            src="/MQ_Logo_Tagline.png"
            alt="Math Quotient"
            className="h-16 w-auto mx-auto mb-6"
          />
          <h2 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-400">
            School Portal
          </h2>
          <p className="mt-2 text-gray-400">
            Access your school's dashboard
          </p>
        </div>

        {/* Auth Card */}
        <div className="bg-gradient-to-r from-gray-800 to-gray-900 rounded-xl shadow-xl p-8 border border-gray-700/50">
          <form onSubmit={handleSubmit} className="space-y-6">
            {error && (
              <div className="bg-red-900/20 text-red-400 p-4 rounded-xl flex items-center gap-2 border border-red-900/50">
                <AlertCircle className="w-5 h-5 flex-shrink-0" />
                <span>{error}</span>
              </div>
            )}

            <div className="space-y-4">
              {/* School Code Input */}
              <div>
                <label htmlFor="code" className="block text-sm font-medium text-gray-300 mb-1">
                  School Code
                </label>
                <div className="relative">
                  <Building2 className="w-5 h-5 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
                  <input
                    id="code"
                    name="code"
                    type="text"
                    required
                    className="w-full pl-10 pr-4 py-2 bg-gray-900/50 border border-gray-700 rounded-lg text-gray-300 
                             placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500/50"
                    placeholder="Enter your school code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    disabled={loading}
                  />
                </div>
              </div>

              {/* Password Input */}
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-300 mb-1">
                  Password
                </label>
                <div className="relative">
                  <Lock className="w-5 h-5 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    className="w-full pl-10 pr-4 py-2 bg-gray-900/50 border border-gray-700 rounded-lg text-gray-300 
                             placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500/50"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    disabled={loading}
                  />
                </div>
                <div className="mt-1">
                  <a 
                    href="#" 
                    onClick={handleForgotPassword}
                    className="text-sm text-gray-400 hover:text-blue-400"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full py-2 px-4 bg-gradient-to-r from-blue-500 to-teal-500 hover:from-blue-600 
                       hover:to-teal-600 text-white rounded-lg font-medium transition-all duration-200 
                       disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
            >
              {loading ? (
                <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
              ) : (
                'Sign in'
              )}
            </button>
          </form>

          {/* Contact Support */}
          <div className="mt-6 text-center">
            <p className="text-gray-400 text-sm">
              Need help accessing your school portal?
            </p>
            <button 
              onClick={() => window.location.href = 'mailto:support@mathquotient.org'}
              className="mt-2 text-blue-400 hover:text-blue-300 text-sm font-medium"
            >
              Contact Support
            </button>
          </div>
        </div>

        {/* Footer */}
        <div className="text-center text-gray-500 text-sm">
          © 2025 Math Quotient. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default SchoolAuth;