import React, { useState, useEffect } from 'react';
import { 
  Users, 
  Book, 
  BarChart2, 
  Calendar, 
  Info,
  Bell,
  Search,
  Filter,
  ChevronDown
} from 'lucide-react';
import AdminLayout from './layout/AdminLayout';
import adminService from '../../services/adminService';
import PerformanceChart from './PerformanceChart';
import StatCard from './StatCard';
import RecentTests from './RecentTests';

const AdminDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [timeRange, setTimeRange] = useState('week');

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await adminService.getDashboardStats();
      if (response.success) {
        setDashboardData(response.data);
      } else {
        setError(response.error || 'Failed to fetch dashboard data');
      }
    } catch (error) {
      setError(error.message || 'An unexpected error occurred');
      console.error('Dashboard data fetch error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
    const interval = setInterval(fetchDashboardData, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  if (loading && !dashboardData) {
    return (
      <AdminLayout>
        <div className="flex items-center justify-center min-h-[calc(100vh-4rem)]">
          <div className="text-center">
            <div className="animate-spin w-16 h-16 border-4 border-purple-600 border-t-transparent rounded-full mx-auto mb-4" />
            <p className="text-gray-400 font-medium">Loading dashboard...</p>
          </div>
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="max-w-[2000px] mx-auto">
        {/* Header Section */}
        <div className="mb-8">
          <div className="bg-gradient-to-r from-gray-800 to-gray-900 rounded-xl shadow-xl p-6 border border-gray-700/50">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
              <div>
                <h1 className="text-2xl font-bold text-gray-100 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-400">
                  Admin Dashboard
                </h1>
                <p className="text-gray-400 mt-1">Welcome back! Here's what's happening today</p>
              </div>
              
              <div className="flex items-center gap-4">
                {/* Search Bar */}
                <div className="relative">
                  <input 
                    type="text"
                    placeholder="Search..."
                    className="bg-gray-900/50 border border-gray-700 rounded-lg py-2 pl-10 pr-4 text-gray-300 placeholder-gray-500 w-48 focus:outline-none focus:ring-2 focus:ring-purple-500/50"
                  />
                  <Search className="w-4 h-4 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
                </div>

                {/* Time Range Selector */}
                <div className="relative">
                  <button className="flex items-center gap-2 bg-gray-900/50 border border-gray-700 rounded-lg px-4 py-2 text-gray-300 hover:bg-gray-800 transition-colors">
                    <span>This {timeRange}</span>
                    <ChevronDown className="w-4 h-4" />
                  </button>
                </div>

                {/* Notifications */}
                <button className="relative p-2 bg-gray-900/50 border border-gray-700 rounded-lg text-gray-300 hover:bg-gray-800 transition-colors">
                  <Bell className="w-5 h-5" />
                  <span className="absolute top-0 right-0 w-2 h-2 bg-purple-500 rounded-full"></span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {error && (
          <div className="mb-6 bg-red-900/20 text-red-400 p-4 rounded-xl flex items-center gap-2 border border-red-900/50 shadow-lg">
            <Info className="w-5 h-5 flex-shrink-0" />
            <span>{error}</span>
          </div>
        )}

        {dashboardData && (
          <div className="space-y-6">
            {/* Stats Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              <StatCard
                title="Total Students"
                value={dashboardData.totalStudents}
                icon={Users}
                color="from-blue-500 to-cyan-400"
                trend={15}
                trendLabel="vs last month"
              />
              <StatCard
                title="Total Tests"
                value={dashboardData.totalTests}
                icon={Calendar}
                color="from-green-500 to-emerald-400"
                trend={8}
                trendLabel="vs last month"
              />
              <StatCard
                title="Average Score"
                value={`${dashboardData.averageScore}%`}
                icon={BarChart2}
                color="from-purple-500 to-pink-400"
                trend={5}
                trendLabel="vs last month"
              />
              <StatCard
                title="Active Concepts"
                value={dashboardData.activeConcepts}
                icon={Book}
                color="from-orange-500 to-yellow-400"
                trend={3}
                trendLabel="vs last month"
              />
            </div>

            {/* Filters Row */}
            <div className="flex items-center gap-4 p-4 bg-gray-800 rounded-xl border border-gray-700/50">
              <Filter className="w-5 h-5 text-gray-400" />
              <div className="flex gap-2">
                {['All Grades', 'Performance', 'Activity', 'Recent'].map((filter) => (
                  <button
                    key={filter}
                    className="px-3 py-1.5 text-sm font-medium rounded-lg text-gray-300 hover:bg-gray-700/50 transition-colors"
                  >
                    {filter}
                  </button>
                ))}
              </div>
            </div>

            {/* Charts Grid */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700/50 p-6">
                <h3 className="text-lg font-semibold text-gray-100 mb-6">Performance Trends</h3>
                <PerformanceChart data={dashboardData.performanceData} />
              </div>
              <RecentTests tests={dashboardData.recentTests} />
            </div>
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default AdminDashboard;