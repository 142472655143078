import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Menu, 
  X, 
  ChevronDown, 
  Bell, 
  Search, 
  LogOut,
  Settings 
} from 'lucide-react';

const AdminNavbar = ({ onMenuToggle, isMenuOpen, adminEmail, onLogout }) => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      setIsProfileOpen(false); // Close the dropdown
      await onLogout(); // Call the passed logout function
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <nav className="fixed top-0 left-0 right-0 bg-gray-900 border-b border-gray-700 z-50">
      <div className="h-16 px-4 flex items-center justify-between">
        {/* Left section with menu button and logo */}
        <div className="flex items-center gap-4">
          <button
            onClick={() => onMenuToggle()}
            className="lg:hidden p-2 hover:bg-gray-800 rounded-lg text-gray-400 hover:text-gray-200"
            aria-label="Toggle menu"
          >
            {isMenuOpen ? (
              <X className="w-6 h-6" />
            ) : (
              <Menu className="w-6 h-6" />
            )}
          </button>

          <Link to="/admin/dashboard" className="flex items-center">
            <img 
              src="/MQ_Logo_Tagline.png" 
              alt="Math Quotient" 
              className="h-8 md:h-10 w-auto object-contain"
            />
          </Link>
        </div>

        {/* Center section with search */}
        <div className="hidden md:block flex-1 max-w-md mx-12">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-500" />
            <input
              type="search"
              placeholder="Search..."
              className="w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 text-gray-200 placeholder-gray-500"
            />
          </div>
        </div>

        {/* Right section */}
        <div className="flex items-center gap-3">

          {/* Profile Dropdown */}
          <div className="relative ml-2">
            <button
              onClick={() => setIsProfileOpen(!isProfileOpen)}
              className="flex items-center gap-2 px-3 py-2 hover:bg-gray-800 rounded-lg text-gray-400 hover:text-gray-200"
              aria-expanded={isProfileOpen}
              aria-haspopup="true"
            >
              <div className="w-8 h-8 bg-purple-600/20 rounded-full flex items-center justify-center">
                <span className="text-sm font-medium text-purple-400">
                  {adminEmail?.slice(0, 2).toUpperCase() || 'A'}
                </span>
              </div>
              <span className="text-sm font-medium text-gray-300 hidden md:block">
                Admin
              </span>
              <ChevronDown className="w-4 h-4" />
            </button>

            {isProfileOpen && (
              <div 
                className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-lg shadow-lg border border-gray-700 py-1 z-50"
                role="menu"
              >
                <Link
                  to="/admin/settings"
                  className="w-full px-4 py-2 text-left text-sm text-gray-300 hover:bg-gray-700 flex items-center gap-2"
                  role="menuitem"
                  onClick={() => setIsProfileOpen(false)}
                >
                  <Settings className="w-4 h-4" />
                  Settings
                </Link>
                <button
                  onClick={handleLogout}
                  className="w-full px-4 py-2 text-left text-sm text-red-400 hover:bg-gray-700 flex items-center gap-2"
                  role="menuitem"
                >
                  <LogOut className="w-4 h-4" />
                  Sign out
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default AdminNavbar;