// client/src/hooks/useSchoolAuth.js
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import schoolService from '../services/schoolService';

export const useSchoolAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [schoolData, setSchoolData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    verifyAuth();
  }, []);

  const verifyAuth = async () => {
    const token = localStorage.getItem('schoolToken');
    if (!token) {
      setLoading(false);
      return;
    }

    try {
      const response = await schoolService.verifyToken();
      if (response.success) {
        setIsAuthenticated(true);
        setSchoolData({
          code: localStorage.getItem('schoolCode'),
          name: localStorage.getItem('schoolName')
        });
      } else {
        await logout();
      }
    } catch (error) {
      await logout();
    } finally {
      setLoading(false);
    }
  };

  const login = async (code, password) => {
    try {
      const result = await schoolService.login(code, password);
      if (result.success) {
        setIsAuthenticated(true);
        setSchoolData({
          code: result.data.school.code,
          name: result.data.school.name
        });
        return { success: true };
      }
      return { success: false, error: result.error };
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  const logout = async () => {
    const result = await schoolService.logout();
    if (result.success) {
      setIsAuthenticated(false);
      setSchoolData(null);
      navigate('/school/login');
    }
  };

  return {
    isAuthenticated,
    loading,
    schoolData,
    login,
    logout,
    verifyAuth
  };
};

export default useSchoolAuth;