import React, { useState } from 'react';
import { Book, HelpCircle, Info, ArrowLeft, CheckCircle, Layout } from 'lucide-react';
import AdminLayout from './layout/AdminLayout';
import QuestionManagement from './QuestionManagement';
import ConceptManagement from './ConceptManagement';

const ContentDashboard = () => {
  const [selectedSection, setSelectedSection] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleQuestionsUpdate = (count) => {
    if (selectedSection === 'questions') {
      setTotalItems(count);
    }
  };

  const handleConceptsUpdate = (count) => {
    if (selectedSection === 'concepts') {
      setTotalItems(count);
    }
  };

  const handleBackToMenu = () => {
    setSelectedSection(null);
    setTotalItems(0);
    setError(null);
    setSuccess(null);
  };

  const renderHeader = () => {
    return (
      <div className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-xl shadow-lg p-8 border border-gray-700/50 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 opacity-50" />
        <div className="relative z-10">
          <div className="flex items-center justify-between">
            <div>
              <div className="flex items-center">
                {selectedSection && (
                  <button
                    onClick={handleBackToMenu}
                    className="mr-4 text-gray-400 hover:text-gray-200 transition-all duration-200 transform hover:scale-110"
                  >
                    <ArrowLeft className="w-6 h-6" />
                  </button>
                )}
                <h1 className="text-3xl font-bold text-gray-100 flex items-center">
                  Content Dashboard
                  {selectedSection && (
                    <span className="ml-4 text-lg font-normal text-gray-400 bg-gray-800/50 px-4 py-1 rounded-full">
                      {totalItems} {selectedSection}
                    </span>
                  )}
                </h1>
              </div>
              <p className="text-gray-400 mt-2 text-lg">
                {!selectedSection
                  ? 'Select a section to manage content'
                  : selectedSection === 'questions'
                  ? 'Create and manage test questions and answers'
                  : 'Organize and structure learning concepts'}
              </p>
            </div>
            <Layout className="text-gray-600 w-8 h-8" />
          </div>
        </div>
      </div>
    );
  };

  const renderNotifications = () => {
    return (
      <div className="space-y-4">
        {error && (
          <div className="bg-gradient-to-r from-red-900/30 to-red-900/10 text-red-400 p-4 rounded-xl flex items-center gap-3 border border-red-900/50 shadow-lg animate-fade-in">
            <Info className="w-5 h-5" />
            <span className="font-medium">{error}</span>
          </div>
        )}
        {success && (
          <div className="bg-gradient-to-r from-green-900/30 to-green-900/10 text-green-400 p-4 rounded-xl flex items-center gap-3 border border-green-900/50 shadow-lg animate-fade-in">
            <CheckCircle className="w-5 h-5" />
            <span className="font-medium">{success}</span>
          </div>
        )}
      </div>
    );
  };

  const renderCards = () => {
    return (
      <div className="grid md:grid-cols-2 gap-8">
        <button
          onClick={() => setSelectedSection('questions')}
          className="group relative bg-gradient-to-br from-gray-800 to-gray-900 rounded-xl p-8 border border-gray-700/50 hover:border-blue-500/50 transition-all duration-300 text-left overflow-hidden"
        >
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/0 to-blue-500/0 group-hover:from-blue-500/10 group-hover:to-blue-500/5 transition-all duration-300" />
          <div className="relative flex items-center gap-6">
            <div className="p-4 rounded-xl bg-blue-500/10 text-blue-400 group-hover:bg-blue-500/20 group-hover:scale-110 transition-all duration-300">
              <Book className="w-8 h-8" />
            </div>
            <div>
              <h2 className="text-2xl font-semibold text-gray-100 group-hover:text-blue-400 transition-colors">
                Question Management
              </h2>
              <p className="text-gray-400 mt-2 group-hover:text-gray-300 transition-colors">
                Create and manage test questions and answers
              </p>
            </div>
          </div>
        </button>

        <button
          onClick={() => setSelectedSection('concepts')}
          className="group relative bg-gradient-to-br from-gray-800 to-gray-900 rounded-xl p-8 border border-gray-700/50 hover:border-purple-500/50 transition-all duration-300 text-left overflow-hidden"
        >
          <div className="absolute inset-0 bg-gradient-to-r from-purple-500/0 to-purple-500/0 group-hover:from-purple-500/10 group-hover:to-purple-500/5 transition-all duration-300" />
          <div className="relative flex items-center gap-6">
            <div className="p-4 rounded-xl bg-purple-500/10 text-purple-400 group-hover:bg-purple-500/20 group-hover:scale-110 transition-all duration-300">
              <HelpCircle className="w-8 h-8" />
            </div>
            <div>
              <h2 className="text-2xl font-semibold text-gray-100 group-hover:text-purple-400 transition-colors">
                Concept Management
              </h2>
              <p className="text-gray-400 mt-2 group-hover:text-gray-300 transition-colors">
                Organize and structure learning concepts
              </p>
            </div>
          </div>
        </button>
      </div>
    );
  };

  const handleError = (message) => {
    setError(message);
    setSuccess(null);
    setTimeout(() => setError(null), 3000);
  };

  const handleSuccess = (message) => {
    setSuccess(message);
    setError(null);
    setTimeout(() => setSuccess(null), 3000);
  };

  const renderContent = () => {
    if (!selectedSection) {
      return renderCards();
    }

    return (
      <div className="mt-8 animate-fade-in">
        {selectedSection === 'questions' ? (
          <QuestionManagement
            onTotalItemsChange={handleQuestionsUpdate}
            onError={handleError}
            onSuccess={handleSuccess}
          />
        ) : (
          <ConceptManagement
            onTotalItemsChange={handleConceptsUpdate}
            onError={handleError}
            onSuccess={handleSuccess}
          />
        )}
      </div>
    );
  };

  return (
    <AdminLayout>
      <div className="space-y-8 mb-8">
        {renderHeader()}
        {renderNotifications()}
        {renderContent()}
      </div>
    </AdminLayout>
  );
};

export default ContentDashboard;