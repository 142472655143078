import { Card, CardHeader, CardTitle } from '@/components/ui/card';
import { 
    Search, Users,
    ChevronLeft, ChevronRight,
  } from 'lucide-react';
// StudentList Component
const StudentList = ({ 
    students, 
    loading, 
    selectedStudent, 
    onSelectStudent, 
    onSearch,
    currentPage,
    totalPages,
    onPageChange 
  }) => {
    const getScoreColor = (score) => {
      if (score >= 80) return 'text-green-400';
      if (score >= 60) return 'text-blue-400';
      return 'text-red-400';
    };
  
    return (
      <Card className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700/50">
        <CardHeader className="border-b border-gray-700">
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <CardTitle className="text-lg font-semibold text-gray-100">
                Students
              </CardTitle>
              <span className="text-sm text-gray-400">
                Total: {students.length}
              </span>
            </div>
            
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
              <input
                type="text"
                placeholder="Search students..."
                onChange={(e) => onSearch(e.target.value)}
                className="w-full pl-10 pr-4 py-2 bg-gray-900/50 border border-gray-700 rounded-lg 
                         text-gray-300 placeholder-gray-500 focus:ring-2 focus:ring-blue-400/50"
              />
            </div>
          </div>
        </CardHeader>
  
        <div className="divide-y divide-gray-700 max-h-[600px] overflow-y-auto">
          {loading ? (
            <div className="p-8 text-center">
              <div className="animate-spin w-8 h-8 border-4 border-blue-400 border-t-transparent 
                            rounded-full mx-auto mb-4" />
              <p className="text-gray-400">Loading students...</p>
            </div>
          ) : students.length > 0 ? (
            students.map((student) => (
                <button
                key={student._id}
                onClick={() => onSelectStudent(student.student_info?.email || student.email)}
                className={`w-full p-4 text-left hover:bg-gray-800/30 transition-colors ${
                    selectedStudent === (student.student_info?.email || student.email) ? 'bg-gray-800/50' : ''
                }`}
              >
                <div className="flex items-center justify-between">
                  <div>
                    <p className="font-medium text-gray-200">{student.name}</p>
                    <p className="text-sm text-gray-400">
                      Grade {student.grade} • {student.totalTests} tests
                    </p>
                  </div>
                  <div className="text-right">
                    <p className={`font-semibold ${getScoreColor(student.averageScore || 0)}`}>
                      {(student.averageScore || 0).toFixed(1)}%
                    </p>
                    <p className="text-sm text-gray-400">
                      {new Date(student.lastTestDate).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              </button>
            ))
          ) : (
            <div className="p-8 text-center">
              <Users className="w-12 h-12 text-gray-600 mx-auto mb-4" />
              <p className="text-gray-400">No students found</p>
            </div>
          )}
        </div>
  
        {totalPages > 1 && (
          <div className="border-t border-gray-700 p-4">
            <div className="flex items-center justify-center gap-4">
              <button
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="p-2 text-gray-400 hover:text-white disabled:opacity-50 
                         disabled:cursor-not-allowed"
              >
                <ChevronLeft className="w-5 h-5" />
              </button>
              <span className="text-gray-400">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="p-2 text-gray-400 hover:text-white disabled:opacity-50 
                         disabled:cursor-not-allowed"
              >
                <ChevronRight className="w-5 h-5" />
              </button>
            </div>
          </div>
        )}
      </Card>
    );
  };

  export default StudentList;