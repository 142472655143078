import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const countryPhoneCodes = [
  { code: '+971', country: 'United Arab Emirates' },
  { code: '+1', country: 'United States' },
  { code: '+44', country: 'United Kingdom' },
  { code: '+91', country: 'India' },
  { code: '+966', country: 'Saudi Arabia' },
  { code: '+965', country: 'Kuwait' },
  { code: '+973', country: 'Bahrain' },
  { code: '+974', country: 'Qatar' },
  { code: '+968', country: 'Oman' },
  { code: '+961', country: 'Lebanon' },
  { code: '+20', country: 'Egypt' },
  { code: '+962', country: 'Jordan' },
  { code: '+963', country: 'Syria' },
  { code: '+964', country: 'Iraq' },
  { code: '+98', country: 'Iran' },
  { code: '+90', country: 'Turkey' },
  { code: '+60', country: 'Malaysia' },
  { code: '+65', country: 'Singapore' },
  { code: '+63', country: 'Philippines' },
  { code: '+66', country: 'Thailand' }
];

const WhatsAppInput = ({ value, onChange, error: parentError, disabled }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [countryCode, setCountryCode] = useState('+971');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [localError, setLocalError] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const validatePhoneNumber = (number) => {
    if (!number) return '';
    if (number.length < 9) {
      return 'Phone number must be at least 9 digits';
    }
    if (number.length > 10) {
      return 'Phone number cannot exceed 10 digits';
    }
    return '';
  };

  useEffect(() => {
    if (isDirty) {
      const error = validatePhoneNumber(phoneNumber);
      setLocalError(error);
      setIsValid(phoneNumber.length >= 9 && phoneNumber.length <= 10);
    }
  }, [phoneNumber, isDirty]);

  const handleCountryCodeSelect = (code) => {
    setCountryCode(code);
    setIsDropdownOpen(false);
    const fullNumber = `${code}${phoneNumber}`;
    onChange(fullNumber);
  };

  const handlePhoneNumberChange = (e) => {
    const newPhoneNumber = e.target.value.replace(/[^\d]/g, '');
    if (newPhoneNumber.length <= 10) {
      setPhoneNumber(newPhoneNumber);
      setIsDirty(true);
      const fullNumber = `${countryCode}${newPhoneNumber}`;
      onChange(fullNumber);
    }
  };

  const error = isDirty ? (parentError || localError) : '';

  return (
    <div className="space-y-2">
      <div className="flex gap-2">
        {/* Country Code Dropdown */}
        <div className="relative">
          <motion.button
            type="button"
            disabled={disabled}
            onClick={() => !disabled && setIsDropdownOpen(!isDropdownOpen)}
            whileHover={{ scale: disabled ? 1 : 1.01 }}
            className={`
              w-28 px-4 py-4 rounded-xl border text-base
              flex items-center justify-between gap-2
              transition-all duration-200 relative
              ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:bg-white/15'}
              ${error 
                ? 'border-red-400 bg-red-400/10' 
                : isFocused
                  ? 'border-white/50 bg-white/15'
                  : 'border-white/20 bg-white/10'
              }
            `}
          >
            <span className="text-white font-medium">{countryCode}</span>
            <motion.svg
              animate={{ rotate: isDropdownOpen ? 180 : 0 }}
              className="w-5 h-5 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </motion.svg>
          </motion.button>

          {/* Dropdown Menu */}
          <AnimatePresence>
            {isDropdownOpen && !disabled && (
              <>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="fixed inset-0 z-30 bg-black/20"
                  onClick={() => setIsDropdownOpen(false)}
                />
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="absolute z-40 w-64 mt-2 bg-gray-800/95
                    rounded-xl border border-white/20 shadow-xl overflow-hidden"
                >
                  <div className="max-h-60 overflow-auto">
                    {countryPhoneCodes.map(({ code, country }) => (
                      <motion.div
                        key={code}
                        whileHover={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
                        whileTap={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                        onClick={() => handleCountryCodeSelect(code)}
                        className={`px-4 py-3 cursor-pointer flex items-center justify-between
                          ${countryCode === code ? 'bg-white/20' : ''}`}
                      >
                        <div className="flex items-center gap-2">
                          <span className="text-white font-medium">{code}</span>
                          <span className="text-white/90">{country}</span>
                        </div>
                        {countryCode === code && (
                          <motion.svg
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                            className="w-5 h-5 text-green-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </motion.svg>
                        )}
                      </motion.div>
                    ))}
                  </div>
                </motion.div>
              </>
            )}
          </AnimatePresence>
        </div>

        {/* Phone Number Input */}
        <div className="relative flex-1">
          <input
            type="tel"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            disabled={disabled}
            placeholder="509919135"
            className={`
              w-full px-6 py-4 rounded-xl border
              text-base text-white placeholder 
              transition-all duration-200
              ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
              ${error 
                ? 'border-red-400 bg-red-400/10' 
                : isFocused
                  ? 'border-white/50 bg-white/15'
                  : 'border-white/20 bg-white/10'
              }
              focus:outline-none
            `}
            maxLength="10"
          />
          
          {isDirty && phoneNumber && (
            <motion.div 
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              className="absolute right-4 top-1/2 -translate-y-1/2"
            >
              {!isValid ? (
                <svg className="w-5 h-5 text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              ) : (
                <svg className="w-5 h-5 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              )}
            </motion.div>
          )}
        </div>
      </div>

      {/* Helper Text */}
      <div className="flex items-center gap-2 px-1">
        <svg className="w-4 h-4 text-white/80" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <p className="text-sm text-white/80">Enter your WhatsApp number</p>
      </div>

      {/* Error Message */}
      <AnimatePresence>
        {error && (
          <motion.p
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="text-sm text-red-400 px-1"
          >
            {error}
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  );
};

export default WhatsAppInput;