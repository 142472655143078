import React, { useState } from 'react';
import { Edit2, ChevronDown, ChevronUp, Eye, Trash2, Check, X, AlertTriangle } from 'lucide-react';
import { Switch } from '../ui/switch';
import MathDisplay from '../common/MathDisplay';
import adminService from '../../services/adminService';

const DifficultyBadge = ({ level }) => {
  const colors = {
    0: 'bg-gray-900/50 text-gray-400 border-gray-700/50',
    1: 'bg-green-900/20 text-green-400 border-green-700/50',
    2: 'bg-blue-900/20 text-blue-400 border-blue-700/50',
    3: 'bg-yellow-900/20 text-yellow-400 border-yellow-700/50',
    4: 'bg-orange-900/20 text-orange-400 border-orange-700/50',
    5: 'bg-red-900/20 text-red-400 border-red-700/50'
  };

  const labels = {
    0: 'N/A',
    1: 'Very Easy',
    2: 'Easy',
    3: 'Medium',
    4: 'Hard',
    5: 'Very Hard'
  };

  return (
    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border ${colors[level] || colors[0]}`}>
      {labels[level] || labels[0]}
    </span>
  );
};

const ConceptsList = ({ concepts = [] }) => (
  <div className="flex flex-wrap gap-2">
    {concepts.map((concept, index) => (
      <span 
        key={index}
        className="inline-flex items-center px-2 py-1 rounded-lg text-xs 
                   bg-purple-900/20 text-purple-400 border border-purple-700/50"
      >
        {concept}
      </span>
    ))}
  </div>
);

const DeleteConfirmationModal = ({ onConfirm, onCancel }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
    <div className="bg-gray-800 rounded-xl max-w-md w-full p-6 border border-gray-700">
      <div className="flex items-center gap-3 mb-4 text-red-400">
        <AlertTriangle size={24} />
        <h3 className="text-lg font-medium text-gray-100">
          Delete Question
        </h3>
      </div>
      <p className="text-gray-300 mb-6">
        Are you sure you want to delete this question? This action cannot be undone.
      </p>
      <div className="flex justify-end gap-3">
        <button
          onClick={onCancel}
          className="px-4 py-2 text-gray-300 hover:bg-gray-700 rounded-lg transition-colors"
        >
          Cancel
        </button>
        <button
          onClick={onConfirm}
          className="flex items-center gap-2 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
        >
          <Trash2 size={16} />
          Delete
        </button>
      </div>
    </div>
  </div>
);

const QuestionCard = ({
  question,
  onEdit,
  onPreview,
  onDelete,
  onValidate,
  expanded,
  onToggleExpand
}) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  
  // Ensure question has all required properties with default values
  const safeQuestion = {
    _id: question?._id || '',
    question_id: question?.question_id || '',
    question_text: question?.question_text || '',
    options: question?.options || { A: '', B: '', C: '', D: '' },
    correct_answer: question?.correct_answer || 'A',
    difficulty_levels: question?.difficulty_levels || {},
    concepts: {
      correct_option: [],
      option_a: [],
      option_b: [],
      option_c: [],
      option_d: [],
      ...(question?.concepts || {})
    },
    misconceptions: {
      option_a: '',
      option_b: '',
      option_c: '',
      option_d: '',
      ...(question?.misconceptions || {})
    },
    isValidated: question?.isValidated || false
  };

  const handleValidationToggle = async () => {
    try {
      // First update the UI optimistically
      onValidate(safeQuestion._id, !safeQuestion.isValidated);
      
      // Then make the API call
      const response = await adminService.updateQuestionValidation(
        safeQuestion._id, 
        !safeQuestion.isValidated
      );
      
      if (!response.success) {
        // If the API call fails, revert the UI change
        onValidate(safeQuestion._id, safeQuestion.isValidated);
        console.error('Failed to update validation status:', response.error);
      }
    } catch (error) {
      // If there's an error, revert the UI change
      onValidate(safeQuestion._id, safeQuestion.isValidated);
      console.error('Error updating validation status:', error);
    }
  };

  const getActiveGrades = () => {
    return Object.entries(safeQuestion.difficulty_levels)
      .filter(([_, level]) => level > 0)
      .map(([grade, level]) => ({
        grade: grade.replace('grade_', ''),
        level
      }));
  };

  const ActionButton = ({ icon: Icon, onClick, label, color = "text-gray-400 hover:text-purple-400 hover:bg-purple-900/20" }) => (
    <button
      onClick={onClick}
      className={`p-2 rounded-lg transition-colors ${color} group`}
      title={label}
    >
      <Icon size={20} className="group-hover:scale-110 transition-transform" />
    </button>
  );

  return (
    <div className="bg-gray-800 rounded-xl shadow-md border border-gray-700/50 hover:border-gray-600/50 transition-colors">
      {/* Header Section */}
      <div className="p-6 flex items-start justify-between">
        <div className="flex-1">
          <div className="flex flex-wrap items-center gap-3 mb-3">
            <span className="px-3 py-1 bg-gray-900/50 text-gray-300 rounded-lg text-sm font-medium border border-gray-700/50">
              ID: {safeQuestion.question_id}
            </span>
            
            {/* <div className="flex items-center gap-3 py-1 px-3 bg-gray-900/50 rounded-lg border border-gray-700/50">
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-300 font-medium">Status:</span>
                <Switch
                  checked={safeQuestion.isValidated}
                  onCheckedChange={handleValidationToggle}
                />
                <span className={`text-sm ${safeQuestion.isValidated ? 'text-green-400' : 'text-gray-400'}`}>
                  {safeQuestion.isValidated ? 'Validated' : 'Not Validated'}
                </span>
              </div>
            </div> */}

            {getActiveGrades().length > 0 && (
              <span className="text-sm text-gray-400 px-3 py-1 bg-gray-900/50 rounded-lg border border-gray-700/50">
                Active in {getActiveGrades().length} grades
              </span>
            )}
          </div>
          <div className="text-gray-100">
            <MathDisplay content={safeQuestion.question_text} />
          </div>
        </div>
        
        <div className="flex items-center gap-1 ml-4">
          {onPreview && (
            <ActionButton
              icon={Eye}
              onClick={() => onPreview(safeQuestion)}
              label="Preview Question"
            />
          )}
          {onEdit && (
            <ActionButton
              icon={Edit2}
              onClick={() => onEdit(safeQuestion)}
              label="Edit Question"
            />
          )}
          <ActionButton
            icon={Trash2}
            onClick={() => setShowDeleteConfirm(true)}
            label="Delete Question"
            color="text-gray-400 hover:text-red-400 hover:bg-red-900/20"
          />
          <ActionButton
            icon={expanded ? ChevronUp : ChevronDown}
            onClick={() => onToggleExpand(safeQuestion._id)}
            label={expanded ? "Show Less" : "Show More"}
          />
        </div>
      </div>

      {/* Options Grid */}
      <div className="px-6 pb-6 grid grid-cols-1 md:grid-cols-2 gap-3">
        {Object.entries(safeQuestion.options).map(([key, value]) => (
          <div
            key={key}
            className={`p-4 rounded-lg border ${
              safeQuestion.correct_answer === key
                ? 'bg-green-900/20 border-green-700/50'
                : 'bg-gray-900/50 border-gray-700/50'
            }`}
          >
            <div className="flex items-center justify-between mb-2">
              <span className={`font-medium ${
                safeQuestion.correct_answer === key ? 'text-green-400' : 'text-gray-300'
              }`}>Option {key}</span>
              {safeQuestion.correct_answer === key && (
                <span className="inline-flex items-center gap-1 text-green-400 text-sm">
                  <Check size={16} />
                  Correct Answer
                </span>
              )}
            </div>
            <div className={safeQuestion.correct_answer === key ? 'text-green-300' : 'text-gray-300'}>
              <MathDisplay content={value} />
            </div>
          </div>
        ))}
      </div>

      {/* Expanded Content */}
      {expanded && (
        <div className="px-6 pb-6 border-t border-gray-700 pt-6">
          {/* Difficulty Levels */}
          <div className="mb-6">
            <h4 className="text-sm font-medium text-gray-300 mb-3">Difficulty Levels</h4>
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-3">
              {getActiveGrades().map(({ grade, level }) => (
                <div key={grade} className="flex items-center justify-between p-3 bg-gray-900/50 rounded-lg border border-gray-700/50">
                  <span className="text-sm text-gray-400">Grade {grade}</span>
                  <DifficultyBadge level={level} />
                </div>
              ))}
            </div>
          </div>

          {/* Concepts */}
          <div className="mb-6">
            <h4 className="text-sm font-medium text-gray-300 mb-3">Concepts</h4>
            <div className="space-y-4">
              <div>
                <span className="text-sm text-gray-400 block mb-2">Correct Answer:</span>
                <ConceptsList concepts={safeQuestion.concepts.correct_option} />
              </div>
              {Object.entries(safeQuestion.concepts)
                .filter(([key]) => key !== 'correct_option')
                .map(([key, concepts]) => (
                  <div key={key}>
                    <span className="text-sm text-gray-400 block mb-2">
                      Option {key.replace('option_', '').toUpperCase()}:
                    </span>
                    <ConceptsList concepts={concepts} />
                  </div>
                ))}
            </div>
          </div>

          {/* Misconceptions */}
          {Object.values(safeQuestion.misconceptions).some(value => value) && (
            <div>
              <h4 className="text-sm font-medium text-gray-300 mb-3">Misconceptions</h4>
              <div className="space-y-3">
                {Object.entries(safeQuestion.misconceptions)
                  .filter(([_, value]) => value)
                  .map(([key, value]) => (
                    <div key={key} className="p-3 bg-gray-900/50 rounded-lg border border-gray-700/50">
                      <span className="text-sm font-medium text-gray-300">
                        Option {key.replace('option_', '').toUpperCase()}:
                      </span>
                      <p className="text-sm text-gray-400 mt-1">{value}</p>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <DeleteConfirmationModal
          onConfirm={() => {
            onDelete(safeQuestion._id);
            setShowDeleteConfirm(false);
          }}
          onCancel={() => setShowDeleteConfirm(false)}
        />
      )}
    </div>
  );
};

export default QuestionCard;